/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { HTMLAttributes, ReactNode, Ref, forwardRef, useCallback, useMemo, useState } from 'react';
import { classNames } from 'primereact/utils';
import { useTranslation } from 'react-i18next';
import { universalRenderer } from 'utils/universalRenderer';
import { animated, useSpring } from 'react-spring';
import useMeasure from 'react-use-measure';
import { Button } from '../Button';
import './ExpandablePanel.scss';

export type ExpandablePanelProps = HTMLAttributes<HTMLDivElement> & {
	header?: ReactNode | ((options: { expanded: boolean }) => ReactNode);
	expanded?: boolean;
	contentClassName?: string;
	expandedContentClassName?: string;
	headerClassName?: string;
	expandedHeaderClassName?: string;
	disabled?: boolean;
};

export const ExpandablePanel = forwardRef(
	(
		{
			header,
			expanded = false,
			children,
			contentClassName,
			expandedContentClassName,
			headerClassName,
			expandedHeaderClassName,
			disabled = false,
			className,
			...restProps
		}: ExpandablePanelProps,
		ref: Ref<HTMLDivElement>
	) => {
		const { t } = useTranslation();
		const [isExpanded, setIsExpanded] = useState<boolean>(expanded);

		const handleHeaderClick = useCallback(() => setIsExpanded((prev) => !prev), []);

		const finalClassName = useMemo(
			() =>
				classNames('expandable-panel', className, {
					'expandable-panel-expanded': isExpanded,
					'expandable-panel-disabled': disabled,
				}),
			[className, isExpanded, disabled]
		);

		const finalHeaderClassName = useMemo(
			() =>
				classNames('expandable-panel-header', headerClassName, {
					[expandedHeaderClassName || '']: isExpanded,
				}),
			[headerClassName, expandedHeaderClassName, isExpanded]
		);

		const finalContentClassName = useMemo(
			() =>
				classNames('expandable-panel-content-outer', {
					[expandedContentClassName || '']: isExpanded,
				}),
			[expandedContentClassName, isExpanded]
		);

		const finalContentInnerClassName = useMemo(
			() => classNames('expandable-panel-content-inner', contentClassName),
			[contentClassName]
		);

		const [contentInnerRef, contentInnerBounds] = useMeasure();
		const panelContentAnimatedStyle = useSpring({
			height: isExpanded ? contentInnerBounds.height : 0,
		});

		return (
			<div ref={ref} className={finalClassName} {...restProps}>
				<div className={finalHeaderClassName} onClick={handleHeaderClick}>
					<Button
						label={isExpanded ? t('actions.collapse') : t('actions.expand')}
						icon="chevron-down"
						iconOnly
						iconClassName="expandable-panel-header-button-icon"
						variant="primary-text"
					/>
					<div className="expandable-panel-header-title">
						{typeof header === 'function'
							? universalRenderer(header({ expanded }))
							: universalRenderer(header)}
					</div>
				</div>
				<animated.div className={finalContentClassName} style={panelContentAnimatedStyle}>
					<div ref={contentInnerRef} className={finalContentInnerClassName}>
						{isExpanded ? children : null}
					</div>
				</animated.div>
			</div>
		);
	}
);
