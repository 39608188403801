export interface Goals {
	goals: Goal[];
	goalLastSignedDate: Date;
	goalSignDateMentee: Date;
	goalSignDateMentor: Date;
}
export interface Goal {
	id: number;
	goal: string;
	change: string;
	priorities: string;
	achivmentPath: string;
	notes: string;
}

export enum ActionGoalId {
	new = -1,
}

export type GoalFields = 'goal' | 'change' | 'priorities' | 'achivmentPath' | 'notes';
