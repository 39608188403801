import React, { FC, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { fixedT } from 'locale';
import { useScope } from 'contexts/scopeContext';
import { useNavigate } from 'hooks/useNavigate';
import { ButtonClean } from 'components/_new/Button';
import { Menu, MenuItem } from 'components/_new/Menu';
import { OverlayElement } from 'components/_new/Overlay';
import { userDisplayName } from 'utils/userDisplayName';
import { CurrentUser } from 'types/CurrentUser';
import { Avatar } from 'components/_new/Avatar';
import { useAuth } from 'contexts/authContext';
import { useGlobal } from 'contexts/globalContext';
import { LandingPageFormsAvailability } from '../../types/LandingPageFormsAvailability';

interface MobileUserSettingsProps {
	currentUser: CurrentUser;
	setShowOrgDialog: React.Dispatch<React.SetStateAction<boolean>>;
	source: 'panel' | 'landingPage';
	hasApplicationSubmitted?: boolean;
	optionsLanguage: string;
	formsAvailability: LandingPageFormsAvailability | undefined;
}
const MobileUserSettings: FC<MobileUserSettingsProps> = (props) => {
	const { currentUser, setShowOrgDialog, source, hasApplicationSubmitted, optionsLanguage, formsAvailability } =
		props;

	const navigate = useNavigate();
	const { i18n } = useTranslation();
	const { organizationName, programName } = useParams();

	const { logout } = useAuth();
	const { hasPanelAccess, currentProgram } = useScope();
	const { setMyAccountModalOpen, setMyCalendarModalOpen } = useGlobal();

	const isLandingPage = source === 'landingPage';
	const hasAccessToPanel = isLandingPage && !!programName && hasApplicationSubmitted;
	const getLabel = (translation: string) =>
		fixedT(isLandingPage ? optionsLanguage : i18n.resolvedLanguage, translation);

	// const { data: organizationData } = useQuery(
	// 	['getFullOrganizationInfo', organization],
	// 	() => organizationsApi.getOrganizationByNameInfo({ organizationName: String(organization) }),
	// 	{
	// 		enabled: Boolean(organization),
	// 		onError: () => {
	// 			// none
	// 		},
	// 	}
	// );
	const hasOrganizationAdminPrivilage =
		currentUser &&
		currentProgram &&
		currentUser.organizationMemberships.find(
			({ organizationId }) => currentProgram.organizationId === organizationId
		)?.role === 'organization admin';

	// const { data: programData } = useQuery(
	// 	['getProgramInfo', organization],
	// 	() => programsApi.getProgramWithMembershipInfo(String(organization), String(program)),
	// 	{
	// 		enabled: Boolean(organization) && Boolean(program),
	// 	}
	// );

	const hasProgramAdminPrivilage =
		hasOrganizationAdminPrivilage ||
		(currentUser &&
			currentProgram &&
			currentUser.programMemberships.find(({ programId }) => currentProgram.id === programId)?.role ===
				'program admin');

	const options = useMemo(() => {
		const items = [
			hasOrganizationAdminPrivilage && {
				key: 'orgAdmin',
				type: 'button',
				label: getLabel('landingPages.buttons.organizationAdministration'),
				onClick: () => navigate(`/organization/${organizationName}`),
			},
			hasProgramAdminPrivilage && {
				key: 'progAdmin',
				type: 'button',
				label: getLabel('landingPages.buttons.programAdministration'),
				onClick: () => navigate(`/program/${organizationName}/${programName}`),
			},
			{
				key: 'index',
				type: 'button',
				label: getLabel('misc.pageTitles.index'),
				onClick: () => navigate(`/${organizationName}/${programName}`),
			},
			formsAvailability?.isMentorRecruitmentAvailable && {
				key: 'mentorApplication',
				type: 'button',
				label: getLabel('landingPages.buttons.mentorApplication'),
				onClick: () => navigate(`apply-mentor`),
			},
			formsAvailability?.isMenteeRecruitmentAvailable && {
				key: 'menteeApplication',
				type: 'button',
				label: getLabel('landingPages.buttons.menteeApplication'),
				onClick: () => navigate(`apply-mentee`),
			},
			currentUser && {
				key: 'changeOrganization',
				type: 'button',
				label: getLabel('landingPages.buttons.changeOrganization'),
				onClick: () => setShowOrgDialog(true),
			},
			currentUser && {
				key: 'myAccount',
				type: 'button',
				label: getLabel('landingPages.buttons.myAccount'),
				onClick: () => {
					setMyAccountModalOpen(true);
				},
			},
			{
				key: 'myCalendar',
				type: 'button',
				label: getLabel('landingPages.buttons.myCalendar'),
				onClick: () => {
					setMyCalendarModalOpen(true);
				},
			},
			currentUser && {
				key: 'logout',
				type: 'button',
				label: getLabel('landingPages.buttons.logout'),
				onClick: () => logout(),
			},
		] as MenuItem[];

		if (hasPanelAccess && hasAccessToPanel && items) {
			items.splice(6, 0, {
				key: 'goToPanel',
				type: 'button',
				label: getLabel('landingPages.buttons.goToPanel'),
				onClick: () => navigate(`/panel/${organizationName}/${programName}`),
			});
		}

		return items?.filter(Boolean);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasPanelAccess, hasOrganizationAdminPrivilage, hasProgramAdminPrivilage, hasAccessToPanel, formsAvailability]);

	const menuRef = useRef<OverlayElement>(null);

	const displayName = userDisplayName(currentUser, null);

	return (
		<>
			<ButtonClean
				className="rounded-xl"
				onClick={({ currentTarget }) => {
					menuRef?.current?.toggle(currentTarget);
				}}
			>
				<Avatar size="md" src={currentUser.avatar} name={displayName} />
			</ButtonClean>
			<Menu ref={menuRef} items={options} />
		</>
	);
};

export default MobileUserSettings;
