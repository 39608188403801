import { httpClient } from 'api';
import { Application } from 'types/Application';
import { Organization } from 'types/Organization';
import { Program, ProgramWithLanding } from 'types/Program';
import { Question } from 'types/Question';
import { ProgramMembershipPayload } from 'types/payloads/ProgramMembershipPayload';
import { ApplicationPayload } from 'types/payloads/ApplicationPayload';
import { Memberships } from 'types/Memberships';

type ObjectToFormDataOptions = {
	namespace?: string;
	skipUndefined?: boolean;
};

export function objectToFormData(
	obj: Record<string, any>,
	formDataObj: FormData | null = null,
	_options?: ObjectToFormDataOptions
): FormData {
	const options: ObjectToFormDataOptions = {
		namespace: undefined,
		skipUndefined: false,
		...(_options || {}),
	};
	const { namespace, skipUndefined } = options;

	// eslint-disable-next-line no-param-reassign
	let formData = formDataObj || new FormData();
	// eslint-disable-next-line no-restricted-syntax, guard-for-in
	for (const property in obj) {
		const value = obj[property];
		if (skipUndefined && (typeof value === 'undefined' || value === 'undefined')) {
			// eslint-disable-next-line no-continue
			continue;
		}
		if (!Object.prototype.hasOwnProperty.call(obj, property)) {
			// eslint-disable-next-line no-continue
			continue;
		}

		const key = namespace ? `${namespace}[${property}]` : property;
		if (value instanceof Date) {
			formData.append(key, value.toISOString());
		} else if (typeof value === 'object' && !(value instanceof File)) {
			objectToFormData(value, formData, { namespace: key, skipUndefined });
		} else if (Array.isArray(value)) {
			// eslint-disable-next-line no-plusplus
			for (let i = 0; i < value.length; i++) {
				const arrayKey = `${key}[${i}]`;
				if (typeof value[i] === 'object' && !(value[i] instanceof File)) {
					formData = objectToFormData(value[i], formData, { namespace: arrayKey, skipUndefined });
				} else {
					formData.append(arrayKey, value[i]);
				}
			}
		} else {
			formData.append(key, value);
		}
	}
	return formData;
}

export const landingPagesApi = {
	getOrganization: (organization: string) => httpClient.get<Organization>(`organizations/${organization}`),
	joinOrganization: (organization: string) => httpClient.post(`organizations/${organization}/join`),
	// clearOrganizations: () => httpClient.get('organizations/clear'), // TODO remove, for testing purposes only

	getProgram: (organization: string, program: string) =>
		httpClient.get<Program>(`programs/${organization}/${program}`),

	getProgramWithLanding: (organization: string, program: string) =>
		httpClient.get<ProgramWithLanding>(`programs/${organization}/${program}`, {
			landing: true,
		}),

	joinProgram: (organization: string, program: string) => httpClient.post(`programs/${organization}/${program}/join`),
	// clearPrograms: () => axiosInstance.get('programs/clear'), // TODO remove, for testing purposes only

	getProgramApplications: (programMembershipId: number, payload: ProgramMembershipPayload) =>
		httpClient.get<Application[]>(`programMemberships/${programMembershipId}/applications`, payload),
	cancelProgramApplication: (programMembershipId: number, payload: ProgramMembershipPayload) =>
		httpClient.post<Application[]>(`programMemberships/${programMembershipId}/cancel`, payload),

	postProgramApplications: (programMembershipId: number, payload: ApplicationPayload) => {
		const formData = objectToFormData(payload);
		return httpClient.post(`programMemberships/${programMembershipId}/applications`, formData);
	},

	getFormQuestions: (programId: number, payload: ProgramMembershipPayload) =>
		httpClient.get<Question[]>(`questions/${programId}`, payload),
};
