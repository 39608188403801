import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Dialog, DialogBaseExtendProps } from 'components/_new/Dialog';
import { Link } from 'components/_new/Link';
import { usePanel } from 'contexts/panelContext';
import { useScope } from 'contexts/scopeContext';
import { Spinner } from 'components/_new/Spinner';
import { Pair } from 'types/Pair';
import { useAuth } from 'contexts/authContext';
import { useMutation } from '@tanstack/react-query';
import { usersApi } from 'api';
import ArrowA from 'assets/popup-arrow-a.png';
import ArrowB from 'assets/popup-arrow-b.png';
import ArrowC from 'assets/popup-arrow-c.png';
import Gratz from 'assets/popup-gratz.png';

export const WelcomeDialogNonPairContent = () => {
	const { t } = useTranslation();
	const { panelScopePath } = usePanel();
	const { currentProgram } = useScope();
	return (
		<>
			<li>
				<div className="flex flex-row gap-2 align-items-center">
					<img
						src={ArrowA}
						alt="arrow"
						role="none"
						className="hidden md:inline-block"
						style={{ height: 30 }}
					/>
					<p>
						<Trans
							t={t}
							i18nKey="userPanel.welcomeDialog.nonPairContent.firstElement"
							components={[
								<Link to={{ pathname: `${panelScopePath}/personal-details` }} target="_blank" />,
							]}
						/>
					</p>
				</div>
			</li>
			<li>
				<div className="flex flex-row gap-2 align-items-center">
					<img
						src={ArrowB}
						alt="arrow"
						role="none"
						className="hidden md:inline-block"
						style={{ height: 30 }}
					/>
					<p>
						<Trans
							t={t}
							i18nKey="userPanel.welcomeDialog.nonPairContent.secondElement"
							components={[<Link to={{ pathname: `${panelScopePath}/files` }} target="_blank" />]}
						/>
					</p>
				</div>
			</li>
			<li>
				<div className="flex flex-row gap-2 align-items-center">
					<p>
						<Trans
							t={t}
							i18nKey="userPanel.welcomeDialog.nonPairContent.thirdElement"
							components={[
								<Link
									to={{
										pathname:
											currentProgram?.language === 'pl'
												? `${panelScopePath}/files/przewodnik-po-mentiway`
												: `${panelScopePath}/files/mentiway-guides`,
									}}
									target="_blank"
								/>,
								<Link
									to={{
										pathname:
											currentProgram?.language === 'pl'
												? `${panelScopePath}/files/przewodnik-po-mentiway/mentoring-krok-po-kroku-z-mentiway`
												: `${panelScopePath}/files/mentiway-guides/mentoring-step-by-step-with-mentiway`,
									}}
									target="_blank"
								/>,
							]}
						/>
					</p>
					<img
						src={ArrowC}
						alt="arrow"
						role="none"
						className="hidden md:inline-block"
						style={{ height: 30 }}
					/>
				</div>
			</li>
		</>
	);
};

type WelcomeDialogPairContentProps = {
	pair: Pair;
};

export const WelcomeDialogPairContent = ({ pair }: WelcomeDialogPairContentProps) => {
	const { t } = useTranslation();
	const { panelScopePath } = usePanel();
	const { currentProgram } = useScope();
	return (
		<>
			<li>
				<div className="flex flex-row gap-2 align-items-center">
					<img
						src={ArrowA}
						alt="arrow"
						role="none"
						className="hidden md:inline-block"
						style={{ height: 30 }}
					/>
					<p>
						<Trans
							t={t}
							i18nKey="userPanel.welcomeDialog.pairContent.firstElement"
							components={[
								<Link
									to={{
										pathname: `${panelScopePath}/pair/${pair.id}/pair-details`,
									}}
									target="_blank"
								/>,
							]}
						/>
					</p>
				</div>
			</li>
			<li>
				<div className="flex flex-row gap-2 align-items-center">
					<img
						src={ArrowB}
						alt="arrow"
						role="none"
						className="hidden md:inline-block"
						style={{ height: 30 }}
					/>
					<p>
						<Trans
							t={t}
							i18nKey="userPanel.welcomeDialog.pairContent.secondElement"
							components={[<Link to={{ pathname: `${panelScopePath}/files` }} target="_blank" />]}
						/>
					</p>
				</div>
			</li>
			<li>
				<div className="flex flex-row gap-2 align-items-center">
					<p>
						<Trans
							t={t}
							i18nKey="userPanel.welcomeDialog.pairContent.thirdElement"
							components={[
								<Link
									to={{
										pathname:
											currentProgram?.language === 'pl'
												? `${panelScopePath}/files/przewodnik-po-mentiway`
												: `${panelScopePath}/files/mentiway-guides`,
									}}
									target="_blank"
								/>,
								<Link
									to={{
										pathname:
											currentProgram?.language === 'pl'
												? `${panelScopePath}/files/przewodnik-po-mentiway/mentoring-krok-po-kroku-z-mentiway`
												: `${panelScopePath}/files/mentiway-guides/mentoring-step-by-step-with-mentiway`,
									}}
									target="_blank"
								/>,
							]}
						/>
					</p>
					<img
						src={ArrowC}
						alt="arrow"
						role="none"
						className="hidden md:inline-block"
						style={{ height: 30 }}
					/>
				</div>
			</li>
		</>
	);
};

export const WelcomeDialog = (props: DialogBaseExtendProps) => {
	const { t } = useTranslation();
	const { panelScopePath } = usePanel();
	const { userPairs, userPairsFetching } = usePanel();
	const { currentUser, loading } = useAuth();
	const showWelcomePopup = Boolean(currentUser?.welcomePopup);
	const firstPair = userPairs?.length || 0 ? userPairs?.[0] : null;
	const [visible, setVisible] = useState(false);

	const { mutate: disableWelcomePopup } = useMutation(() => usersApi.diableWelcomePopup());

	useEffect(() => {
		if (!loading && showWelcomePopup) {
			setTimeout(() => {
				disableWelcomePopup();
				setVisible(true);
			}, 1000);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading, showWelcomePopup]);

	return (
		<Dialog
			{...props}
			size="lg"
			title={t('userPanel.welcomeDialog.title')}
			visible={visible}
			onHide={() => {
				setVisible(false);
			}}
			bodyClassName="grid"
		>
			<div className="col-12 lg:col-9">
				{!firstPair ? (
					<>
						<p>
							<Trans t={t} i18nKey="userPanel.welcomeDialog.primaryText" target="_blank" />
						</p>
						<p>
							<Trans
								t={t}
								i18nKey="userPanel.welcomeDialog.secondaryText"
								components={[<Link to={{ pathname: `${panelScopePath}/pairing` }} target="_blank" />]}
							/>
						</p>
					</>
				) : (
					<p>
						<Trans t={t} i18nKey="userPanel.welcomeDialog.primaryTextWithPair" target="_blank" />
					</p>
				)}
				<div className="flex flex-row">
					<div className="flex-1">
						<span>
							<Trans t={t} i18nKey="userPanel.welcomeDialog.beforeList" target="_blank" />
						</span>
						{userPairsFetching ? (
							<Spinner center />
						) : (
							<ul className="list-none">
								{firstPair ? (
									<WelcomeDialogPairContent pair={firstPair} />
								) : (
									<WelcomeDialogNonPairContent />
								)}
							</ul>
						)}
					</div>
				</div>
			</div>
			<div className="hidden lg:flex col-3 align-items-center">
				<img src={Gratz} alt="persons" role="none" style={{ maxWidth: 200 }} />
			</div>
		</Dialog>
	);
};
