import React, { Fragment, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Helmet } from 'react-helmet';
import { programsApi } from 'api';
import { useGlobal } from 'contexts/globalContext';
import SubPageTitle from 'components/_common/panel/SubPageTitle';
import { useProgramPanel } from 'contexts/programPanelContext';
import { dateFormat } from 'utils/dateFormat';
import { classNames } from 'primereact/utils';
import { DialogAction, DialogConfirm } from 'components/_new/Dialog';
import { Button } from 'components/_new/Button';
import { InputText } from 'components/_new/InputText';
import { Controller, useForm } from 'react-hook-form';
import { Dropdown } from 'components/_new/Dropdown';
import { Paginator, PaginatorPageState } from 'primereact/paginator';
import { Loader } from 'components/_new/Loader';
import download from 'downloadjs';
import { Link } from 'components/_new/Link';
import { Tooltip } from 'primereact/tooltip';
import { Box, BoxSection } from 'components/_new/Box';
import { useUrlSearch } from 'utils/useUrlSearch';
import { AddUserDialog } from '../_common/panel/AddUserDialog';
import { EditApplicationDialog } from './EditApplicationDialog';
import { ApplicationRole } from '../../types/Application';
import { User } from '../../types/User';

type MembershipFilter = {
	search: string;
	role?: 'user' | 'admin';
	application?: 'with-application' | 'without-application';
};

const ProgramMembershipList = () => {
	const { toastRef } = useGlobal();
	const { panelPath } = useProgramPanel();

	const { set, setMany, params } = useUrlSearch<{
		search?: string;
		role?: 'user' | 'admin';
		application?: 'with-application' | 'without-application';
		page: number;
	}>({ page: 1 });

	const { search, role, application, page } = params;
	const queryFilter = { search, role, application };

	const { control, handleSubmit } = useForm<MembershipFilter>({
		defaultValues: {
			search: params.search,
			role: params.role,
			application: params.application,
		},
	});

	const [addUserModal, setAddUserModal] = useState(false);
	const [chooseRoleModalOpen, setChooseRoleModalOpen] = useState(false);
	const { t } = useTranslation();

	const { data: program, isLoading, isSuccess } = useProgramPanel();

	const [selectedMembershipId, setSelectedMembershipId] = useState<number | null>(null);
	const [selectedUser, setSelectedUser] = useState<User | null>(null);
	const [makeAdminDialogOpen, setMakeAdminDialogOpen] = useState(false);
	const [editApplicationDialogOpen, setEditApplicationDialogOpen] = useState<boolean>(false);
	const [editApplicationApplicationRole, setEditApplicationApplicationRole] = useState<ApplicationRole>('mentee');
	const {
		mutate: manageMembershipMutate,
		isLoading: manageMembershipLoading,
		variables: manageMembershipVariables,
	} = useMutation(['manageMyProgramMembership'], programsApi.manageMyProgramMembership, {
		onSuccess: () => {
			refetchMemberships();
			toastRef?.current?.show({
				severity: 'success',
				life: 3000,
				summary: t('misc.success'),
				detail: t('misc.updatedSuccessfully'),
			});
		},
	});

	const handleMembershipRoleUpdate = (membershipId: number, role: string) => {
		manageMembershipMutate({ id: Number(program?.id), membershipId, role });
	};

	const handlePageChange = ({ page }: PaginatorPageState) => {
		set('page', String(page + 1));
	};

	const {
		data: programMemberships,
		isSuccess: getProgramMembershipsSuccess,
		isLoading: getProgramMembershipLoading,
		isFetching: getProgramMembershipFetching,
		refetch: refetchMemberships,
	} = useQuery(
		['getProgramMemberships', { id: program?.id, page, filter: queryFilter }],
		() => programsApi.getProgramAdminMemberships({ id: Number(program?.id), page, filter: queryFilter }),
		{
			enabled: Boolean(program?.id && isSuccess),
		}
	);

	const [downoadXlsLoading, setDownoadXlsLoading] = useState(false);

	const handleSubmitForm = handleSubmit(({ search, role, application }) => {
		const prepared = {
			page: undefined,
			search: search && String(search).trim().length >= 3 ? String(search).trim() : undefined,
			role,
			application,
		};
		setMany(prepared);
	});

	return (
		<>
			<EditApplicationDialog
				visible={!!editApplicationDialogOpen}
				onHide={() => setEditApplicationDialogOpen(false)}
				user={selectedUser}
				programId={Number(program?.id)}
				programMembershipId={selectedMembershipId}
				applicationRole={editApplicationApplicationRole}
				applicationType="proper"
				editMode={false}
				onApplicationAdded={() => {
					refetchMemberships();
				}}
				onCancel={() => {
					setSelectedMembershipId(null);
					setSelectedUser(null);
				}}
			/>
			<DialogAction
				title={t('programPanel.memberships.addMentorOrMentee')}
				visible={chooseRoleModalOpen}
				onHide={() => {
					setChooseRoleModalOpen(false);
				}}
				actions={[
					{
						key: 'mentee',
						label: 'mentee',
						onClick: () => {
							setChooseRoleModalOpen(false);
							setEditApplicationDialogOpen(true);
							setEditApplicationApplicationRole('mentee');
						},
					},
					{
						key: 'mentor',
						label: 'mentor',
						onClick: () => {
							setChooseRoleModalOpen(false);
							setEditApplicationDialogOpen(true);
							setEditApplicationApplicationRole('mentor');
						},
					},
				]}
			/>
			<DialogConfirm
				title={t('programPanel.memberships.confirmMakeAnAdmin')}
				visible={makeAdminDialogOpen}
				onHide={() => setMakeAdminDialogOpen(false)}
				onConfirm={() => {
					if (selectedMembershipId) {
						handleMembershipRoleUpdate(selectedMembershipId, 'program admin');
					}
				}}
			/>
			<AddUserDialog
				onHide={() => setAddUserModal(false)}
				isOrganisationPanel={false}
				visible={addUserModal}
				program={program}
				onUserAddSuccess={() => {
					refetchMemberships();
				}}
			/>
			<Helmet title={t('organizationPanel.organizationMemberships')} />
			<SubPageTitle
				title={t('organizationPanel.organizationMemberships')}
				additional={program?.displayName}
				right={
					<div className="flex flex-row gap-1">
						<Button
							label={t('misc.downloadXls')}
							loading={downoadXlsLoading}
							onClick={() => {
								setDownoadXlsLoading(true);
								programsApi
									.downloadProgramAdminMembershipsXls({ programId: Number(program?.id) })
									.then((responseData: Blob) => {
										download(
											responseData,
											'memberships.xlsx',
											'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
										);
										setTimeout(() => {
											setDownoadXlsLoading(false);
										}, 3000);
									});
							}}
						/>
						<Button label={t('userPanel.myAccount.addNewUser')} onClick={() => setAddUserModal(true)} />
					</div>
				}
			/>

			<div className="flex flex-column gap-2">
				<div className="flex flex-row justify-content-between align-items-center">
					<p>{t('programPanel.memberships.desc')}</p>
				</div>

				<div className="flex flex-column gap-2">
					<form onSubmit={handleSubmitForm} className="flex flex-row gap-2 flex-wrap justify-content-between">
						<div className="flex flex-row gap-2 flex-wrap">
							<Controller
								control={control}
								name="search"
								render={({ field }) => (
									<InputText
										type="search"
										maxLength={50}
										placeholder={t('programPanel.application.searchFilterOptionPlaceholder')}
										inline
										disabled={getProgramMembershipFetching}
										{...field}
									/>
								)}
							/>
							<Controller
								control={control}
								name="role"
								render={({ field }) => (
									<Dropdown
										{...field}
										inline
										withEmpty
										emptyLabel={t('programPanel.memberships.roleFilterOptions.all')}
										placeholder={t('programPanel.memberships.roleFilterOptions.all')}
										options={[
											{
												label: t('programPanel.memberships.roleFilterOptions.admins'),
												value: 'admin',
											},
											{
												label: t('programPanel.memberships.roleFilterOptions.users'),
												value: 'user',
											},
										]}
										disabled={getProgramMembershipFetching}
									/>
								)}
							/>
							<Controller
								control={control}
								name="application"
								render={({ field }) => (
									<Dropdown
										{...field}
										inline
										withEmpty
										emptyLabel={t('programPanel.memberships.withApplicationsFilterOptions.all')}
										placeholder={t('programPanel.memberships.withApplicationsFilterOptions.all')}
										options={[
											{
												label: t(
													'programPanel.memberships.withApplicationsFilterOptions.onlyWithApplications'
												),
												value: 'with-application',
											},
											{
												label: t(
													'programPanel.memberships.withApplicationsFilterOptions.onlyWithoutApplications'
												),
												value: 'without-application',
											},
										]}
										disabled={getProgramMembershipFetching}
									/>
								)}
							/>
						</div>
						<Button submit label={t('programPanel.memberships.filter')} loading={isLoading} />
					</form>
				</div>

				{(isLoading || getProgramMembershipLoading) && <Loader />}

				{!getProgramMembershipLoading && (
					<div className="my-3">
						<Trans
							i18nKey="programPanel.memberships.summary"
							components={{ b: <b /> }}
							values={{
								count: programMemberships?.pagination.all,
								currentPage: programMemberships?.pagination.currentPage,
								pages: programMemberships?.pagination.pages,
							}}
						/>
					</div>
				)}

				{isSuccess && getProgramMembershipsSuccess && (
					<div className="flex flex-column gap-2">
						{getProgramMembershipsSuccess && programMemberships.data.length === 0 && (
							<p>{t('programPanel.memberships.noMemberships')}</p>
						)}
						{getProgramMembershipsSuccess && programMemberships.data.length > 0 && (
							<ul className="p-list-unstylled flex flex-column gap-2">
								{programMemberships.data.map((membership) => {
									const twoFactorAuthEnabled =
										membership.user.twoFactorAuth ||
										Number(membership.user.stats?.organizationAdminCount) > 0 ||
										Number(membership.user.stats?.programAdminCount) > 0 ||
										Number(membership.user.stats?.program2faReqCount) > 0;
									return (
										<Fragment key={membership.id}>
											<li>
												<Tooltip
													target={`#two-factor-auth-indicator-${membership.id}`}
													// disabled={!twoFactorAuthEnabled}
													position="bottom"
												>
													<div className="flex flex-column gap-2">
														<div>
															{membership.user.twoFactorAuth ? (
																<i className="mx-1 pi pi-check-circle text-green-400" />
															) : (
																<i className="mx-1 pi pi-times-circle text-red-400" />
															)}
															<span>
																{t(
																	'programPanel.memberships.userTwoFactorRefactorEnabledReason.byAccountSetting'
																)}
															</span>
														</div>
														<div>
															{Number(membership.user.stats?.organizationAdminCount) >
															0 ? (
																<i className="mx-1 pi pi-check-circle text-green-400" />
															) : (
																<i className="mx-1 pi pi-times-circle text-red-400" />
															)}
															<span>
																{t(
																	'programPanel.memberships.userTwoFactorRefactorEnabledReason.dueToOrganizationAdmin'
																)}
															</span>
														</div>
														<div>
															{Number(membership.user.stats?.programAdminCount) ? (
																<i className="mx-1 pi pi-check-circle text-green-400" />
															) : (
																<i className="mx-1 pi pi-times-circle text-red-400" />
															)}
															<span>
																{t(
																	'programPanel.memberships.userTwoFactorRefactorEnabledReason.dueToProgramAdmin'
																)}
															</span>
														</div>
														<div>
															{Number(membership.user.stats?.program2faReqCount) > 0 ? (
																<i className="mx-1 pi pi-check-circle text-green-400" />
															) : (
																<i className="mx-1 pi pi-times-circle text-red-400" />
															)}
															<span>
																{t(
																	'programPanel.memberships.userTwoFactorRefactorEnabledReason.dueToProgramSetting'
																)}
															</span>
														</div>
													</div>
												</Tooltip>
												<Box variant="white-bordered">
													<BoxSection>
														<h2>{membership.stats.userDisplayName}</h2>
														<p>Email: {membership.stats.userEmail}</p>
														<p>
															{t('programPanel.role')}:{' '}
															<span
																className={classNames('mx-2', {
																	'text-rose bold':
																		membership.role === 'program admin',
																	'text-purplish-blue': membership.role === 'user',
																})}
															>
																{membership.role}
															</span>{' '}
															| {t('programPanel.usersLanguage')}:{' '}
															<span className="mx-2 text-purplish-blue">
																{membership.user.language || '-'}
															</span>{' '}
															| {t('programPanel.terms')}:{' '}
															<span className="mx-2">
																{membership.user.terms ? (
																	<span>
																		<i className="mx-1 pi pi-check-circle text-green-400" />
																	</span>
																) : (
																	<span>
																		<i className="mx-1 pi pi-times-circle text-red-400" />
																	</span>
																)}
															</span>
															| {t('programPanel.lastLogin')}:{' '}
															<span className="mx-2">
																{membership.user.lastLoginDate ? (
																	<span className="text-purplish-blue">
																		{dateFormat(
																			membership.user.lastLoginDate,
																			'full'
																		)}
																	</span>
																) : (
																	'-'
																)}
															</span>
															| {t('programPanel.twoFactorAuthEnabled')}:{' '}
															<span
																id={`two-factor-auth-indicator-${membership.id}`}
																className="mx-2"
															>
																{twoFactorAuthEnabled ? (
																	<span>
																		<i className="mx-1 pi pi-check-circle text-green-400" />
																	</span>
																) : (
																	<span>
																		<i className="mx-1 pi pi-times-circle text-red-400" />
																	</span>
																)}
															</span>
														</p>
														<div>
															<div className="flex gap-2 justify-content-between">
																<div>
																	{membership.applications.length > 0 ? (
																		<div className="flex flex-row flex-wrap gap-1">
																			{membership.applications.map(
																				(application) => {
																					return (
																						<Link
																							key={application.id}
																							to={`${panelPath}/application/${application.id}`}
																						>{`${
																							application.applicationRole
																						} (${application.approved}) ${
																							application.finished
																								? 'finished'
																								: ''
																						}`}</Link>
																					);
																				}
																			)}
																		</div>
																	) : (
																		<Button
																			label={t(
																				'programPanel.memberships.addProgramApplication'
																			)}
																			onClick={() => {
																				setSelectedUser(membership?.user);
																				setSelectedMembershipId(membership.id);
																				setChooseRoleModalOpen(true);
																			}}
																		/>
																	)}
																</div>
																{membership.role === 'program admin' && (
																	<Button
																		label={t(
																			'programPanel.memberships.removeAdminPrivilages'
																		)}
																		onClick={() => {
																			handleMembershipRoleUpdate(
																				membership.id,
																				'user'
																			);
																		}}
																		loading={
																			manageMembershipLoading &&
																			manageMembershipVariables?.membershipId ===
																				membership.id
																		}
																		variant="primary-outlined"
																	/>
																)}

																{membership.role === 'user' && (
																	<Button
																		label={t(
																			'programPanel.memberships.makeAnAdmin'
																		)}
																		onClick={() => {
																			setSelectedMembershipId(membership.id);
																			setMakeAdminDialogOpen(true);
																		}}
																		loading={
																			manageMembershipLoading &&
																			manageMembershipVariables?.membershipId ===
																				membership.id
																		}
																	/>
																)}
															</div>
														</div>
													</BoxSection>
												</Box>
											</li>
										</Fragment>
									);
								})}
							</ul>
						)}
						<Paginator
							first={
								programMemberships.pagination.currentPage * programMemberships.pagination.itemsPerPage -
								programMemberships.pagination.itemsPerPage +
								1
							}
							rows={programMemberships.pagination.itemsPerPage}
							totalRecords={programMemberships.pagination.all}
							onPageChange={handlePageChange}
						/>
					</div>
				)}
			</div>
		</>
	);
};

export default ProgramMembershipList;
