import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { pairsApi } from 'api';
import SubPageTitle from 'components/_common/panel/SubPageTitle';
import { Button } from 'components/_new/Button';
import { MessageBox } from 'components/_new/MessageBox';
import { usePanel } from 'contexts/panelContext';
import { useGlobal } from 'contexts/globalContext';
import { Loader } from 'components/_new/Loader';
import { Dropdown, DropdownMulti } from 'components/_new/Dropdown';
import { Controller, useForm } from 'react-hook-form';
import PageMissing from 'components/PageMissing';
import { ApplicationWithContactInfo } from 'types/Application';
import { prepareUserAreas } from 'utils/prepareUserAreas';
import { useChat } from 'components/chat/useChat';
import { Box, BoxSection } from 'components/_new/Box';
import { InputText } from 'components/_new/InputText';
import { Tooltip } from 'primereact/tooltip';
import { AxiosError } from 'axios';
import { MakeContactConfirmModal } from './MakeContactConfirmModal';
import PairItem from './PairItem';

type FilterForm = {
	areas: string[];
	areasMode: 'any' | 'all';
	search: string;
};

export const PairMembers = () => {
	const { t } = useTranslation();

	const { toastRef } = useGlobal();
	const {
		currentProgram,
		panelType,
		currentApplicationData: { id: applicationId, applicationRole, limitStats },
		currentProgramMembership: { id: currentProgramMembershipId },
		panelScopePath,
	} = usePanel();
	const currentApplicationPairLimitReached = Boolean(limitStats?.pairLimitReached);

	const [page, setPage] = useState(1);
	const handlePageChange = useCallback(() => setPage((prev) => prev + 1), []);

	const { data: applicationAreas, isSuccess: applicationAreasSuccess } = useQuery(
		['GET_APPLICATION_AREAS', { applicationRole, membershipId: Number(currentProgramMembershipId), page }],
		() => pairsApi.getApplicationAreas({ applicationRole, membershipId: Number(currentProgramMembershipId) }),
		{ enabled: true, initialData: [] }
	);

	const { control, watch, handleSubmit } = useForm<FilterForm>({
		defaultValues: {
			areas: [],
			areasMode: 'any',
			search: '',
		},
	});
	const [filterQuery, setFilterQuery] = useState<any | null>(null);

	const [applications, setApplications] = useState<ApplicationWithContactInfo[]>([]);
	const { data, isLoading, isFetching, refetch } = useQuery(
		[
			'GET_POTENTIAL_MEMBERS',
			{ applicationRole, membershipId: Number(currentProgramMembershipId), filter: filterQuery, page },
		],
		() =>
			pairsApi.getBrowsePotentialPairs({
				applicationRole,
				membershipId: Number(currentProgramMembershipId),
				filter: filterQuery,
				page,
			}),
		{
			enabled: false,
			onSuccess: ({ data }) => {
				setApplications((prev) => [...prev, ...data]);
			},
		}
	);
	const hasNextPage = (data?.pagination.currentPage || 0) < (data?.pagination.pages || 0);

	const { openDialog } = useChat();
	const {
		mutate: makeContactMutate,
		variables: makeContactVariables,
		isLoading: makeContactLoading,
	} = useMutation(
		({ applicationId }: { applicationId: number }) =>
			pairsApi.makeContact({ applicationRole, membershipId: Number(currentProgramMembershipId), applicationId }),
		{
			onSuccess: (data) => {
				toastRef?.current?.show({
					severity: 'success',
					life: 3000,
					summary: t('misc.success'),
					detail: t('userPanel.potentialMembers.contactHasBeenMaked'),
				});
				openDialog(
					applicationId === data.menteeApplicationId ? data.mentorMembershipId : data.menteeMembershipId
				);
				setApplications((prev) =>
					prev.map((application) => {
						if (
							application.programMembershipId === data.menteeMembershipId ||
							application.programMembershipId === data.mentorMembershipId
						) {
							return {
								...application,
								inPairing: true,
							};
						}
						return application;
					})
				);
			},
			onError: (error: AxiosError, variables) => {
				const yourLimitReached =
					error.response?.data &&
					(error.response.data as any)?.message === 'your application pair limit reached';
				if (yourLimitReached) {
					setApplications((prev) => prev.filter((application) => application.id !== variables.applicationId));
					toastRef?.current?.show({
						severity: 'error',
						life: 5000,
						summary: t(`misc.error`),
						detail: t('userPanel.potentialMembers.yourApplicationPairLimitReached'),
					});
				}

				const limitReachedError =
					error.response?.data &&
					(error.response.data as any)?.message === 'target application pair limit reached';
				if (limitReachedError) {
					setApplications((prev) => prev.filter((application) => application.id !== variables.applicationId));
					toastRef?.current?.show({
						severity: 'error',
						life: 5000,
						summary: t(`misc.error`),
						detail: t('userPanel.potentialMembers.applicationPairLimitReached'),
					});
				}
			},
		}
	);

	const title =
		applicationRole === 'mentee'
			? t('userPanel.potentialMembers.potentialMentors')
			: t('userPanel.potentialMembers.potentialMentees');

	const [selectedApplication, setSelectedApplication] = useState<number | null>(null);
	const [makeContactConfirmModalShow, setMakeContactConfirmModalShow] = useState(false);
	const handleAccept = useCallback(() => {
		if (!makeContactLoading && makeContactConfirmModalShow && selectedApplication) {
			makeContactMutate({ applicationId: selectedApplication });
		}
	}, [makeContactLoading, makeContactConfirmModalShow, selectedApplication, makeContactMutate]);

	const mentorAccess = currentProgram.browseMembersByMentor && panelType === 'mentor';
	const menteeAccess = currentProgram.browseMembersByMentee && panelType === 'mentee';

	if (!mentorAccess && !menteeAccess) {
		return <PageMissing />;
	}

	const userAreas = prepareUserAreas(applicationAreas);

	const handleSubmitForm = handleSubmit((values) => {
		setPage(1);
		setApplications([]);

		const search = values.search.length > 0 ? values.search : undefined;
		if (search) {
			setFilterQuery({
				search,
			});
		} else {
			const rawAreas = values.areas;
			const rawAreasMode = values.areasMode;
			const areas = rawAreas && rawAreas.length > 0 ? rawAreas : undefined;
			const areasMode = rawAreasMode && ['all', 'any'].includes(rawAreasMode) ? rawAreasMode : undefined;
			setFilterQuery({
				areas,
				areasMode,
			});
		}
	});

	useEffect(() => {
		refetch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, filterQuery]);

	const hasSearchValue = String(watch('search')).length > 0;

	return (
		<>
			<MakeContactConfirmModal
				confirmationOpen={makeContactConfirmModalShow}
				setConfirmationOpen={setMakeContactConfirmModalShow}
				onHide={() => {
					setSelectedApplication(null);
				}}
				handleAccept={handleAccept}
			/>
			<Helmet title={title} />
			<SubPageTitle title={title} />

			<div className="flex flex-column gap-2">
				<MessageBox
					variant="purple"
					message={[
						<Trans
							t={t}
							i18nKey="userPanel.potentialMembers.info1"
							values={{
								applicationTypes:
									applicationRole === 'mentee'
										? t('userPanel.potentialMembers.infoApplicationTypesMentors')
										: t('userPanel.potentialMembers.infoApplicationTypesMentees'),
							}}
							components={[
								<Link
									to={{
										pathname: `${panelScopePath}/pairing`,
									}}
									className="underline"
								/>,
							]}
						/>,
						<Trans
							t={t}
							i18nKey="userPanel.potentialMembers.info2"
							components={[
								<Link
									to={{
										pathname: `${panelScopePath}/pairing`,
									}}
									className="underline"
								/>,
							]}
						/>,
					]}
					highlight
				/>
				<form onSubmit={handleSubmitForm} className="flex flex-row gap-2 flex-wrap align-items-start">
					<div className="flex-1 flex flex-row align-items-center gap-2">
						<Controller
							control={control}
							name="areas"
							render={({ field }) => (
								<DropdownMulti
									{...field}
									className="flex-1"
									inline
									options={applicationAreas.map((area) => ({
										...area,
										options: area.options.map((str) => ({ label: str, value: str })),
									}))}
									selectedItemTemplate={(item) =>
										item ? (
											<span key={item} className="inline-flex align-items-center mr-3">
												<i className="pi pi-hashtag align-self-center text-xl mr-1 text-purplish-blue" />
												<span>{item}</span>
											</span>
										) : (
											t('userPanel.potentialMembers.areasFilterPlaceholder')
										)
									}
									disabled={hasSearchValue || !applicationAreasSuccess}
								/>
							)}
						/>
						<Controller
							control={control}
							name="areasMode"
							render={({ field }) => (
								<Dropdown
									{...field}
									inline
									defaultValue="any"
									options={[
										{
											label: t('userPanel.potentialMembers.areasModeFilterOptions.any'),
											value: 'any',
										},
										{
											label: t('userPanel.potentialMembers.areasModeFilterOptions.all'),
											value: 'all',
										},
									]}
									disabled={hasSearchValue}
								/>
							)}
						/>
					</div>

					<div className="flex flex-row align-items-center gap-2">
						<span className="text-gray">lub</span>

						<Controller
							control={control}
							name="search"
							render={({ field }) => (
								<InputText {...field} placeholder={t('userPanel.potentialMembers.searchByText')} />
							)}
						/>
					</div>

					<Button submit label={t('userPanel.potentialMembers.goFilter')} />
				</form>

				<div className="flex flex-column gap-2 mt-2">
					{!isLoading && applications.length === 0 && (
						<Box variant="white-bordered">
							<BoxSection className="text-center">{t('userPanel.potentialMembers.noResults')}</BoxSection>
						</Box>
					)}
					{applications.map((application) => (
						<PairItem
							key={application.id}
							data={application}
							userAreas={userAreas}
							rednerBottom={() => {
								const tooltipSpanId = `tooltipButton-${application.id}`;
								const tooltipText = (() => {
									if (currentApplicationPairLimitReached) {
										return t('userPanel.potentialMembers.yourApplicationPairLimitReached');
									}
									return undefined;
								})();
								return (
									<div className="flex flex-row gap-2 justify-content-end">
										{application.inPairing ? (
											<Link
												className="text-purplish-blue underline"
												to={{ pathname: `${panelScopePath}/pairing` }}
											>
												{t('userPanel.potentialMembers.goToPairs')}
											</Link>
										) : (
											<>
												<Tooltip
													target={`#${tooltipSpanId}`}
													disabled={tooltipText === undefined}
												/>
												<span id={tooltipSpanId} data-pr-tooltip={tooltipText}>
													<Button
														label={t('userPanel.potentialMembers.makeContact')}
														onClick={() => {
															setSelectedApplication(application.id);
															setMakeContactConfirmModalShow(true);
														}}
														loading={
															makeContactLoading &&
															makeContactVariables?.applicationId === application.id
														}
														disabled={Boolean(tooltipText)}
													/>
												</span>
											</>
										)}
									</div>
								);
							}}
						/>
					))}
				</div>

				{hasNextPage && !isFetching && (
					<div className="flex justify-content-center">
						<Button
							label={t('misc.loadMore')}
							onClick={() => {
								handlePageChange();
								refetch();
							}}
						/>
					</div>
				)}

				{(isFetching || isLoading) && <Loader />}
			</div>
		</>
	);
};
