import React, { useState, forwardRef, useCallback, createRef, useEffect, useId, useRef } from 'react';
import { CSSTransition, CSSTransitionProps } from 'primereact/csstransition';
import { useMountEffect } from 'primereact/hooks';
import { classNames, ObjectUtils, UniqueComponentId } from 'primereact/utils';
import { MenuItem } from 'primereact/menuitem';

export type MenuItemExt = Omit<MenuItem, 'items'> & {
	id: string;
	activeClassName?: string;
	items?: MenuItemExt[];
};

export interface PanelMenuProps
	extends Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'ref'> {
	model?: MenuItemExt[];
	multiple?: boolean;
	transitionOptions?: CSSTransitionProps;
	children?: React.ReactNode;
}

interface PanelMenuItemProps {
	sub?: boolean;
	item: MenuItemExt;
	expandedItems?: string[];
	onExpandStateChange?: (id: string) => void;
	index: number;
	headerId: string;
	contentId: string;
	className?: string;
	root?: boolean;
}

const PanelMenuItem = ({
	sub,
	item,
	expandedItems,
	onExpandStateChange,
	index,
	headerId,
	contentId,
	root,
}: PanelMenuItemProps) => {
	const active = expandedItems?.includes(item.id);

	const isExtandable = Boolean(item.items && item.items.length > 0);

	const onItemClick = (event: any, item: MenuItemExt) => {
		if (item.disabled) {
			event.preventDefault();
			return;
		}
		if (item.separator) {
			event.preventDefault();
			return;
		}

		if (!item.url) {
			event.preventDefault();
		}

		if (item.command) {
			item.command({
				originalEvent: event,
				item,
			});
		}

		if (isExtandable && onExpandStateChange) {
			onExpandStateChange(item.id);
		}
	};

	const rid = useId();
	const key = `${item.id}${rid}`;
	const className = classNames('p-panelmenu-panel', item.className);
	const headerClassName = classNames('p-component p-panelmenu-header', {
		'p-highlight': active,
		'p-disabled': item.disabled,
	});
	const submenuIconClassName = classNames('p-panelmenu-icon pi text-xs', {
		'pi-chevron-right': !sub && !active,
		'pi-chevron-down': !sub && active,
		'pi-angle-right': sub && !active,
		'pi-angle-down': sub && active,
	});
	const iconClassName = classNames('p-menuitem-icon', item.icon);
	const submenuIcon = item.items && <span className={submenuIconClassName} />;
	const { icon } = item;
	const label = item.label && <span className="p-menuitem-text">{item.label}</span>;
	const contentWrapperClassName = classNames('p-toggleable-content', {
		'p-toggleable-content-collapsed': !active,
	});
	const menuContentRef = createRef<HTMLDivElement>();

	let content = (
		<a
			href={item.url || '#'}
			className={classNames(
				root || (root && isExtandable) ? 'p-panelmenu-header-link' : 'p-menuitem-link',
				item.className
			)}
			onClick={(e) => onItemClick(e, item)}
			id={headerId}
			aria-expanded={active}
			aria-controls={contentId}
			aria-disabled={item.disabled}
		>
			{submenuIcon}
			{icon && <span className="p-menuitem-icon">{icon}</span>}
			{label}
		</a>
	);
	if (item.template) {
		const defaultContentOptions = {
			onClick: (event: any) => onItemClick(event, item),
			className: root || (root && isExtandable) ? 'p-panelmenu-header-link' : 'p-menuitem-link',
			labelClassName: 'p-menuitem-text',
			submenuIconClassName,
			iconClassName,
			element: content,
			leaf: !item.items,
			active,
		};
		content = ObjectUtils.getJSXElement(item.template, item, defaultContentOptions);
	}
	if (item.separator) {
		content = <div className="p-panel-divider" />;
	}

	const renderSubItem = () => {
		const renderSubItemContent = () => (
			<div className="p-panelmenu-content">
				<ul className="p-submenu-list p-panelmenu-root-submenu" role="tree">
					{(item.items || []).map((item2, i) => {
						const key = `${(item2 as MenuItemExt).id}-${i}`;
						return (
							<li key={key} className="p-menuitem" role="none">
								<PanelMenuItem
									sub
									className={classNames('p-panelmenu-root-submenu', item.className)}
									headerId={headerId}
									contentId={contentId}
									item={item2 as MenuItemExt}
									expandedItems={expandedItems}
									onExpandStateChange={onExpandStateChange}
									index={i}
								/>
							</li>
						);
					})}
				</ul>
			</div>
		);
		return (
			<div
				id={contentId}
				key={contentId}
				ref={menuContentRef}
				className={contentWrapperClassName}
				role="region"
				aria-labelledby={headerId}
			>
				{renderSubItemContent()}
			</div>
		);
	};

	return root ? (
		<div key={key} className={className} style={item.style}>
			<div className={headerClassName} style={item.style}>
				{content}
			</div>
			{isExtandable && (
				<CSSTransition
					key={key}
					nodeRef={menuContentRef}
					classNames="p-toggleable-content"
					timeout={{ enter: 1000, exit: 450 }}
					in={active}
					unmountOnExit
				>
					{renderSubItem()}
				</CSSTransition>
			)}
		</div>
	) : (
		<>
			{content}
			<CSSTransition
				key={key}
				nodeRef={menuContentRef}
				classNames="p-toggleable-content"
				timeout={{ enter: 1000, exit: 450 }}
				in={active}
				unmountOnExit
			>
				{renderSubItem()}
			</CSSTransition>
		</>
	);
};

export const PanelMenu = forwardRef(({ id, model, ...props }: PanelMenuProps, ref: any) => {
	const [idState, setIdState] = useState(id);
	const headerId = `${idState}_header`;
	const contentId = `${idState}_content`;
	const className = classNames('p-panelmenu p-component', props.className);

	useMountEffect(() => {
		if (!idState) {
			setIdState(UniqueComponentId());
		}
	});

	const getExpandedItemsReducer = (arr: MenuItemExt[]) =>
		arr.reduce<string[]>((previousValue, currentValue) => {
			let y = [...previousValue] as string[];
			if (currentValue.expanded) {
				y.push(currentValue.id);
			}
			if (currentValue.items) {
				y = [...y, ...getExpandedItemsReducer((currentValue.items as MenuItemExt[]) || [])];
			}
			return y;
		}, []);

	const [expandedItems, setExpandedItems] = useState<string[]>(getExpandedItemsReducer(model || []));

	const serializeModel = (items: MenuItemExt[]) => {
		const minimizeModel = (xitems: MenuItemExt[]): any => {
			return xitems.map((item) => {
				return {
					id: item.id,
					activeClassName: item.activeClassName,
					className: item.className,
					disabled: item.disabled,
					expanded: item.expanded,
					data: item.data,
					label: item.label,
					style: item.style,
					visible: item.visible,
					url: item.url,
					template: item.template,
					items: item.items ? minimizeModel(item.items) : null,
				};
			});
		};
		return JSON.stringify(minimizeModel(items));
	};
	const serializedModel = model ? serializeModel(model) : null;
	useEffect(() => {
		if (model) {
			setExpandedItems(getExpandedItemsReducer(model || []));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [serializedModel]);

	const handleExpandStateChange = useCallback(
		(id: string) => {
			setExpandedItems((prev) =>
				expandedItems.findIndex((id2) => id === id2) === -1 ? [...prev, id] : prev.filter((id3) => id3 !== id)
			);
		},
		[expandedItems]
	);

	return (
		<div id={id} ref={ref} className={className} style={props.style}>
			{(model || []).map((item, i) => (
				<PanelMenuItem
					key={item.id}
					headerId={headerId}
					contentId={contentId}
					item={item}
					expandedItems={expandedItems}
					onExpandStateChange={handleExpandStateChange}
					index={i}
					root
				/>
			))}
		</div>
	);
});
