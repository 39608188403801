import { useEffect } from 'react';
import { ChatThreadWithMessage } from 'types/Chat';
import { CHAT_THREAD_KEY, CHAT_THREAD_SELF_KEY, useChatContext } from './ChatContextProvider';

type UseChatThreadSubscriberProps = {
	onChatThread?: (event: CustomEvent<ChatThreadWithMessage>) => void;
	onChatThreadSelf?: (data: CustomEvent<ChatThreadWithMessage>) => void;
};

export const useChatThreadSubscriber = ({ onChatThread, onChatThreadSelf }: UseChatThreadSubscriberProps) => {
	const { subscribe, unsubscribe } = useChatContext();

	useEffect(() => {
		if (onChatThread) {
			subscribe(CHAT_THREAD_KEY, onChatThread);
		}
		return () => {
			unsubscribe(CHAT_THREAD_KEY, onChatThread);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [onChatThread]);

	useEffect(() => {
		if (onChatThreadSelf) {
			subscribe(CHAT_THREAD_SELF_KEY, onChatThreadSelf);
		}
		return () => {
			unsubscribe(CHAT_THREAD_SELF_KEY, onChatThreadSelf);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [onChatThreadSelf]);

	return { subscribe, unsubscribe };
};
