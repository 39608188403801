import { useCallback } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { chatApi } from 'api/chat';
import { DialogProps } from 'components/_new/Dialog';
import { useGlobal } from 'contexts/globalContext';
import { useScope } from 'contexts/scopeContext';

const CHAT_QUERY_PARAM = 'chat';

export const useChat = () => {
	const { currentProgram, currentProgramMembership } = useScope();
	const { newChats, setNewChats } = useGlobal();
	const queryUniqueParam = {
		programId: Number(currentProgram?.id),
		programMembershipId: Number(currentProgramMembership?.id),
	};

	const navigate = useNavigate();
	// const location = useLocation();
	// const locationPathname = location.pathname;

	// console.log('loc', locationPathname);
	const [params] = useSearchParams();

	const { data: chatApiAccess, isLoading: chatApiAccessLoading } = useQuery(
		['chat-access', { programId: currentProgram?.id, programMembershipId: currentProgramMembership?.id }],
		() => chatApi.getAccess(Number(currentProgramMembership?.id)),
		{ enabled: Boolean(currentProgram && currentProgramMembership), initialData: false, cacheTime: 60000 }
	);

	const { mutateAsync: readMutate } = useMutation((interlocutorProgramMembershipId: number) =>
		chatApi.readThread(Number(currentProgramMembership?.id), interlocutorProgramMembershipId)
	);

	const readThread = useCallback((interlocutorProgramMembershipId: number) => {
		readMutate(interlocutorProgramMembershipId);
		setNewChats((prev) => prev.filter((id) => id !== interlocutorProgramMembershipId));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const hasChatParam = Boolean(params.has(CHAT_QUERY_PARAM));
	const chatParam = hasChatParam ? Number(params.get(CHAT_QUERY_PARAM)) : null;
	const dialogVisible = chatApiAccess ? hasChatParam : false;

	const openDialog = useCallback(
		(threadId?: number) => {
			const newSearch = new URLSearchParams(params);
			newSearch.delete(CHAT_QUERY_PARAM);
			setTimeout(() => {
				newSearch.append(CHAT_QUERY_PARAM, String(threadId || 'init'));
				const { pathname } = window.location;
				navigate({ pathname, search: newSearch.toString() }, { replace: false });
			}, 10);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[navigate, params]
	);

	const resetDialog = useCallback(() => {
		const newSearch = new URLSearchParams(params);
		newSearch.delete(CHAT_QUERY_PARAM);
		setTimeout(() => {
			newSearch.append(CHAT_QUERY_PARAM, 'init');
			const { pathname } = window.location;
			navigate({ pathname, search: newSearch.toString() }, { replace: false });
		}, 10);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [navigate, params]);

	const closeDialog = useCallback(() => {
		const newSearch = new URLSearchParams(params);
		newSearch.delete(CHAT_QUERY_PARAM);
		setTimeout(() => {
			const { pathname } = window.location;
			navigate({ pathname, search: newSearch.toString() }, { replace: false });
		}, 10);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const dialogProps: Partial<DialogProps> = {
		visible: dialogVisible,
		onHide: () => closeDialog(),
	};

	const access = {
		loading: chatApiAccessLoading,
		hasAccess: chatApiAccess,
	};

	return {
		currentThread: chatParam,
		resetDialog,
		dialogProps,
		access,
		openDialog,
		closeDialog,
		queryUniqueParam,
		newChats: newChats.length,
		readThread,
	};
};
