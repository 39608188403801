import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Session } from 'types/Session';
import { InputTextArea } from 'components/_new/InputTextArea';
import { Button } from 'components/_new/Button';
import { useGlobal } from 'contexts/globalContext';
import { useScope } from 'contexts/scopeContext';
import { Link } from '../../_new/Link';
import { usePair } from '../../../contexts/pairContext';

type HomeworkSubpageProps = { session: Session; saveHandler: (homework: string) => Promise<any> };

type HomeworkForm = {
	homework: string;
};

export const HomeworkSubpage = ({ session, saveHandler }: HomeworkSubpageProps) => {
	const { t } = useTranslation();
	const { toastRef } = useGlobal();
	const { currentProgram } = useScope();

	const { register, handleSubmit } = useForm<HomeworkForm>({ defaultValues: { homework: session.homework } });
	const { organizationName, programName, type } = useParams();
	const {
		pair: { id: pairId },
	} = usePair();
	const [loading, setLoading] = useState<boolean>(false);
	const handleSubmitForm = handleSubmit(({ homework }) => {
		setLoading(true);
		saveHandler(homework)
			.then(() => {
				toastRef?.current?.show({
					severity: 'success',
					life: 3000,
					summary: t('misc.success'),
					detail: t('userPanel.sessionsNew.homework.saveMessage'),
				});
			})
			.finally(() => {
				setLoading(false);
			});
	});
	return (
		<form className="flex flex-column gap-2" onSubmit={handleSubmitForm}>
			<h2 className="my-5">{t('userPanel.sessionsNew.homework.heading')}</h2>
			<div>
				<p>{t('userPanel.sessionsNew.homework.desc1')}</p>
				<p>
					<Trans
						i18nKey="userPanel.sessionsNew.homework.desc4"
						components={[
							<Link
								to={
									currentProgram?.language === 'pl'
										? `/panel/${organizationName}/${programName}/${type}/files/pigulki-wiedzy/praca-wlasna-mentee`
										: `/panel/${organizationName}/${programName}/${type}/files/knowledge/mentee's-homeworks`
								}
								target="_blank"
								rel="noopener noreferrer"
								className="inline"
							/>,
							<Link
								to={`/panel/${organizationName}/${programName}/${type}/pair/${pairId}/mentibot-ai/form/homework`}
								target="_blank"
								rel="noopener noreferrer"
							/>,
						]}
					/>
				</p>
			</div>
			<InputTextArea {...register('homework')} rows={6} />
			<div className="flex flex-row justify-content-end">
				<Button submit label={t('actions.save')} loading={loading} />
			</div>
		</form>
	);
};
