import React from 'react';
import { usePair } from 'contexts/pairContext';
import { usePanel } from 'contexts/panelContext';
import { useQuery } from '@tanstack/react-query';
import { PageLoading } from 'components/PageLoading';
import { userPanelApi } from 'api';
import PersonalDetails from './PersonalDetails';

export const PairDetails = () => {
	const { currentProgramMembership, currentApplicationData } = usePanel();
	const {
		pair: { id },
	} = usePair();

	const { data, isLoading } = useQuery(
		[
			'get-pair-application-details',
			{
				membershipId: currentProgramMembership.id,
				applicationRole: currentApplicationData.applicationRole,
				pairId: id,
			},
		],
		() =>
			userPanelApi.getPairApplicationDetails(
				currentApplicationData.applicationRole,
				currentProgramMembership.id,
				id
			),
		{ enabled: true }
	);

	if (isLoading || !data) {
		return <PageLoading />;
	}

	return <PersonalDetails applicationData={data} />;
};
