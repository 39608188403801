import { t } from 'i18next';
import { TFunction } from 'react-i18next';
import { GoalFields, Goal } from 'types/Goal';

interface GetGoalRowsType {
	label: string;
	type: GoalFields;
	text: string | undefined;
	required?: boolean;
}

const getGoalRows: (goalData: Goal, t: TFunction<'translation', undefined>) => GetGoalRowsType[] = ({
	goal,
	change,
	priorities,
	achivmentPath,
	notes,
}) => [
	{
		label: t('userPanel.goal.agreedGoal'),
		type: 'goal',
		text: goal,
		required: true,
	},
	{
		label: t('userPanel.goal.expectedChange'),
		type: 'change',
		text: change,
	},
	{
		label: t('userPanel.goal.achivmentPath'),
		type: 'achivmentPath',
		text: achivmentPath,
	},
	{
		label: t('userPanel.goal.priorities'),
		type: 'priorities',
		text: priorities,
	},
	{
		label: t('userPanel.goal.notes'),
		type: 'notes',
		text: notes,
	},
];

export default getGoalRows;
