import { ApplicationRole, ApplicationType } from 'types/Application';

export interface Question {
	id: number;
	priority: number;
	questionType: QuestionTypes;
	applicationRole: ApplicationRole;
	applicationType: ApplicationType;
	systemQuestionType: string | null;
	text: string;
	description: string;
	required: boolean;
	choiceList: string[] | GroupedChoiceList[];
	categoryChoiceList: []; // TODO type
	min: number;
	max: number;
	links?: string[] | null;
	hiddenFromUsers: boolean;
}

export interface AdminQuestion extends Question {
	programId: number;
	hiddenFromUsers: boolean;
}

export interface GroupedChoiceList {
	title: string;
	options: string[];
}

export enum QuestionTypes {
	shortText = 'short text',
	link = 'link',
	longText = 'longtext',
	singleChoice = 'single choice',
	multipleChoice = 'multiple choice',
	multipleChoiceGroups = 'multiple choice groups',
	attachment = 'attachment',
	checkbox = 'checkbox',
	infoHeader = 'info header',
	infoText = 'info text',
}
