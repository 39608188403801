/* eslint-disable react/no-unused-prop-types */
import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { Helmet } from 'react-helmet';
import download from 'downloadjs';
import { programsApi, pairsApi } from 'api';
import SubPageTitle from 'components/_common/panel/SubPageTitle';
import { useProgramPanel } from 'contexts/programPanelContext';
import { classNames } from 'primereact/utils';
import { Button } from 'components/_new/Button';
import { Table } from 'components/_new/Table/Table';
import { PairContractStats, PairExtended } from 'types/Pair';
import { dateFormat } from 'utils/dateFormat';
import { Tooltip } from 'primereact/tooltip';
import { Controller, useForm } from 'react-hook-form';
import { Loader } from 'components/_new/Loader';
import { InputText } from 'components/_new/InputText';
import { Paginator, PaginatorPageState } from 'primereact/paginator';
import { Dropdown } from 'components/_new/Dropdown';
import { useUrlSearch } from 'utils/useUrlSearch';
import { Box, BoxSection } from 'components/_new/Box';

type PairsFilter = {
	search: string;
	withDeleted: boolean;
	advanced?: 'without-signed-contract' | 'without-signed-goals' | 'without-last-session';
};

const ProgramPairList = () => {
	const { t } = useTranslation();

	const { set, setMany, params } = useUrlSearch<{
		search?: string;
		withDeleted?: boolean;
		advanced?: 'without-signed-contract' | 'without-signed-goals' | 'without-last-session';
		page: number;
		'sort[field]': string;
		'sort[order]': 'asc' | 'desc';
	}>({ page: 1, 'sort[field]': 'acceptedAt', 'sort[order]': 'desc' });

	const { search, withDeleted, advanced, page } = params;
	const querySort = {
		field: params['sort[field]'],
		order: params['sort[order]'],
	};
	const queryFilter = { search, withDeleted, advanced };

	const { data: program, isLoading, isSuccess } = useProgramPanel();

	const { control, handleSubmit } = useForm<PairsFilter>({
		defaultValues: {
			search: params.search,
			withDeleted: params.withDeleted,
			advanced: params.advanced,
		},
	});

	const handlePageChange = ({ page }: PaginatorPageState) => {
		set('page', String(page + 1));
	};

	const handleSort = (field: string, order: 'desc' | 'asc' | null) => {
		setMany({
			page: undefined,
			'sort[field]': String(field && order ? field : undefined),
			'sort[order]': String(field && order ? order : undefined),
		});
	};

	const handleSubmitForm = handleSubmit(({ search, withDeleted, advanced }) => {
		const prepared = {
			page: undefined,
			search: search && String(search).trim().length >= 3 ? String(search).trim() : undefined,
			withDeleted: withDeleted ? true : undefined,
			advanced: advanced || undefined,
		};
		setMany(prepared);
	});

	const {
		data: programPairsData,
		isSuccess: programPairsSuccess,
		isLoading: programPairsLoading,
		isFetching: programPairsFetching,
	} = useQuery(
		['getProgramPairs', { id: program?.id, page, filter: queryFilter, sort: querySort }],
		() =>
			programsApi.getProgramPairsPaginated({
				id: Number(program?.id),
				page,
				filter: queryFilter,
				sort: querySort,
			}),
		{
			enabled: Boolean(isSuccess),
			keepPreviousData: true,
		}
	);

	const [downloadXlsLoading, setDownoadXlsLoading] = useState(false);
	const handleDownloadXLS = useCallback(() => {
		if (!downloadXlsLoading) {
			setDownoadXlsLoading(true);
			pairsApi.adminDownloadPairsXLS({ programId: Number(program?.id) }).then((responseData: Blob) => {
				download(
					responseData,
					'pairs.xlsx',
					'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
				);
				setTimeout(() => {
					setDownoadXlsLoading(false);
				}, 3000);
			});
		}
	}, [downloadXlsLoading, program]);

	const renderSigningIcon = (pairStats: PairContractStats, type: 'contract' | 'goals') => {
		const value = type === 'contract' ? pairStats.contractInitialSigns : pairStats.contractGoalSigns;

		let iconClass = 'pi pi-times-circle text-red-400 text-xl';
		let tooltipKey = `${type}.notSigned`;
		const signee = pairStats[`${type}SignedByOne`];
		if (value === 2) {
			iconClass = 'pi pi-check-circle text-green-400 text-xl';
			tooltipKey = `${type}.signedByBoth`;
		}
		if (value === 1) {
			iconClass = 'pi pi-times-circle text-yellow-400 text-xl';
			tooltipKey = `${type}.signedBy${signee}`;
		}

		const iconClassName = `mt-1 mr-2 ${iconClass} ${type}-sign-icon`;

		return (
			<>
				<i id={`${type}-sign-icon-${pairStats.pairId}`} className={iconClassName} />
				<Tooltip
					target={`#${type}-sign-icon-${pairStats.pairId}`}
					content={t(`programPanel.pair.list.tooltip.${tooltipKey}`)}
					position="bottom"
				/>
			</>
		);
	};

	return (
		<>
			<Helmet title={t('organizationPanel.programPairs')} />
			<SubPageTitle
				title={t('organizationPanel.programPairs')}
				additional={program?.displayName}
				right={() => (
					<Button
						disabled={isLoading || programPairsLoading}
						label={t('misc.downloadXls')}
						onClick={handleDownloadXLS}
						loading={programPairsFetching}
					/>
				)}
			/>
			<Box variant="white-bordered">
				<BoxSection>
					<div className="flex flex-column gap-2">
						<form
							onSubmit={handleSubmitForm}
							className="flex flex-row gap-2 flex-wrap justify-content-between"
						>
							<div className="flex flex-row gap-2 flex-wrap">
								<Controller
									control={control}
									name="search"
									render={({ field }) => (
										<InputText
											type="search"
											maxLength={50}
											placeholder={t('programPanel.application.searchFilterOptionPlaceholder')}
											inline
											disabled={programPairsFetching}
											{...field}
										/>
									)}
								/>
								<Controller
									control={control}
									name="withDeleted"
									render={({ field }) => (
										<Dropdown
											{...field}
											inline
											withEmpty
											emptyLabel={t('programPanel.pair.list.withDeletedFilter.activeOnly')}
											placeholder={t('programPanel.pair.list.withDeletedFilter.activeOnly')}
											options={[
												{
													label: t('programPanel.pair.list.withDeletedFilter.withDeleted'),
													value: 'true',
												},
											]}
											disabled={programPairsFetching}
										/>
									)}
								/>
								<Controller
									control={control}
									name="advanced"
									render={({ field }) => (
										<Dropdown
											{...field}
											inline
											withEmpty
											placeholder={t('programPanel.pair.list.advancedFilter.placeholder')}
											options={[
												{
													label: t(
														'programPanel.pair.list.advancedFilter.options.withoutSignedContract'
													),
													value: 'without-signed-contract',
												},
												{
													label: t(
														'programPanel.pair.list.advancedFilter.options.withoutSignedGoals'
													),
													value: 'without-signed-goals',
												},
												{
													label: t(
														'programPanel.pair.list.advancedFilter.options.withoutLastSession'
													),
													value: 'without-last-session',
												},
											]}
											disabled={programPairsFetching}
										/>
									)}
								/>
							</div>

							<Button submit label={t('programPanel.pair.list.filter')} loading={programPairsFetching} />
						</form>
						{programPairsSuccess && (
							<div className="my-3">
								<Trans
									i18nKey="programPanel.pair.list.summary2"
									components={{ b: <b /> }}
									values={{
										count: programPairsData?.pagination.all,
										currentPage: programPairsData?.pagination.currentPage,
										pages: programPairsData?.pagination.pages,
									}}
								/>
							</div>
						)}
						{(isLoading || programPairsLoading) && <Loader />}
						{programPairsSuccess && programPairsData.data.length === 0 && (
							<p>{t('programPanel.pair.list.noPairs')}</p>
						)}
						{programPairsSuccess && programPairsData.data.length > 0 && (
							<>
								<Table<PairExtended>
									isRefetching={programPairsFetching}
									data={programPairsData.data}
									defaultSort={querySort}
									onSort={handleSort}
									columns={[
										{
											label: t('programPanel.pair.list.acceptedAt'),
											sortable: true,
											name: 'acceptedAt',
											body: (pair) =>
												pair.stats.acceptedAt ? dateFormat(pair.stats.acceptedAt, 'date') : '-',
											isDate: true,
										},
										{
											label: t('programPanel.pair.list.mentee'),
											sortable: true,
											name: 'menteeDisplayName',
											body: (pair) => (
												<Link
													to={{
														pathname: `/program/${program?.name}/pair/${pair.id}`,
													}}
													className={classNames({
														'text-error': pair.menteeApplication.deletedAt !== null,
													})}
												>
													{pair.stats.menteeUserDisplayName}
												</Link>
											),
										},
										{
											label: t('programPanel.pair.list.mentor'),
											sortable: true,
											name: 'mentorDisplayName',
											body: (pair) => (
												<Link
													to={{
														pathname: `/program/${program?.name}/pair/${pair.id}`,
													}}
													className={classNames({
														'text-error': pair.menteeApplication.deletedAt !== null,
													})}
												>
													{pair.stats.mentorUserDisplayName}
												</Link>
											),
										},
										{
											label: t('programPanel.pair.list.contractShort'),
											longLabel: t('programPanel.pair.list.contract'),
											bodyClassName: 'text-center',
											name: 'contracts',
											body: (pair) =>
												pair.stats ? (
													<>
														{renderSigningIcon(pair.contractStats, 'contract')}
														{renderSigningIcon(pair.contractStats, 'goals')}
													</>
												) : null,
											sortable: true,
										},
										{
											label: t('programPanel.pair.list.sessionsShort'),
											longLabel: t('programPanel.pair.list.sessions'),
											bodyClassName: 'text-center',
											name: 'sessionsSort',
											body: (pair) =>
												`${pair.sessionStats.sessionsPast || 0} / ${
													pair.sessionStats.sessionsPlanned || 0
												} / ${pair.sessionStats.sessionsAll || 0}`,
											sortable: true,
										},
										{
											label: t('programPanel.pair.list.daysLastMeetingShort'),
											longLabel: t('programPanel.pair.list.daysLastMeeting'),
											name: 'sessionLastDateDays',
											body: (pair) => {
												if (
													typeof pair.sessionLastStats.sessionLastDateDays !== 'undefined' &&
													pair.sessionLastStats.sessionLastDateDays !== null
												) {
													return Number(pair.sessionLastStats.sessionLastDateDays) >= 30 ? (
														<span className="text-error">
															{pair.sessionLastStats.sessionLastDateDays}
														</span>
													) : (
														pair.sessionLastStats.sessionLastDateDays
													);
												}
												return '-';
											},
											sortable: true,
										},
										{
											label: t('programPanel.pair.list.avgRatingMeetingsShort'),
											longLabel: t('programPanel.pair.list.avgRatingMeetings'),
											name: 'avgSatisfactionSort',
											body: (pair) => (
												<>
													<span
														className={classNames({
															'text-error bold':
																(pair.sessionSatisfactionStats?.menteeAvgSatisfaction ||
																	5) < 3 && !'',
														})}
													>
														{pair.sessionSatisfactionStats?.menteeAvgSatisfaction || '- '}
													</span>
													{' | '}
													<span
														className={classNames({
															'text-error bold':
																(pair.sessionSatisfactionStats?.mentorAvgSatisfaction ||
																	5) < 3,
														})}
													>
														{pair.sessionSatisfactionStats?.mentorAvgSatisfaction || '- '}
													</span>
												</>
											),
											sortable: true,
										},
										// {
										// 	label: t('programPanel.pair.list.feedbackShort'),
										// 	name: 'feedback',
										// 	body: (pair) => {
										// 		const surveyStats = {
										// 			mentee: {
										// 				created: Boolean(pair.menteeMembership.surveyStats?.created),
										// 				completed: Boolean(
										// 					pair.menteeMembership.surveyStats?.completed
										// 				),
										// 			},
										// 			mentor: {
										// 				created: Boolean(pair.mentorMembership.surveyStats?.created),
										// 				completed: Boolean(
										// 					pair.mentorMembership.surveyStats?.completed
										// 				),
										// 			},
										// 		};
										// 		return (
										// 			<div className="flex flex-row align-items-center">
										// 				{!surveyStats.mentee.created && (
										// 					<>
										// 						<Tooltip
										// 							target={`#pair-${pair.id}-survey-mentee-empty`}
										// 							content={t(
										// 								'programPanel.pair.list.tooltip.feedback.empty'
										// 							)}
										// 						/>
										// 						<i
										// 							id={`pair-${pair.id}-survey-mentee-empty`}
										// 							className="mt-1 mr-2 pi pi-times-circle text-gray text-xl"
										// 						/>
										// 					</>
										// 				)}
										// 				{surveyStats.mentee.created && !surveyStats.mentee.completed && (
										// 					<>
										// 						<Tooltip
										// 							target={`#pair-${pair.id}-survey-mentee-uncompleted`}
										// 							content={t(
										// 								'programPanel.pair.list.tooltip.feedback.uncompleted'
										// 							)}
										// 						/>
										// 						<i
										// 							id={`pair-${pair.id}-survey-mentee-uncompleted`}
										// 							className="mt-1 mr-2 pi pi-times-circle text-red-400 text-xl"
										// 						/>
										// 					</>
										// 				)}
										// 				{surveyStats.mentee.created && surveyStats.mentee.completed && (
										// 					<>
										// 						<Tooltip
										// 							target={`#pair-${pair.id}-survey-mentee-completed`}
										// 							content={t(
										// 								'programPanel.pair.list.tooltip.feedback.completed'
										// 							)}
										// 						/>
										// 						<i
										// 							id={`pair-${pair.id}-survey-mentee-completed`}
										// 							className="mt-1 mr-2 pi pi-check-circle text-green-400 text-xl"
										// 						/>
										// 					</>
										// 				)}
										// 				{!surveyStats.mentor.created && (
										// 					<>
										// 						<Tooltip
										// 							target={`#pair-${pair.id}-survey-mentor-empty`}
										// 							content={t(
										// 								'programPanel.pair.list.tooltip.feedback.empty'
										// 							)}
										// 						/>
										// 						<i
										// 							id={`pair-${pair.id}-survey-mentor-empty`}
										// 							className="mt-1 mr-2 pi pi-times-circle text-gray text-xl"
										// 						/>
										// 					</>
										// 				)}
										// 				{surveyStats.mentor.created && !surveyStats.mentor.completed && (
										// 					<>
										// 						<Tooltip
										// 							target={`#pair-${pair.id}-survey-mentor-uncompleted`}
										// 							content={t(
										// 								'programPanel.pair.list.tooltip.feedback.uncompleted'
										// 							)}
										// 						/>
										// 						<i
										// 							id={`pair-${pair.id}-survey-mentor-uncompleted`}
										// 							className="mt-1 mr-2 pi pi-times-circle text-red-400 text-xl"
										// 						/>
										// 					</>
										// 				)}
										// 				{surveyStats.mentor.created && surveyStats.mentor.completed && (
										// 					<>
										// 						<Tooltip
										// 							target={`#pair-${pair.id}-survey-mentor-completed`}
										// 							content={t(
										// 								'programPanel.pair.list.tooltip.feedback.completed'
										// 							)}
										// 						/>
										// 						<i
										// 							id={`pair-${pair.id}-survey-mentor-completed`}
										// 							className="mt-1 mr-2 pi pi-check-circle text-green-400 text-xl"
										// 						/>
										// 					</>
										// 				)}
										// 			</div>
										// 		);
										// 	},
										// 	// sortable: true,
										// },
										{
											label: t('programPanel.pair.list.goalsRealizationShort'),
											longLabel: t('programPanel.pair.list.goalsRealization'),
											name: 'avgGoalsRatingSum',
											body: (pair) => (
												<>
													<span
														className={classNames({
															'text-error bold': (pair.avgGoalsRating.mid || 5) < 3,
														})}
													>
														{pair.avgGoalsRating.mid || '-'}
													</span>
													{' | '}
													<span
														className={classNames({
															'text-error bold': (pair.avgGoalsRating.last || 5) < 3,
														})}
													>
														{pair.avgGoalsRating.last || '-'}
													</span>
												</>
											),
											// sortable: true,
										},
										{
											label: t('programPanel.pair.list.finalReportShort'),
											longLabel: t('programPanel.pair.list.finalReport'),
											name: 'finalReportReady',
											body: (pair) =>
												pair.stats.finalReportReady ? (
													<i className="mt-1 mr-2 pi pi-check-circle text-green-400 text-xl" />
												) : (
													<i className="mt-1 mr-2 pi pi-times-circle text-red-400 text-xl" />
												),
											sortable: true,
										},
									]}
								/>
								<Paginator
									first={
										programPairsData.pagination.currentPage *
											programPairsData.pagination.itemsPerPage -
										programPairsData.pagination.itemsPerPage +
										1
									}
									rows={programPairsData.pagination.itemsPerPage}
									totalRecords={programPairsData.pagination.all}
									onPageChange={handlePageChange}
								/>
							</>
						)}
					</div>
				</BoxSection>
			</Box>
		</>
	);
};

export default ProgramPairList;
