import React, { useState } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { postsApi } from 'api';
import { usePanel } from 'contexts/panelContext';
import SubPageTitle from 'components/_common/panel/SubPageTitle';
import { Post } from 'types/Dashboard';
import { Spinner } from 'components/_new/Spinner';
import { InputText } from 'components/_new/InputText';
import { Button } from 'components/_new/Button';
import NotificationPost from './NotificationPost';
import ProgressSteps from '../_common/ProgressSteps/ProgressSteps';

const Wall = () => {
	const { currentProgramMembership, currentApplicationData } = usePanel();
	const { t } = useTranslation();

	const { control, handleSubmit } = useForm({ defaultValues: { search: '' } });

	const [search, setSearch] = useState<string | null>(null);

	const postsPerLoad = 10;
	const { refetch, data, fetchNextPage, isLoading, isFetching, isFetchingNextPage, isSuccess, hasNextPage } =
		useInfiniteQuery(
			[
				'posts',
				{
					membershipId: currentProgramMembership?.id,
					applicationId: currentApplicationData.id,
					search,
				},
			],
			({ pageParam }) =>
				postsApi.getPosts({
					membershipId: currentProgramMembership?.id,
					applicationId: currentApplicationData.id,
					params: {
						page: pageParam || 1,
						pageSize: postsPerLoad,
						search,
					},
				}),
			{
				onSuccess: ({ pages }) => pages,
				enabled: true,
				keepPreviousData: true,
				getNextPageParam: ({ pagination }) => {
					const nextPage = pagination.currentPage + 1;
					if (nextPage > pagination.itemsPerPage) {
						return false;
					}
					return nextPage;
				},
			}
		);

	const posts = (data?.pages || []).reduce<Post[]>((current, item) => {
		return [...current, ...item.data];
	}, []);

	const handleSubmitForm = handleSubmit(({ search: inputSearch }) => {
		if (inputSearch === search) {
			refetch();
		} else {
			setSearch(inputSearch && inputSearch.length > 0 ? inputSearch : null);
		}
	});

	const [searchMode, setSearchMode] = useState(false);
	const noPosts = !isLoading && isSuccess && posts && posts.length === 0;

	return (
		<div className="h-full">
			<ProgressSteps />
			<SubPageTitle
				title={t('userPanel.dashboard.notifications')}
				right={() =>
					searchMode ? (
						<form onSubmit={handleSubmitForm} className="flex flex-row justify-content-end gap-2">
							<Controller
								control={control}
								name="search"
								render={({ field }) => (
									<InputText
										inline
										search
										{...field}
										onChange={(event) => {
											if (event.currentTarget.value === '') {
												setSearchMode(false);
												setSearch(null);
												refetch();
											}
											field?.onChange(event);
										}}
									/>
								)}
							/>
							<Button submit label={t('programPanel.post.searchPosts')} loading={isFetching} />
						</form>
					) : (
						<Button
							label={t('actions.search')}
							onClick={() => setSearchMode((prev) => !prev)}
							icon="search"
							iconOnly
							variant="primary-text"
							variantSize="lg"
						/>
					)
				}
			/>
			{isLoading && <Spinner />}
			{noPosts && <p>{search ? t('programPanel.post.noPostsSearch') : t('programPanel.post.noPosts')}</p>}
			{isSuccess &&
				posts &&
				posts.length > 0 &&
				posts.map((post) => (
					<NotificationPost
						key={post.id}
						postData={post}
						withComments
						getPostCommentsRequest={({ postId, pageParam }) => {
							return postsApi.getPostComments({
								postId,
								params: {
									page: pageParam[0] || 1,
									limit: 3,
								},
							});
						}}
						addPostCommentRequest={({ postId, payload }) => {
							return postsApi.addPostComment({ postId, payload });
						}}
					/>
				))}
			{!noPosts && hasNextPage && (
				<div className="flex justify-content-center">
					<Button label={t('misc.loadMore')} onClick={() => fetchNextPage()} loading={isFetchingNextPage} />
				</div>
			)}
		</div>
	);
};

export default Wall;
