import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CreateGoalPayload, UpdateGoalPayload } from 'types/payloads/GoalPayload';
import { Goal } from 'types/Goal';

import CustomConfirmDialog from 'components/_common/ConfirmDialog';

import GoalForm from './GoalForm';
import GoalText from './GoalText';
import { Button } from '../_common/Button';

interface Props {
	goalData: Goal;
	index: number;
	editedGoal: number | null;
	getGoals: () => void;
	updateGoal: (goalValue: UpdateGoalPayload) => void;
	saveNewGoal: (goalValue: CreateGoalPayload) => void;
	deleteGoal: (deletedGoalId: number) => void;
	setEditedGoal: (id: number | null) => void;
	bold?: boolean;
}

const GoalItem = ({
	goalData,
	index,
	editedGoal,
	getGoals,
	saveNewGoal,
	updateGoal,
	deleteGoal,
	setEditedGoal,
	bold = false,
}: Props) => {
	const { t } = useTranslation();
	const [editPopup, setEditPopup] = useState(false);

	const onEditClick = () => {
		getGoals();
		setEditedGoal(goalData.id);
	};
	const deleteGoalConfirm = () => {
		deleteGoal(goalData.id);
		setEditedGoal(null);
	};

	const isEdited = editedGoal === goalData.id;

	const cancelEdit = () => {
		setEditedGoal(null);
		getGoals();
	};

	const goalFormId = `goal-form-${goalData.id ? goalData.id : `idx-${index}`}`;

	return (
		<div
			className="flex flex-column gap-2 b-bottom pl-4 pr-4 pt-2 pb-2 mb-4 border-round-lg"
			style={{ background: 'rgb(252,251,255)', boxShadow: ' 6px 6px 12px #c4c1d7, -6px -6px 12px #ffffff' }}
		>
			<div
				className="flex flex-row gap-2 w-full justify-content-between align-items-center pb-2"
				style={{ borderBottom: '1px solid lightGray' }}
			>
				<div
					className="p-2 ml-2 text-center flex align-items-center justify-content-center goal-num"
					style={{
						fontSize: '20px',
						width: 32,
						height: 32,
					}}
				>
					{`${index}`}
				</div>
				{editedGoal !== goalData.id && (
					<div>
						<Button
							className="p-button-text p-button-label text-black-alpha-80 p-button-sm"
							icon="pi pi-pencil text-purple-500"
							onClick={onEditClick}
							label={t('actions.edit')}
							size="sm"
						/>

						<Button
							className="p-button-text p-button-label p-button-danger text-black-alpha-80 p-button-sm"
							icon="pi pi-trash text-pink-500"
							onClick={() => setEditPopup(true)}
							label={t('actions.remove')}
							size="sm"
						/>
					</div>
				)}
			</div>
			{isEdited ? (
				<GoalForm
					id={goalFormId}
					editedGoal={editedGoal}
					goalData={goalData}
					setEditedGoal={setEditedGoal}
					saveNewGoal={saveNewGoal}
					updateGoal={updateGoal}
					getGoals={getGoals}
				/>
			) : (
				<GoalText goalData={goalData} bold={bold} />
			)}
			{editedGoal === goalData.id && (
				<div className="flex items-center justify-content-end gap-2">
					<Button
						type="submit"
						form={goalFormId}
						label={t('actions.save')}
						className="p-button-primary p-button-sm"
						icon="pi pi-check"
						iconPos="right"
						size="sm"
					/>
					<Button
						label={t('actions.cancel')}
						type="button"
						onClick={cancelEdit}
						className="p-button-primary p-button-outlined p-button-sm"
						icon="pi pi-times"
						iconPos="right"
						size="sm"
					/>
				</div>
			)}
			<CustomConfirmDialog
				confirmationOpen={editPopup}
				setConfirmationOpen={() => setEditPopup(false)}
				message={t('userPanel.goal.removeGoalConfirm')}
				handleAccept={deleteGoalConfirm}
			/>
		</div>
	);
};

export default GoalItem;
