import React, {
	PropsWithChildren,
	Ref,
	forwardRef,
	ReactNode,
	useCallback,
	useState,
	useMemo,
	MouseEventHandler,
	useRef,
} from 'react';
import { createPortal } from 'react-dom';
import { animated, useSpring } from 'react-spring';
import composeRefs from '@seznam/compose-react-refs';
import { universalRenderer } from 'utils/universalRenderer';
import { useEventListener, useUpdateEffect } from 'primereact/hooks';
import { classNames } from 'primereact/utils';
import './Dialog.scss';
import { startIndex, baseIndex, decrementIndex, incrementIndex } from '../UIStorage/UIStorage';

export type DialogSize = 'xl' | 'lg' | 'md' | 'sm';

export type DialogBaseProps = PropsWithChildren<{
	visible?: boolean;
	onHide?: () => any;
	header?: ReactNode | (() => ReactNode);
	footer?: ReactNode | (() => ReactNode);
	className?: string;
	headerClassName?: string;
	bodyClassName?: string;
	footerClassName?: string;
	size?: DialogSize;
	container?: HTMLElement;
	maxHeight?: boolean;
	contentRef?: Ref<HTMLDivElement>;
}>;

export type DialogBaseExtendProps = Omit<DialogBaseProps, 'header' | 'footer'>;

export const DialogBase = forwardRef(
	(
		{
			visible = false,
			onHide,
			header,
			footer,
			children,
			className,
			headerClassName,
			bodyClassName,
			footerClassName,
			size = 'md',
			container = document.body,
			maxHeight,
			contentRef,
		}: DialogBaseProps,
		ref: Ref<HTMLDivElement>
	) => {
		const [zIndex, setZIndex] = useState(baseIndex);

		const containerInnerRef = useRef(null);
		const onKeyDown = useCallback(
			(event: any) => {
				const { key, target } = event;
				if (key === 'Escape') {
					if (onHide) {
						onHide();
					}
				}
				// TODO: prevent focus with tab outside dialog, when is open
				// if (key === 'Tab' && containerInnerRef?.current) {
				// 	if (!containerInnerRef.current.contains(target)) {
				// 	}
				// 	console.log('tab', target);
				// }
			},
			[onHide]
		);
		const [bindDocumentKeyDownListener, unbindDocumentKeyDownListener] = useEventListener({
			type: 'keydown',
			listener: (event: any) => onKeyDown(event),
		});

		// useUpdateEffect(() => {
		// 	if (zIndex === startIndex) {
		// 		document.body.classList.remove('overflow-y-hidden');
		// 	} else {
		// 		document.body.classList.add('overflow-y-hidden');
		// 	}
		// }, [zIndex]);

		const [displayModal, setDisplayModal] = useState<boolean>(false);
		useUpdateEffect(() => {
			if (visible) {
				setZIndex(incrementIndex());
				bindDocumentKeyDownListener();
				setDisplayModal(true);
			} else {
				setZIndex(decrementIndex());
				unbindDocumentKeyDownListener();
			}
		}, [visible]);

		const backdropStyle = useSpring({
			opacity: visible ? 1 : 0,
			config: { duration: 250 },
			onResolve: ({ value, finished }) => {
				if (finished && String(value) === '0') {
					setDisplayModal(false);
				}
			},
		});

		const finalContainerOuterClassName = useMemo(
			() => classNames('dialog-container-outer', { 'dialog-container-outer-visible': visible }, className),
			[visible, className]
		);

		const finalContainerInnerClassName = useMemo(
			() =>
				classNames(
					'dialog-container-inner',
					`dialog-container-inner-${size}`,
					{ 'dialog-container-inner-visible': visible },
					{ 'dialog-container-inner-max': maxHeight },
					className
				),
			[visible, size, className, maxHeight]
		);

		const backdropRef = useRef(null);
		const containerOuterRef = useRef(null);
		const handleBackdropClick: MouseEventHandler<HTMLDivElement> = useCallback(
			({ target }) => {
				// only when click on backdrop outer container
				if (target === backdropRef?.current || target === containerOuterRef?.current) {
					if (onHide) {
						onHide();
					}
				}
			},
			[backdropRef, containerOuterRef, onHide]
		);

		return createPortal(
			displayModal ? (
				<animated.div
					ref={composeRefs(ref, backdropRef)}
					className="dialog-backdrop"
					style={{ ...backdropStyle, zIndex }}
					onClick={handleBackdropClick}
				>
					<div ref={containerOuterRef} className={finalContainerOuterClassName}>
						<div ref={containerInnerRef} className={finalContainerInnerClassName}>
							{header && (
								<div className={classNames('dialog-header', headerClassName)}>
									{universalRenderer(header)}
								</div>
							)}
							<div
								ref={contentRef}
								className={classNames(
									'dialog-body p-styled-scrollbar p-styled-scrollbar-purplishblue',
									bodyClassName
								)}
							>
								{children}
							</div>
							{footer && (
								<div className={classNames('dialog-footer', footerClassName)}>
									{universalRenderer(footer)}
								</div>
							)}
						</div>
					</div>
				</animated.div>
			) : null,
			container
		);
	}
);
