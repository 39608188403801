import { httpClient, objectToFormData } from 'api';
import { Application } from 'types/Application';
import { Post } from 'types/Dashboard';
import { File, FileView, Folder, GroupedDataItem } from 'types/File';
import { ProgramMembership, ProgramMembershipStats } from 'types/Membership';
import { Paginated } from 'types/Paginated';
import { Pair, PairExtended } from 'types/Pair';
import {
	Program,
	ProgramLandingPageModuleDefine,
	ProgramLandingPageModule,
	UpdateProgramPairsParametersPayload,
	UpdateProgramRecruitmentParametersPayload,
	UpdateProgramSettingsParametersPayload,
	UpdateProgramStatusParametersPayload,
	ProgramLandingPageModulesAddPayload,
	ProgramLandingPageModulesEditPayload,
	ProgramLandingPageModulesSequencePayload,
	ProgramLandingPageModulesImagePayload,
} from 'types/Program';
import { User } from 'types/User';

export const programsApi = {
	createForOrganization: ({
		organizationName,
		displayName,
		name,
		language,
		authRequired = false,
		programType,
	}: {
		organizationName: string;
		displayName: string;
		name: string;
		language: string;
		authRequired: boolean;
		programType: string;
	}) =>
		httpClient.post<Program>(`programs/organization/${organizationName}`, {
			displayName,
			name,
			language,
			authRequired,
			programType,
		}),
	editProgram: ({
		id,
		displayName,
		// name,
		language,
		authRequired = false,
		programType,
	}: {
		id: number;
		displayName: string;
		// name: string;
		language: string;
		authRequired: boolean;
		programType: string;
	}) =>
		httpClient.put<Program>(`programs/${id}`, {
			displayName,
			// name,
			language,
			authRequired,
			programType,
		}),
	// getProgramWithMembershipInfo: (organizationName: string, programName: string) =>
	// 	httpClient.get<ProgramInfo>(`programs/${organizationName}/${programName}/info`),
	getProgramContact: (programId: number) => httpClient.get<string>(`programs/${programId}/contact`),
	getProgram: (organizationName: string, programName: string) =>
		httpClient.get<Program>(`programs/${organizationName}/${programName}`),
	getProgramAdminLabelFiles: ({ programId }: { programId: number }) =>
		httpClient.get<Array<{ id: number; name: string }>>(`programs/admin/${programId}/label-files`),
	getProgramAdmin: ({ organizationName, programName }: { organizationName: string; programName: string }) =>
		httpClient.get<Program>(`programs/admin/${organizationName}/${programName}`),
	// getProgramByName: ({ programName }: { programName: string }) =>
	// 	axiosInstance.get<Program>(`programs/${programName}`),
	getProgramAdminMemberships: ({ id, page, filter }: { id: number; page: number; filter: any }) =>
		httpClient.get<
			Paginated<
				Omit<ProgramMembership, 'user' | 'stats' | 'applications'> & {
					user: User;
					stats: ProgramMembershipStats;
					applications: Application[];
				}
			>
		>(`programs/admin/${id}/memberships`, { page, filter }),

	downloadProgramAdminMembershipsXls: ({ programId }: { programId: number }) =>
		httpClient.get<Blob>(`programs/admin/${programId}/memberships/xls`, undefined, { responseType: 'blob' }),

	getProgramMembership: ({ programId, membershipId }: { programId: number; membershipId: number }) =>
		httpClient.get<any>(`programs/admin/${programId}/membership/${membershipId}`),
	getProgramPairsPaginated: ({ id, page, filter, sort }: { id: number; page: number; filter?: any; sort?: any }) =>
		httpClient.get<Paginated<PairExtended>>(`programs/admin/${id}/pairs`, { page, filter, sort }),
	// getProgramPairs: ({ id, acceptedOnly }: { id: number; acceptedOnly?: boolean }) =>
	// 	httpClient.get<PairExtended[]>(`programs/admin/${id}/pairs`, { acceptedOnly }),
	manageMyProgramMembership: ({ id, membershipId, role }: { id: number; membershipId: number; role: string }) =>
		httpClient.put<any>(`programs/admin/${id}/membership/${membershipId}`, {
			role,
		}),
	removeMyProgramMembership: ({ id, membershipId }: { id: number; membershipId: number }) =>
		httpClient.delete<any>(`programs/admin/${id}/membership/${membershipId}`),
	getStatisticsMain: ({ id }: { id: number }) =>
		httpClient.get<any>(`programs/admin/${id}/statistics/main`, undefined, { timeout: 20000 }),
	getStatisticsQuestionarires: ({ id }: { id: number }) =>
		httpClient.get<any>(`programs/admin/${id}/statistics/questionarires`, undefined, { timeout: 20000 }),
	realoadStatistics: ({ id }: { id: number }) => httpClient.post<any>(`programs/admin/${id}/statistics/reload`),
	getOrganizationPrograms: ({ organizationName }: { organizationName: string }) =>
		httpClient.get<any>(`programs/admin/organization/${organizationName}/programs`),
	getProgramAdminApplicationsAll: ({
		programId,
		applicationRole,
		acceptedOnly = false,
	}: {
		programId: number;
		applicationRole: string;
		acceptedOnly?: boolean;
	}) =>
		httpClient.get<Application[]>(`programs/admin/${programId}/applications/${applicationRole}/all`, {
			acceptedOnly,
		}),
	getProgramAdminApplicationsPaginated: ({
		programId,
		page = 1,
		filter = {},
		sort = {},
	}: {
		programId: number;
		page?: number;
		filter?: any;
		sort?: any;
	}) =>
		httpClient.get<Paginated<Application>>(`programs/admin/${programId}/applications/paginated`, {
			page,
			filter,
			sort,
		}),
	putProgramAdminParameters: (programId: number, data: any) =>
		httpClient.put<Program>(`programs/admin/${programId}`, data),
	putProgramAdminStatusParameters: (programId: number, data: UpdateProgramStatusParametersPayload) =>
		httpClient.put<Program>(
			`programs/admin/${programId}/status`,
			objectToFormData(data, undefined, { skipUndefined: true })
		),
	putProgramAdminRecruitmentParameters: (programId: number, data: UpdateProgramRecruitmentParametersPayload) =>
		httpClient.put<Program>(`programs/admin/${programId}/recruitment`, data),
	putProgramAdminSettingsParameters: (programId: number, data: UpdateProgramSettingsParametersPayload) =>
		httpClient.put<Program>(`programs/admin/${programId}/settings`, data),
	putProgramAdminPairsParameters: (programId: number, data: UpdateProgramPairsParametersPayload) =>
		httpClient.put<Program>(`programs/admin/${programId}/pairs`, data),
	editProgramAdminContact: (programId: number, contact: string) =>
		httpClient.put<Program>(`programs/admin/${programId}/contact`, { contact }),
	getProgramAdminApplication: ({ programId, applicationId }: { programId: number; applicationId: number }) =>
		httpClient.get<Application>(`programs/admin/${programId}/application/${applicationId}`),
	getProgramAdminApplicationPairing: ({ programId, applicationId }: { programId: number; applicationId: number }) =>
		httpClient.get<Pair[]>(`programs/admin/${programId}/application/${applicationId}/pairing`),
	getProgramAdminPair: ({ programId, pairId }: { programId: number; pairId: number }) =>
		httpClient.get<PairExtended>(`programs/admin/${programId}/pair/${pairId}`),
	getProgramAdminPosts: (programId: number, page: number, perPage: number) =>
		httpClient.get<Post[]>(`programs/admin/${programId}/posts`, { page, perPage }),

	getProgramAdminLandingPageModuleDefines: (programId: number) =>
		httpClient.get<ProgramLandingPageModuleDefine[]>(`programs/admin/${programId}/landing-page-module-defines`),
	getProgramAdminLandingPageModule: (programId: number) =>
		httpClient.get<ProgramLandingPageModule[]>(`programs/admin/${programId}/landing-page-modules`),
	addProgramAdminLandingPageModule: (programId: number, data: ProgramLandingPageModulesAddPayload) =>
		httpClient.post<ProgramLandingPageModule[]>(`programs/admin/${programId}/landing-page-modules`, data),
	uploadProgramAdminLandingPageModuleImage: (programId: number, { file }: ProgramLandingPageModulesImagePayload) => {
		const formData = new FormData();
		formData.append('file', file);
		return httpClient.post<ProgramLandingPageModule[]>(
			`programs/admin/${programId}/landing-page-modules/image`,
			formData
		);
	},
	setProgramAdminLandingPageModuleSequence: (
		programId: number,
		sequences: ProgramLandingPageModulesSequencePayload
	) => httpClient.put<ProgramLandingPageModule[]>(`programs/admin/${programId}/landing-page-modules`, { sequences }),
	editProgramAdminLandingPageModule: (programId: number, id: number, data: ProgramLandingPageModulesEditPayload) =>
		httpClient.put<ProgramLandingPageModule>(`programs/admin/${programId}/landing-page-modules/${id}`, data),
	deleteProgramAdminLandingPageModule: (programId: number, id: number) =>
		httpClient.delete(`programs/admin/${programId}/landing-page-modules/${id}`),

	// deletePrograAdminPost: ({ programId, postId }: { programId: number; postId: number }) =>
	// 	axiosInstance.delete<boolean>(`programs/admin/${programId}/post/${postId}`),
	// addProgramPost: ({ programId, ...data }: any) => httpClient.post<Post>(`programs/admin/${programId}/post`, data),
	// editProgramPost: ({ programId, postId, ...data }: any) =>
	// 	axiosInstance.put<Post>(`programs/admin/${programId}/post/${postId}`, data),
	// getProgramPost: ({ programId, postId }: any) =>
	// 	axiosInstance.get<Post>(`programs/admin/${programId}/post/${postId}`),
	editProgramApplication: ({
		programId,
		applicationId,
		status,
		reason,
	}: {
		programId: number;
		applicationId: number;
		status: string;
		reason?: string;
	}) => httpClient.put(`programs/admin/${programId}/application/${applicationId}`, { status, reason }),
	deleteProgramPair: ({ programId, pairId }: { programId: number; pairId: number }) =>
		httpClient.delete(`programs/admin/${programId}/pair/${pairId}`),
	acceptProgramPair: ({ programId, pairId }: { programId: number; pairId: number }) =>
		httpClient.post(`programs/admin/${programId}/pair/${pairId}`),
	deleteProgramApplications: ({
		programId,
		applicationIds,
		withUser,
		sendMail,
		quickDelete,
	}: {
		programId: number;
		applicationIds: number[];
		withUser: boolean;
		sendMail: boolean;
		quickDelete: boolean;
	}) =>
		httpClient.delete(`programs/admin/${programId}/application/delete`, {
			data: {
				applicationIds,
				withUser,
				sendMail,
				quickDelete,
			},
		}),
	cancelDeleteProgramApplication: ({ programId, applicationId }: { programId: number; applicationId: number }) =>
		httpClient.put(`programs/admin/${programId}/application/delete/${applicationId}`),
	addProgramPair: ({
		programId,
		applicationId,
		pairApplicationId,
	}: {
		programId: number;
		applicationId: number;
		pairApplicationId: number;
	}) => httpClient.put<Pair>(`programs/admin/${programId}/application/${applicationId}/pair`, { pairApplicationId }),
	getAvailablePairsForApplication: ({ programId, applicationId }: { programId: number; applicationId: number }) =>
		httpClient.get<Application[]>(`programs/admin/${programId}/application/${applicationId}/pairs`),

	// knowledge
	getProgramKnowledgeFiles: (programId: number) => httpClient.get<Folder[]>(`programs/admin/${programId}/knowledge`),
	getProgramKnowledgeFileViews: (programId: number, fileId: number) =>
		httpClient.get<GroupedDataItem[]>(`programs/admin/${programId}/knowledge/${fileId}/views`, undefined),
	getProgramKnowledgeFile: (programId: number, fileId: number) =>
		httpClient.get<File>(`programs/admin/${programId}/knowledge/${fileId}`, undefined),
	getProgramKnowledgeFileBlob: (programId: number, fileId: number, responseAsBlob = false) =>
		httpClient.get<any>(
			`programs/admin/${programId}/knowledge/${fileId}/file`,
			undefined,
			responseAsBlob ? { responseType: 'blob' } : undefined
		),
	addProgramKnowledgeFile: (programId: number, data: Record<string, any>) => {
		const formData = new FormData();
		Object.entries(data).forEach(([k, v]) => {
			if (typeof v !== 'undefined' && v !== 'null' && v !== 'undefined') {
				formData.append(k, v);
			}
		});
		return httpClient.post<File>(`programs/admin/${programId}/knowledge`, formData);
	},
	editProgramKnowledgeFile: (programId: number, fileId: number, data: Record<string, any>) => {
		const formData = new FormData();
		Object.entries(data).forEach(([k, v]) => {
			if (typeof v !== 'undefined' && v !== 'null' && v !== 'undefined') {
				formData.append(k, v);
			}
		});
		return httpClient.put<File>(`programs/admin/${programId}/knowledge/${fileId}`, formData);
	},
	deleteProgramKnowledgeFile: (programId: number, fileId: number) =>
		httpClient.delete(`programs/admin/${programId}/knowledge/${fileId}`),

	getProgramKnowledgePaths: (programId: number) =>
		httpClient.get<string[]>(`programs/admin/${programId}/knowledge/paths`),
};
