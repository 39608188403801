import React, { useState } from 'react';
import { DialogConfirm, DialogConfirmProps } from 'components/_new/Dialog';
import { Control, Controller } from 'react-hook-form';
import { Checkbox } from 'primereact/checkbox';
import { Trans, useTranslation } from 'react-i18next';
import { Application } from 'types/Application';
import { ExpandablePanel } from 'components/_new/ExpandablePanel';
import { Avatar } from 'components/_new/Avatar';
import { Link } from 'react-router-dom';
import { useProgramPanel } from 'contexts/programPanelContext';
import { userDisplayName } from 'utils/userDisplayName';

type DeleteApplicationConfirmDialogProps = {
	control: Control<
		{
			withUser: boolean;
			sendMail: boolean;
			quickDelete: boolean;
		},
		object
	>;
	applicationList?: Application[];
} & DialogConfirmProps;

export const DeleteApplicationConfirmDialog = ({
	control,
	applicationList,
	...props
}: DeleteApplicationConfirmDialogProps) => {
	const { t } = useTranslation();
	const { data: program } = useProgramPanel();
	return (
		<DialogConfirm {...props}>
			<form className="flex flex-column gap-2">
				{applicationList && applicationList?.length > 0 && (
					<ExpandablePanel
						header={
							<div>
								<Trans
									t={t}
									i18nKey="programPanel.pairing.removeApplication.applicationsToDeleteInfo"
									components={[<span className="text-purple bold" />]}
									count={applicationList.length}
								/>
							</div>
						}
						expandedHeaderClassName="text-purple font-bold"
						contentClassName="grid"
						className="mb-4"
					>
						{applicationList.map((application) => (
							<Link
								key={application.id}
								target="_blank"
								to={`/program/${program?.name}/application/${application.id}`}
								className="col-12 md:col-6 flex flex-row gap-2 align-items-center"
							>
								<Avatar
									src={application.programMembership.user.avatar}
									size="md"
									name={userDisplayName(application.programMembership.user, null)}
								/>
								<span>{userDisplayName(application.programMembership.user)}</span>
							</Link>
						))}
					</ExpandablePanel>
				)}

				<div className="flex flex-row gap-2 align-items-center">
					<Controller
						control={control}
						name="withUser"
						render={({ field }) => <Checkbox inputId="withUser" checked={field.value} {...field} />}
					/>
					<label htmlFor="withUser">{t('programPanel.pairing.removeApplication.removeWithUser')}</label>
				</div>
				<div className="flex flex-row gap-2 align-items-center">
					<Controller
						control={control}
						name="sendMail"
						render={({ field }) => <Checkbox inputId="sendMail" checked={field.value} {...field} />}
					/>
					<label htmlFor="sendMail">{t('programPanel.pairing.removeApplication.removeSendMail')}</label>
				</div>
				<p>{t('programPanel.pairing.removeApplication.confirmText')}</p>

				<div className="flex flex-row gap-2 align-items-center">
					<Controller
						control={control}
						name="quickDelete"
						render={({ field }) => <Checkbox inputId="quickDelete" checked={field.value} {...field} />}
					/>
					<label htmlFor="quickDelete">{t('programPanel.pairing.removeApplication.quickDelete')}</label>
				</div>
			</form>
		</DialogConfirm>
	);
};
