import React, { FC, useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { Helmet } from 'react-helmet';
import { closestTo } from 'date-fns';
import Fuse from 'fuse.js';
import { RouterUrlParams } from 'App';
import { Folder, File, FileType } from 'types/File';
import { programsApi } from 'api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Tooltip } from 'primereact/tooltip';
import { FilterMatchMode } from 'primereact/api';
import FilesSearchbar from 'components/knowledgeDatabase/FilesSearchbar';
import DateCell from 'components/knowledgeDatabase/DateCell';
import { matchFiles } from 'components/knowledgeDatabase/helpers';
import { useProgramPanel } from 'contexts/programPanelContext';
import CustomConfirmDialog from 'components/_common/ConfirmDialog';
import { useGlobal } from 'contexts/globalContext';
import { Box, BoxSection } from 'components/_new/Box';
import { deslugify, slugify } from 'utils/helpers';
import { Button } from 'components/_new/Button';
import { Icon } from 'components/_new/Icon';
import ProgramKnowledgeFileDisplayModule from './ProgramKnowledgeFileDisplayModule';
import { ProgramKnowledgeFileViewsModal } from './ProgramKnowledgeFileViewsModal';

interface Props {
	filesData: Folder[];
	refetch?: () => void;
	breadcrumb: {
		home: string;
	};
}
const ProgramKnowledgeFiles: FC<Props> = ({ filesData, refetch, breadcrumb }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { '*': filePath } = useParams() as RouterUrlParams;

	const { toastRef } = useGlobal();
	const { data: program, organizationName, programName } = useProgramPanel();

	const homeBreadcrumb = {
		icon: 'pi pi-book',
		command: () => {
			setSearchValue('');
			navigate(breadcrumb.home);
		},
	};
	const [breadcrumbs, setBreadcrumbs] = useState<{ label: string; command: () => void }[]>([]);
	const [viewableFiles, setViewableFiles] = useState<Folder[] | File[] | Array<File | Folder>>([]);
	const [selectedFile, setSelectedFile] = useState<File>();
	const [searchValue, setSearchValue] = useState('');
	const [filters, setFilters] = useState({
		type: { value: '', matchMode: FilterMatchMode.EQUALS },
	});
	const getFolderFiles = useCallback(
		(searchedName: string) => filesData.find((folder) => folder.name === searchedName)?.files as unknown as File[],
		[filesData]
	);
	const flattenedFiles = useMemo(
		() =>
			filesData.reduce(
				(flatFilesArr: Array<File | Folder>, folder: Folder): Array<File | Folder> => [
					...flatFilesArr,
					...folder.files,
					folder,
				],
				[]
			),
		[filesData]
	);

	useEffect(() => {
		if (!filePath) {
			if (refetch) {
				refetch();
			}
			setViewableFiles(filesData);
			setSelectedFile(undefined);
		} else {
			const requestedData = matchFiles(filesData, filePath);

			if (requestedData && requestedData.type !== 'directory') {
				setSelectedFile(requestedData as File);
			} else {
				setSelectedFile(undefined);
				setViewableFiles(((requestedData as Folder)?.files as unknown as File[]) ?? []);
			}
		}

		const breadcrumsData = filePath.split('/').reduce((current: any, currentItem: any, currentIndex) => {
			const label =
				flattenedFiles.find((file) => slugify(file.name) === currentItem)?.name ?? deslugify(currentItem);

			const prevSlug = current[currentIndex - 1]?.slug || null;
			const slug = slugify(currentItem);
			const finalSlug = [prevSlug, slug].filter(Boolean).join('/');
			const navigatePath = `/program/${organizationName}/${programName}/knowledge/browse/${finalSlug}`;

			current.push({
				label,
				slug,
				navigatePath,
			});

			return current;
		}, []);

		setBreadcrumbs(
			breadcrumsData.map(({ label, navigatePath, slug }: any) => {
				return {
					label,
					command: () => {
						navigate(navigatePath);
						setSearchValue('');
						if (navigatePath !== slug) {
							setViewableFiles(getFolderFiles(slug));
						}
					},
				};
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filePath, navigate, filesData, getFolderFiles, flattenedFiles]);

	const handleRowClick = (rowData: Folder | File) => {
		switch (rowData.type) {
			// disable on prgoram panel for navigate view
			// case 'link':
			// 	window.open((rowData as File).url as string, '_blank');
			// 	break;
			default:
				navigate(
					(rowData as File).directory
						? `${slugify((rowData as File).directory)}/${slugify(rowData.name)}`
						: slugify(rowData.name)
				);
				setViewableFiles((rowData as Folder).files);
				break;
		}
	};

	const handleSearch = useCallback(
		(value: string) => {
			const fileNameFromUrl = filePath.split('/').slice(-1)[0];
			const previouslyDisplayedFiles = getFolderFiles(fileNameFromUrl) ?? filesData;

			const fuse = new Fuse(flattenedFiles, {
				threshold: 0.5,
				findAllMatches: true,
				ignoreLocation: true,
				keys: ['name'], // TODO: add search by description when added by backend
			});

			setSearchValue(value);

			const filteredFiles = fuse.search(value).map((result) => result.item);

			setViewableFiles(value ? filteredFiles : previouslyDisplayedFiles);
		},
		[filesData, flattenedFiles, filePath, getFolderFiles]
	);

	const handleFilter = (value: string) => {
		const newFilters = { ...filters };
		newFilters.type.value = value;

		setFilters(newFilters);
	};

	const renderIcon = (type: FileType | undefined) => {
		switch (type) {
			case 'pdf':
				return <i className="pi pi-file-pdf" />;
			case 'link':
				return <i className="pi pi-globe" />;
			case 'html_wyswig':
			case 'html':
				return <i className="pi pi-file" />;
			case 'file':
				return <i className="pi pi-cloud-download" />;
			case 'video':
				return <i className="pi pi-video" />;
			default:
				return <i className="pi pi-folder" />;
		}
	};

	const renderName = (rowData: Folder | File) => {
		switch (rowData.type) {
			case 'link':
				return (
					<>
						<Tooltip target=".tooltip" position="right" />
						<div className="flex align-items-center">
							<div
								data-pr-tooltip={t('userPanel.files.externalLinkTooltip')}
								data-pr-at="right+30 center"
								className="tooltip"
							>
								{rowData.name}
							</div>
							<i
								className="pi pi-external-link text-xs tooltip pl-2"
								data-pr-tooltip={t('userPanel.files.externalLinkTooltip')}
								data-pr-at="right+10 center"
							/>
						</div>
					</>
				);
			default:
				return rowData.name;
		}
	};

	const renderDate = (item: Folder | File) => {
		if (item.type === 'directory') {
			const dates = (item as Folder).files.map((file) => new Date(file.updatedAt));
			const closestDate = closestTo(new Date(), dates);

			return closestDate ? <DateCell date={closestDate} /> : null;
		}

		return <DateCell date={new Date((item as File).updatedAt)} />;
	};

	const currentItem = breadcrumbs[breadcrumbs.length - 1]?.label || null;

	const [delteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
	const { mutate: deleteFileMutate, isLoading: deleteFileLoading } = useMutation(
		() => programsApi.deleteProgramKnowledgeFile(Number(program?.id), Number(selectedFile?.id)),
		{
			onSuccess: () => {
				setSelectedFile(undefined);
				navigate(`/program/${organizationName}/${programName}/knowledge/browse`);
				toastRef?.current?.show({
					severity: 'success',
					life: 3000,
					summary: t('misc.success'),
					detail: t('programPanel.knowledge.removedSuccessfully'),
				});
			},
		}
	);
	const handleDeleteFile = () => {
		deleteFileMutate();
	};

	const prepareLink = (type: string) => {
		if (selectedFile) {
			const dir = slugify(selectedFile.directory);
			const fileName = slugify(selectedFile.name);
			return `${process.env.REACT_APP_FRONT_URL}/panel/${organizationName}/${programName}/${type}/files/${dir}/${fileName}`;
		}
		return null;
	};

	const [viewsModal, setViewsModal] = useState(false);

	return (
		<>
			{currentItem && <Helmet title={currentItem} />}
			<BreadCrumb model={breadcrumbs} home={homeBreadcrumb} className="mb-3" />

			{selectedFile ? (
				<div className="flex flex-row gap-2 flex-wrap">
					<ProgramKnowledgeFileViewsModal
						visible={viewsModal}
						onHide={() => {
							setViewsModal(false);
						}}
						program={program!}
						fileId={selectedFile.id}
					/>
					<CustomConfirmDialog
						confirmationOpen={delteConfirmationOpen}
						setConfirmationOpen={setDeleteConfirmationOpen}
						header={t('programPanel.knowledge.removeConfirm')}
						handleAccept={handleDeleteFile}
					/>
					<div className="flex-1">
						<ProgramKnowledgeFileDisplayModule selectedFile={selectedFile} />
					</div>
					<div className="flex flex-column gap-2" style={{ width: '240px' }}>
						<Box variant="white-bordered">
							<BoxSection header={t('programPanel.knowledge.linksToMaterial')}>
								<div>
									<h3>{t('programPanel.knowledge.forMentees')}:</h3>
									{selectedFile.menteeAccess ? (
										<>
											<p className="bg-gray-200  text-gray-700 text-wrap-any border-round p-2 text-xs selectable">
												{prepareLink('mentee')}
											</p>
											<Button
												label={t('programPanel.knowledge.copyToClipboard')}
												variant="primary-text"
												variantSize="sm"
												block
												icon="copy"
												onClick={() => {
													const text = prepareLink('mentee');
													if (text) {
														navigator.clipboard.writeText(text);
														toastRef?.current?.show({
															severity: 'success',
															life: 3000,
															summary: t('misc.success'),
															detail: t('programPanel.knowledge.copiedToClipboard'),
														});
													}
												}}
											/>
										</>
									) : (
										<p>{t('programPanel.knowledge.materialUnavailable')}</p>
									)}
								</div>
								<div>
									<h3>{t('programPanel.knowledge.forMentors')}:</h3>
									{selectedFile.mentorAccess ? (
										<>
											<p className="bg-gray-200 text-gray-700 text-wrap-any border-round p-2 text-xs selectable">
												{prepareLink('mentor')}
											</p>
											<Button
												label={t('programPanel.knowledge.copyToClipboard')}
												variant="primary-text"
												variantSize="sm"
												block
												icon="copy"
												onClick={() => {
													const text = prepareLink('mentor');
													if (text) {
														navigator.clipboard.writeText(text);
														toastRef?.current?.show({
															severity: 'success',
															life: 3000,
															summary: t('misc.success'),
															detail: t('programPanel.knowledge.copiedToClipboard'),
														});
													}
												}}
											/>
										</>
									) : (
										<p>{t('programPanel.knowledge.materialUnavailable')}</p>
									)}
								</div>
							</BoxSection>
						</Box>
						<Box variant="white-bordered">
							<BoxSection header={t('programPanel.knowledge.edit')}>
								<div className="flex flex-column gap-2">
									<Tooltip
										target="#editMaterialButton"
										position="bottom"
										disabled={selectedFile.canEdit}
									>
										{t('programPanel.knowledge.canNotChange')}
									</Tooltip>
									<span id="editMaterialButton">
										<Button
											label={t('programPanel.knowledge.editMaterial')}
											onClick={() => {
												navigate(
													`/program/${organizationName}/${programName}/knowledge/edit/${selectedFile.id}`
												);
											}}
											block
											disabled={!selectedFile.canEdit || deleteFileLoading}
										/>
									</span>
									<Tooltip
										target="#removeMaterialButton"
										position="bottom"
										disabled={selectedFile.canEdit}
									>
										{t('programPanel.knowledge.canNotChange')}
									</Tooltip>
									<span id="removeMaterialButton">
										<Button
											label={t('programPanel.knowledge.removeFromKnowledgeBase')}
											onClick={() => {
												setDeleteConfirmationOpen(true);
											}}
											className="w-full p-"
											variantSize="xs"
											block
											loading={deleteFileLoading}
											disabled={!selectedFile.canEdit || deleteFileLoading}
											variant="danger"
										/>
									</span>
								</div>
							</BoxSection>
						</Box>
						<Box variant="white-bordered">
							<BoxSection header={t('programPanel.knowledge.views')}>
								<div className="flex flex-row gap-2 justify-content-between">
									<div>
										<Icon name="eye" className="text-purple" />
										<span className="ml-2">{t('misc.manyMentees')}</span>
									</div>
									<span className="font-bold">{selectedFile.programViewStats?.menteeViews || 0}</span>
								</div>
								<div className="flex flex-row gap-2 justify-content-between">
									<div>
										<Icon name="eye" className="text-purple" />
										<span className="ml-2">{t('misc.manyMentors')}</span>
									</div>
									<span className="font-bold">{selectedFile.programViewStats?.mentorViews || 0}</span>
								</div>
							</BoxSection>
							<BoxSection header={t('programPanel.knowledge.ratings')}>
								<div className="flex flex-row gap-2 justify-content-around mb-2">
									<div>
										<Icon name="thumbs-up" iconStyle="regular" className="text-purple" />
										<span className="font-bold ml-2">
											{selectedFile.programRatingStats?.positive || 0}
										</span>
									</div>
									<div>
										<Icon name="thumbs-down" iconStyle="regular" className="text-purple" />
										<span className="font-bold ml-2">
											{selectedFile.programRatingStats?.negative || 0}
										</span>
									</div>
								</div>

								<Button
									label={t('programPanel.knowledge.viewAllViews')}
									className="mx-auto"
									onClick={() => {
										setViewsModal(true);
									}}
									disabled={
										!selectedFile.programViewStats?.mentorViews &&
										!selectedFile.programViewStats?.menteeViews
									}
									block
								/>
							</BoxSection>
						</Box>
					</div>
				</div>
			) : (
				<DataTable
					dataKey="id"
					header={
						<FilesSearchbar
							searchValue={searchValue}
							onSearch={handleSearch}
							selectedFilterOption={filters.type.value}
							onFilter={handleFilter}
						/>
					}
					value={viewableFiles as File[] | Folder[]}
					emptyMessage={t('userPanel.files.fileNotFound')}
					onRowClick={({ data }) => handleRowClick(data)}
					rowClassName={() => 'cursor-pointer h-4rem'}
					loading={!filesData}
					filters={filters}
					filterDisplay="menu"
					rowHover
					className="table-hiddable"
				>
					<Column
						body={(item) => renderIcon(item.type)}
						style={{ width: '5%' }}
						className="table-hiddable-cell"
						headerClassName="table-hiddable-cell"
					/>
					<Column
						body={(item) => {
							return (
								<div>
									<span className="inline-block lg:hidden mr-2">{renderIcon(item.type)}</span>
									<span>{renderName(item)}</span>
								</div>
							);
						}}
						header={t('userPanel.files.name')}
						style={{ width: '60%' }}
						className="text-left"
					/>
					<Column
						body={(item) => renderDate(item)}
						header={t('userPanel.files.lastModification')}
						style={{ width: '35%' }}
						className="table-hiddable-cell"
						headerClassName="table-hiddable-cell"
					/>
					<Column
						body={(item) =>
							// eslint-disable-next-line no-nested-ternary
							item.type !== 'directory' ? (
								item.mentorAccess ? (
									<span>
										<i className="mx-1 pi pi-check-circle text-green-400" />
									</span>
								) : (
									<span>
										<i className="mx-1 pi pi-times-circle text-red-400" />
									</span>
								)
							) : (
								<span />
							)
						}
						header={t('userPanel.files.mentorAccess')}
						style={{ width: '5%' }}
						className="table-hiddable-cell"
						headerClassName="table-hiddable-cell"
					/>
					<Column
						body={(item) =>
							// eslint-disable-next-line no-nested-ternary
							item.type !== 'directory' ? (
								item.menteeAccess ? (
									<span>
										<i className="mx-1 pi pi-check-circle text-green-400" />
									</span>
								) : (
									<span>
										<i className="mx-1 pi pi-times-circle text-red-400" />
									</span>
								)
							) : (
								<span />
							)
						}
						header={t('userPanel.files.menteeAccess')}
						style={{ width: '5%' }}
						className="table-hiddable-cell"
						headerClassName="table-hiddable-cell"
					/>
					<Column
						body={({ type, id, canEdit }) =>
							type !== 'directory' ? (
								<>
									<Tooltip target={`#editButton-${id}`} disabled={canEdit} position="bottom">
										{t('programPanel.knowledge.canNotChange')}
									</Tooltip>
									<span id={`editButton-${id}`}>
										<Button
											label={t('actions.edit')}
											onClick={() => {
												navigate(
													`/program/${organizationName}/${programName}/knowledge/edit/${id}`
												);
											}}
											disabled={!canEdit}
										/>
									</span>
								</>
							) : (
								<span />
							)
						}
					/>
				</DataTable>
			)}
		</>
	);
};

export default ProgramKnowledgeFiles;
