import React, { PropsWithChildren } from 'react';
import { ChatDialog } from './ChatDialog';
import { ChatContextProvider } from './ChatContextProvider';

export const CHAT_THREAD_KEY = 'chat-thread';
export const CHAT_THREAD_SELF_KEY = 'chat-thread-self';

// eslint-disable-next-line @typescript-eslint/ban-types
export const ChatProvider = ({ children }: PropsWithChildren<{}>) => {
	return (
		<ChatContextProvider>
			<ChatDialog />
			{children}
		</ChatContextProvider>
	);
};
