import { classNames } from 'primereact/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Goal } from 'types/Goal';

import getGoalRows from './components/getGoalRows';

interface Props {
	goalData: Goal;
	bold?: boolean;
}

const GoalText = ({ goalData, bold = false }: Props) => {
	const { t } = useTranslation();
	const rows = getGoalRows(goalData, t);

	return (
		<div>
			{rows?.map(({ label, text }) => (
				<div key={label} className="grid mt-2">
					<div
						className={classNames('col-12 lg:col-4 p-2', {
							'font-bold': bold,
						})}
					>
						{label}
					</div>
					<div className="col-12 lg:col-8" style={{ whiteSpace: 'pre-line' }}>
						{text || '-'}
					</div>
				</div>
			))}
		</div>
	);
};

export default GoalText;
