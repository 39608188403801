import parseOriginal, { HTMLReactParserOptions } from 'html-react-parser';

type ParseOptions = HTMLReactParserOptions & {
	nl2br?: boolean;
};

const nl2br = (text: string) =>
	// eslint-disable-next-line no-useless-concat
	text && text.length > 0 ? text.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br>' + '$2') : text;

export const parseHtml = (_html: string, _options?: ParseOptions) => {
	if (typeof _html !== 'string' || !_html || _html.length === 0) {
		return null;
	}
	const { nl2br: useNl2Br, ...options } = { nl2br: true, trim: true, ..._options };
	const html = useNl2Br ? nl2br(_html) : _html;
	return parseOriginal(html, options);
};
