import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { surveyApi } from 'api/survey';
import { usePanel } from 'contexts/panelContext';
import { FinalSurveyPresenter } from 'components/_common/FnalSurveyPresenter';
import { Spinner } from 'components/_new/Spinner';
import { Dialog, DialogBaseExtendProps } from 'components/_new/Dialog';
import { Button } from 'components/_new/Button';
import iconChecklist from 'assets/icon-checklist.png';

type FinalSurveyPanelProps = DialogBaseExtendProps & { withIntro?: boolean };

export const FinalSurveyPanel = ({ withIntro = false, ...dialogProps }: FinalSurveyPanelProps) => {
	const { t } = useTranslation();
	const { currentProgramMembership } = usePanel();

	const { data, isLoading, isSuccess } = useQuery(
		['FINAL_SURVEY', { programMembershipId: Number(currentProgramMembership?.id) }],
		() => surveyApi.getSurveyForProgramMembership(Number(currentProgramMembership?.id)),
		{
			enabled: dialogProps.visible && Boolean(currentProgramMembership),
		}
	);

	const queryClient = useQueryClient();
	const { mutate, isLoading: updating } = useMutation(
		({ step, answers }: { step: number; answers: Array<any> }) =>
			surveyApi.updateSurveyForProgramMembership(Number(currentProgramMembership?.id), { step, answers }),
		{
			onSuccess: () => {
				queryClient.refetchQueries([
					'FINAL_SURVEY',
					{ programMembershipId: Number(currentProgramMembership?.id) },
				]);
			},
		}
	);

	const dialogContentRef = useRef<HTMLDivElement>(null);
	const handleSave = (step: number, answers: Array<any>) => {
		dialogContentRef?.current?.scrollTo({ top: 0, behavior: 'smooth' });
		mutate({ step, answers });
	};

	const handleEnd = () => {
		dialogProps.onHide?.();
	};

	const [afterIntro, setAfterIntro] = useState(false);
	const handleEndIntro = useCallback(() => setAfterIntro(true), []);

	return (
		<Dialog contentRef={dialogContentRef} title={t('userPanel.finalSurvey.title')} size="lg" {...dialogProps}>
			{withIntro && !afterIntro && (
				<div className="flex flex-column gap-2">
					<div className="grid">
						<div className="col-12 md:col-9">
							<p>{t('userPanel.finalSurvey.introText.first')}</p>
							<p>{t('userPanel.finalSurvey.introText.second')}</p>
							<p>{t('userPanel.finalSurvey.introText.third')}</p>
							<p>{t('userPanel.finalSurvey.introText.fourth')}</p>
						</div>
						<div className="hidden md:block md:col-3">
							<img src={iconChecklist} className="w-full" alt="" />
						</div>
					</div>
					<div className="grid">
						<div className="col-12 md:col-7" />
						<div className="col-12 md:col-5">
							<Button block label={t('userPanel.finalSurvey.startSurvey')} onClick={handleEndIntro} />
						</div>
					</div>
				</div>
			)}
			{(!withIntro || afterIntro) && (
				<>
					{isLoading && <Spinner center />}
					{isSuccess && (
						<FinalSurveyPresenter
							panelMode
							survey={data}
							updating={updating}
							onSave={handleSave}
							onEnd={handleEnd}
						/>
					)}
				</>
			)}
		</Dialog>
	);
};
