/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
import React, { useState, useMemo, useCallback, useRef, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { dayjs } from 'utils/dayjs';

import { roundFixed } from 'utils/number';
import { classNames } from 'primereact/utils';
import { universalRenderer } from 'utils/universalRenderer';
import { Button } from '../Button';

import './Calendar.scss';

export type CalendarProps = {
	defaultCurrentDate?: Date;
	headerRight?: ReactNode | (() => ReactNode);
	events?: CalendarEventBase[];
	mapEvents?: (events: CalendarEventBase[]) => CalendarEventBase[];
	onTimeClick?: (meta: { time: Date }) => void;
};

export type CalendarEventBase = {
	key: string | number;
	id: string | number;
	startTime: Date;
	endTime: Date;
	dateOnly: boolean;
	summary: string;
	color?: {
		bg: string;
		text: string;
	};
	source?: string | number;
	ownerName?: string;
	onClick?: (event: Omit<CalendarEventBase, 'onClick'>) => void;
	meta?: any;
};

type WeekDay = {
	day: number;
	date: dayjs.Dayjs;
	isPast: boolean;
};

type CalendarEventTile = CalendarEventBase & {
	key: string;
	isFirst: boolean;
	isLast: boolean;
	top: number;
	height: number;
	hasPrevEvent?: boolean;
	skip: boolean;
};

type WeekDayWithEvents = WeekDay & {
	dayEvents: CalendarEventTile[];
};

type HourRow = { hour: number; hourString: string; mappedDays: WeekDayWithEvents[]; skip: boolean };

const unique = (arr: any[]) => arr.filter((value, index, array) => array.indexOf(value) === index);

export const Calendar = (props: CalendarProps) => {
	const { defaultCurrentDate, headerRight, events, mapEvents, onTimeClick } = props;

	const { t } = useTranslation();

	const finalEvents = useMemo(() => {
		const mappedEvents = mapEvents ? mapEvents(events || []) : events || [];
		return mappedEvents.map((event) => {
			// eslint-disable-next-line prefer-destructuring
			const startTime = event.dateOnly
				? dayjs(event.startTime).set('hour', 0).toDate()
				: new Date(event.startTime);
			// eslint-disable-next-line prefer-destructuring
			const endTime = event.dateOnly
				? dayjs(event.endTime).set('hour', 23).set('minute', 59).set('second', 59).toDate()
				: new Date(event.endTime);
			return { ...event, startTime, endTime };
		});
	}, [events, mapEvents]);

	// date states
	const [currentDate, setCurrentDate] = useState(
		dayjs(defaultCurrentDate || new Date())
			.weekday(0)
			.toDate()
	);
	const currentWeek = useMemo(() => {
		const firstYearDate = dayjs(currentDate).set('month', 0).set('date', 0);
		const days = dayjs(currentDate).diff(firstYearDate, 'days');
		return Math.floor(days / 7) + 1;
	}, [currentDate]);
	const yearWeeksCount = dayjs(currentDate).isoWeeksInYear();

	// control date
	const prevMonth = useCallback(
		() =>
			setCurrentDate((prev) => {
				const prevMonthDate = dayjs(prev).startOf('month').add(-1, 'day').startOf('month');
				return prevMonthDate.toDate();
			}),
		[]
	);
	const nextMonth = useCallback(
		() =>
			setCurrentDate((prev) => {
				const nextMonthDate = dayjs(prev).endOf('month').add(1, 'day');
				return nextMonthDate.toDate();
			}),
		[]
	);
	const prevWeek = useCallback(() => setCurrentDate((prev) => dayjs(prev).add(-1, 'week').weekday(0).toDate()), []);
	const nextWeek = useCallback(() => setCurrentDate((prev) => dayjs(prev).add(1, 'week').weekday(0).toDate()), []);

	const currentDateWeekView = useMemo(() => dayjs(currentDate).weekday(0).toDate(), [currentDate]);

	const renderHeader = () => {
		const namedMonth = dayjs(currentDate).format('MMMM YYYY');
		return (
			<div className="header">
				<div className="header-month">
					<div className="left">
						<Button
							label={t('userPanel.myCalendar.prevMonth')}
							icon="angle-left"
							iconOnly
							variant="primary-text"
							onClick={prevMonth}
						/>
					</div>
					<div className="center">{namedMonth}</div>
					<div className="right">
						<Button
							label={t('userPanel.myCalendar.nextMonth')}
							icon="angle-right"
							iconOnly
							variant="primary-text"
							onClick={nextMonth}
						/>
					</div>
				</div>
				<div className="header-month">
					<div className="left">
						<Button
							label={t('userPanel.myCalendar.prevWeek')}
							icon="angle-left"
							iconOnly
							variant="primary-text"
							onClick={prevWeek}
						/>
					</div>
					<div className="center">
						{t('userPanel.myCalendar.weekNo', { count: currentWeek })}/{yearWeeksCount}
					</div>
					<div className="right">
						<Button
							label={t('userPanel.myCalendar.nextWeek')}
							icon="angle-right"
							iconOnly
							variant="primary-text"
							onClick={nextWeek}
						/>
					</div>
				</div>
				{headerRight ? universalRenderer(headerRight) : null}
			</div>
		);
	};

	const week = useMemo<WeekDay[]>(
		() =>
			Array(7)
				.fill(null)
				.map((_, i) => {
					const date = dayjs(currentDateWeekView).add(i, 'day');
					const day = date.get('day');
					const isPast = date.add(1, 'day').isAfter(new Date(), 'day');
					return {
						day,
						date,
						isPast,
					};
				}),
		[currentDateWeekView]
	);

	const dateInWeek = (date: Date) =>
		(dayjs(date).isSame(dayjs(currentDateWeekView).startOf('week')) ||
			dayjs(date).isAfter(dayjs(currentDateWeekView).startOf('week'))) &&
		(dayjs(date).isBefore(dayjs(currentDateWeekView).endOf('week')) ||
			dayjs(date).isSame(dayjs(currentDateWeekView).endOf('week')));

	const weekEvents = (finalEvents || []).filter(({ startTime, endTime }) => {
		const startsInWeek = dateInWeek(startTime);
		const endsInWeek = dateInWeek(endTime);
		const outWeek =
			dayjs(startTime).isBefore(dayjs(currentDateWeekView).startOf('week')) &&
			dayjs(endTime).isAfter(dayjs(currentDateWeekView).endOf('week'));
		return startsInWeek || endsInWeek || outWeek;
	});

	const hoursNumbersArr = Array(24)
		.fill(null)
		.map((_, i) => i);

	const hoursRows = hoursNumbersArr
		.map<HourRow>((hour) => {
			const hourString = `${hour}:00`;

			const mappedDays = week.map<WeekDayWithEvents>((day) => {
				const { date } = day;
				const dayStart = dayjs(date).set('hour', hour).set('minute', 0).set('second', 0);
				const dayEnd = dayjs(date).set('hour', hour).set('minute', 59).set('second', 59);

				const inDay = (date: Date) =>
					(dayjs(date).isSame(dayStart) || dayjs(date).isAfter(dayStart)) &&
					(dayjs(date).isBefore(dayEnd) || dayjs(date).isSame(dayEnd));
				const outDay = (startTime: Date, endTime: Date) =>
					dayjs(startTime).isBefore(dayStart) && dayjs(endTime).isAfter(dayEnd);

				const dayEvents = weekEvents
					.filter(({ startTime, endTime }) => {
						return inDay(startTime) || inDay(endTime) || outDay(startTime, endTime);
					})
					// skip zero-minute end time events for hour tile
					.filter(({ endTime }) => {
						const isTheSameHour = Number(dayjs(endTime).get('hour')) === hour;
						const zeroMinutes = Number(dayjs(endTime).get('minutes')) === 0;
						const skip = isTheSameHour && zeroMinutes;
						return !skip;
					})
					.map<CalendarEventTile>((event) => {
						const startTimeHour = Number(dayjs(event.startTime).get('hour'));
						const startTimeInCurrentHour = startTimeHour === hour;
						const startMinute = Number(dayjs(event.startTime).get('minutes'));
						const startPoint = roundFixed(startMinute / 60);

						const endTimeInCurrentHour =
							Number(dayjs(event.endTime).add(-1, 'minutes').get('hour')) === hour;
						const eventDurationMinutes = Number(dayjs(event.endTime).diff(event.startTime, 'minutes'));
						const eventDurationLastPoint = (eventDurationMinutes % 60) / 60;
						const endMinute = Number(dayjs(event.endTime).get('minutes'));
						const endPoint = roundFixed(endMinute / 60);

						let top = 0;
						let height = 50;
						if (startTimeInCurrentHour && startMinute > 0 && startPoint !== 0) {
							top = 50 * startPoint;
							height -= top;
						}

						if (endTimeInCurrentHour && eventDurationLastPoint > 0 && endPoint !== 0) {
							const heightForEnd = 50 - 50 * endPoint;
							height -= heightForEnd;
						}
						if (endTimeInCurrentHour) {
							height -= 1;
						}

						return {
							...event,
							key: `${event.key}-${hour}`,
							isFirst: startTimeInCurrentHour,
							isLast: endTimeInCurrentHour,
							top,
							height,
							skip: false,
						};
					});

				return { ...day, dayEvents };
			});

			return { hourString, hour, mappedDays, skip: false };
		})
		.reduce<HourRow[]>((current, item) => {
			const row: HourRow = {
				...item,
				mappedDays: item.mappedDays.map((day) => {
					const sameDayEvents = current
						.map(({ mappedDays }) => mappedDays)
						.map((mappedDays) => mappedDays.filter((md) => md.day === day.day))
						.flat()
						.map(({ dayEvents }) => dayEvents)
						.flat();
					return {
						...day,
						dayEvents: day.dayEvents.map((event) => {
							const hasPrevEvent = unique(sameDayEvents.map(({ id }) => id)).includes(event.id);
							return { ...event, hasPrevEvent };
						}),
					};
				}),
			};
			return [...current, row];
		}, [])
		// clear first minute tile
		.reduce<HourRow[]>((current, item) => {
			const row = {
				...item,
				mappedDays: item.mappedDays.map((md) => ({
					...md,
					dayEvents: md.dayEvents.filter(({ skip }) => !skip),
				})),
			};
			return [...current, row];
		}, [])

		// cut/skip first empty (non-events) rows
		.reduce<HourRow[]>((current, item) => {
			const row = { ...item };
			const skipThisRow = row.mappedDays.map(({ dayEvents }) => dayEvents).flat().length === 0;
			if (
				item.hour < 8 &&
				skipThisRow &&
				current.every(({ mappedDays }) => mappedDays.map(({ dayEvents }) => dayEvents).flat().length === 0)
			) {
				row.skip = true;
			}
			return [...current, row];
		}, [])
		// cut/skip lasts empty (non-events) rows
		.reverse()
		.reduce<HourRow[]>((current, item) => {
			const row = { ...item };
			const skipThisRow = row.mappedDays.map(({ dayEvents }) => dayEvents).flat().length === 0;
			if (
				item.hour > 16 &&
				skipThisRow &&
				current.every(({ mappedDays }) => mappedDays.map(({ dayEvents }) => dayEvents).flat().length === 0)
			) {
				row.skip = true;
			}
			return [...current, row];
		}, [])
		.reverse()
		// fix for events-collision
		.reduce<HourRow[]>((current, item) => {
			const row = { ...item };
			return [...current, row];
		}, []);

	const eventsRef = useRef<Record<string, any>>({});

	const renderWeekView = () => {
		return (
			<div className="grid">
				{hoursRows.map(({ hour, hourString, mappedDays, skip }) => {
					if (skip) {
						return null;
					}
					return (
						<>
							<div className="hour-cell">{hourString}</div>
							{mappedDays.map(({ date, isPast, dayEvents }) => {
								return (
									<div className={classNames('day-cell', { past: !isPast })}>
										<div
											className={classNames('hour-helper', {
												interactive: Boolean(onTimeClick),
											})}
										>
											<div
												onClick={(event) => {
													if (onTimeClick) {
														onTimeClick({
															time: date
																.set('hour', hour)
																.set('minutes', 0)
																.set('second', 0)
																.toDate(),
														});
													}
													event.preventDefault();
												}}
											/>
											<div
												onClick={(event) => {
													if (onTimeClick) {
														onTimeClick({
															time: date
																.set('hour', hour)
																.set('minutes', 15)
																.set('second', 0)
																.toDate(),
														});
													}
													event.preventDefault();
												}}
											/>
											<div
												onClick={(event) => {
													if (onTimeClick) {
														onTimeClick({
															time: date
																.set('hour', hour)
																.set('minutes', 30)
																.set('second', 0)
																.toDate(),
														});
													}
													event.preventDefault();
												}}
											/>
											<div
												onClick={(event) => {
													if (onTimeClick) {
														onTimeClick({
															time: date
																.set('hour', hour)
																.set('minutes', 45)
																.set('second', 0)
																.toDate(),
														});
													}
													event.preventDefault();
												}}
											/>
										</div>
										{dayEvents.map((event) => {
											const { onClick, ...restEvent } = event;
											const {
												key,
												top,
												height,
												color,
												summary,
												isFirst,
												isLast,
												startTime,
												endTime,
												hasPrevEvent,
												ownerName,
											} = restEvent;
											let finalSummary = summary;
											if (summary === 'Busy') {
												finalSummary = t('userPanel.myCalendar.busy');
											}
											if (summary === 'Tentative') {
												finalSummary = t('userPanel.myCalendar.tentative');
											}
											return (
												<div id={key} key={key} className="event-item">
													<div
														// eslint-disable-next-line no-return-assign
														ref={(el) => (eventsRef.current[key] = el)}
														className={classNames('event-tile relative', {
															'event-tile-first': isFirst,
															'event-tile-last': isLast,
															'event-interactive': Boolean(onClick),
														})}
														style={{
															top: `${top}px`,
															minHeight: `${height}px`,
															backgroundColor: color?.bg || 'white',
															color: color?.text || 'black',
														}}
														onClick={(event) => {
															if (onClick) {
																onClick(restEvent);
															}
															event.preventDefault();
														}}
													>
														{!hasPrevEvent && finalSummary}
														{isFirst && (
															<div className="event-tile-additional">
																<div>
																	{dayjs(startTime).format('HH:mm')} -{' '}
																	{dayjs(endTime).format('HH:mm')}
																</div>
																{ownerName && <div>{ownerName}</div>}
															</div>
														)}
													</div>
												</div>
											);
										})}
									</div>
								);
							})}
						</>
					);
				})}
			</div>
		);
	};

	return (
		<div className="flex flex-column overflow-hidden h-full calendar">
			<div className="flex-0 px-4 py-2">{renderHeader()}</div>
			<div className="relative flex-1 overflow-scroll px-4 p-styled-scrollbar p-styled-scrollbar-purplishblue">
				<div className="week-view">
					<div className="grid sticky-header">
						<div className="week-header" />
						{week.map(({ date, isPast }) => (
							<div className={classNames('week-header', { past: !isPast })}>
								<span className="week-header-day">{dayjs(date).format('dd, D')}</span>
							</div>
						))}
					</div>
					{renderWeekView()}
				</div>
			</div>
		</div>
	);
};
