import React, { Ref, forwardRef, InputHTMLAttributes, useRef, useState, useEffect, useCallback } from 'react';
import { classNames } from 'primereact/utils';
import mergeRefs from 'merge-refs';
import { getInputBaseClassName } from '../InputBase';
import { Button } from '../Button';

import './InputNumber.scss';

export type InputNumberProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'value' | 'onChange'> & {
	variant?: 'white';
	inline?: boolean;
	max?: number;
	min?: number;
	step?: number;
	value?: number;
	defaultValue?: number;
	onChange?: (value: number) => void;
};

export const InputNumber = forwardRef(
	(
		{
			variant,
			className,
			inline,
			max,
			min,
			step = 1,
			onChange,
			value,
			defaultValue,
			...restProps
		}: InputNumberProps,
		ref: Ref<HTMLInputElement>
	) => {
		const innerRef = useRef<HTMLInputElement>(null);

		const isControlled = typeof value !== 'undefined';
		const [innerInputValue, setInnerInputValue] = useState<number | undefined>(value || defaultValue);

		// Synchronizacja lokalnego stanu, jeśli komponent jest kontrolowany
		useEffect(() => {
			if (isControlled) {
				setInnerInputValue(value);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [isControlled, value]);

		const handleIncrease = useCallback(() => {
			const valueSource = Number(innerRef.current?.value);
			const nextValue = max !== undefined ? Math.min(max, valueSource + step) : valueSource + step;
			if (!isControlled) {
				// eslint-disable-next-line no-lonely-if
				if (innerRef.current) {
					innerRef.current.value = String(nextValue);
				}
			}
			onChange?.(nextValue);
		}, [isControlled, max, onChange, step]);

		const handleDecrease = useCallback(() => {
			const valueSource = Number(innerRef.current?.value);
			const nextValue = min !== undefined ? Math.max(min, valueSource - step) : valueSource - step;
			if (!isControlled) {
				// eslint-disable-next-line no-lonely-if
				if (innerRef.current) {
					innerRef.current.value = String(nextValue);
				}
			}
			onChange?.(nextValue);
		}, [isControlled, min, onChange, step]);

		const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
			const nextValue = Number(event?.target.value);
			if (
				!Number.isNaN(nextValue) &&
				(min === undefined || nextValue >= min) &&
				(max === undefined || nextValue <= max)
			) {
				if (!isControlled) {
					if (innerRef.current) {
						innerRef.current.value = String(nextValue);
						event.preventDefault();
					}
				}
				onChange?.(nextValue);
			}
		};

		return (
			<div className={classNames(getInputBaseClassName({ inline }), 'input-number no-appearance flex flex-row')}>
				<input
					ref={mergeRefs(innerRef, ref)}
					type="number"
					value={isControlled ? innerInputValue : undefined}
					min={min}
					max={max}
					step={step}
					defaultValue={isControlled ? undefined : defaultValue}
					onChange={handleChange}
					{...restProps}
				/>
				<div className="flex flex-column">
					<Button label="up" iconOnly icon="arrow-up" variant="primary" noPad onClick={handleIncrease} />
					<Button label="down" iconOnly icon="arrow-down" variant="primary" noPad onClick={handleDecrease} />
				</div>
			</div>
		);
	}
);
