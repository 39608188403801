import React, { FC, ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { RouterUrlParams } from 'App';
import { Answer, Application } from 'types/Application';
import { colors } from 'theme';
import { GroupedChoiceList } from 'types/Question';
import { Box, BoxSection } from 'components/_new/Box';
import { Avatar } from 'components/_new/Avatar';
import { classNames } from 'primereact/utils';
import { Button } from 'components/_new/Button';
import { useChat } from 'components/chat/useChat';
import { userDisplayName } from 'utils/userDisplayName';
import { contentLinkParser } from 'utils/contentLinkParser';
import { parseHtml } from 'utils/htmlParser';
import { urlValidator } from 'utils/urlValidator';
import AdminNotesModal from 'components/chat/AdminNotesModal';
import { Tooltip } from 'primereact/tooltip';

enum FilteredQuestionKeys {
	name = 'name',
	surname = 'surname',
	department = 'department',
	position = 'position',
	linkedin = 'linkedin',
	areas = 'areas',
	consent = 'consent',
}

type AdditionalAnswer = { label: string; value: ReactNode | null };
interface BusinessCardProps {
	data: Application;
	additionalAnswers?: Array<AdditionalAnswer | false>;
	highlightDeleted?: boolean;
	displayHidden?: boolean;
	isYour?: boolean;
	renderBottom?: () => React.ReactNode;
	disableMessage?: boolean;
	configPanel?: boolean;
}

const BusinessCard: FC<BusinessCardProps> = ({
	data,
	additionalAnswers,
	highlightDeleted = false,
	displayHidden = false,
	isYour,
	renderBottom,
	disableMessage = false,
	configPanel = false,
}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { organizationName, programName, type } = useParams() as RouterUrlParams;
	const { openDialog } = useChat();

	const [adminNotesVisible, setAdminNotesVisible] = useState(false);
	const [notesCount, setNotesCount] = useState<number>(0);

	const {
		systemAnswers,
		answers,
		programMembership: { user },
	} = data;
	const filteredAnswers = [
		...answers
			.filter((answer) => !Object.keys(FilteredQuestionKeys).includes(answer.question.systemQuestionType))
			.filter((answer) => (displayHidden ? true : !answer.question.hiddenFromUsers))
			.filter((answer) => answer.value !== null && answer.value !== 'false'),
	];

	const renderContent = (content: any, separator = ', ') => {
		if (Array.isArray(content)) {
			return content.join(separator);
		}
		return parseHtml(content);
	};
	const userName = userDisplayName(user, null);
	const renderTitle = () => (
		<div className="flex flex-column md:flex-row gap-2 flex-wrap">
			<div className="flex-1 flex flex-row gap-4">
				<Avatar src={user.avatar} name={userName} size="xl" />
				<div>
					<div
						className={classNames('w-10rem text-2xl font-semibold md:font-bold md:text-4xl md:w-20rem', {
							'text-error': highlightDeleted && data.deletedAt !== null,
						})}
					>
						{[user.firstName, user.lastName].filter((item) => item?.length > 0).join(' ')}
					</div>
					{systemAnswers.position && <div>{renderContent(systemAnswers.position)}</div>}
					{systemAnswers.department && <div>{renderContent(systemAnswers.department)}</div>}
				</div>
			</div>
			<Tooltip
				target="#chatButton"
				content={t('chat.onlyForApprovedApplications')}
				disabled={data.approved === 'accepted'}
				position="bottom"
				className="max-w-[200px] break-words whitespace-normal"
			/>
			<div className="flex-1 flex flex-column justify-content-start gap-2 py-2">
				{!isYour && !disableMessage && (
					<div id="chatButton">
						<Button
							className="inline-flex justify-content-start"
							noPad
							variant="primary-text"
							label={t('chat.writeMessage')}
							icon="comments"
							iconClassName="w-2rem"
							variantSize="sm"
							onClick={() => openDialog(data.programMembershipId)}
							disabled={data.approved !== 'accepted'}
						/>
					</div>
				)}
				{systemAnswers.linkedin && String(systemAnswers.linkedin) !== 'undefined' && (
					<div className="flex align-items-center font-bold gap-2">
						<i className="pi pi-linkedin w-2rem text-center" />
						{contentLinkParser(systemAnswers.linkedin, {
							linkClassName: 'font-normal md:font-bold',
							allowDomains: ['linkedin.com', 'facebook.com'],
						})}
					</div>
				)}
				<div className="flex flex-row align-items-center gap-2">
					<i className="pi pi-at w-2rem text-center" style={{ color: colors.purplishBlue }} />
					<div className="font-normal md:font-bold"> {user.email}</div>
				</div>
				{!isYour && configPanel && (
					<Button
						className="inline-flex justify-content-start"
						noPad
						variant="rose-text"
						label={`${t('chat.adminNotes')}${notesCount > 0 ? ` (${notesCount})` : ''}`}
						icon="note-sticky"
						iconClassName="w-2rem"
						variantSize="sm"
						onClick={() => setAdminNotesVisible(true)}
					/>
				)}
			</div>
		</div>
	);
	const renderAnswer = (
		answer: Answer,
		systemAnswers: {
			areas?: GroupedChoiceList[];
			department?: string;
			email?: string;
			linkedin?: string;
			name?: string;
			position?: string;
			surname?: string;
			expectations?: string;
			pairLimit?: string;
			avatar?: string;
			attachments: any;
		},
		colors: {
			whitePurple?: string;
			blue?: string;
			purple?: string;
			purplishBlue: any;
			darkPurplishBlue?: string;
			pink?: string;
			gray: any;
			lightGray?: string;
			blackShadow?: string;
			successGreen?: string;
			warningYellow?: string;
			warningPink?: string;
			white?: string;
			black?: string;
			hotPink?: string;
		},
		renderContent: { (content: any, separator?: string): ReactNode; (arg0: any): any }
	) => {
		if (answer.question.questionType === 'attachment') {
			return (
				<>
					<span style={{ color: colors.gray }}>{answer.question.text}</span>
					<a
						style={{ color: colors.purplishBlue }}
						target="_blank"
						href={
							systemAnswers?.attachments?.find(
								(attachment: { questionId: number }) => attachment.questionId === answer.questionId
							)?.url
						}
						rel="noreferrer"
					>
						{renderContent(answer.value) || '-'}
					</a>
				</>
			);
		}
		if (answer.question.questionType === 'checkbox' && answer.value) {
			return (
				<>
					<span style={{ color: colors.gray }}>{answer.question.text}</span>
					{renderContent(String(answer.question.description || '')?.replace(/<\/?0>/g, '')) || '-'}
				</>
			);
		}

		if (answer.question.questionType === 'info text' || answer.question.questionType === 'info header') {
			return null;
		}

		if (answer.question.questionType === 'link') {
			const url = String(answer.value);
			if (url.length > 0) {
				if (urlValidator(url)) {
					return (
						<>
							<span style={{ color: colors.gray }}>{answer.question.text}</span>
							<a href={url} target="_blank" className="text-purple underline" rel="noreferrer">
								{url}
							</a>
						</>
					);
				}

				const newUrl = `https://${answer.value}`;
				if (urlValidator(newUrl)) {
					return (
						<>
							<span style={{ color: colors.gray }}>{answer.question.text}</span>
							<a href={newUrl} target="_blank" className="text-purple underline" rel="noreferrer">
								{newUrl}
							</a>
						</>
					);
				}

				return (
					<>
						<span style={{ color: colors.gray }}>{answer.question.text}</span>
						<span className="text-error">{t('misc.error')}</span>
					</>
				);
			}
			return null;
		}

		return (
			<>
				<span style={{ color: colors.gray }}>
					{answer.question.text}
					{answer.question.hiddenFromUsers && t('userPanel.personalDetails.isConfidential')}
				</span>
				{renderContent(answer.value) || '-'}
			</>
		);
	};

	const areas = (systemAnswers || []).areas || [];

	const finalAdditionalAnswers = useMemo(
		() => (additionalAnswers || []).filter(Boolean) as AdditionalAnswer[],
		[additionalAnswers]
	);

	// @ts-ignore
	return (
		<Box variant="white-bordered">
			{configPanel && (
				<AdminNotesModal
					applicationId={data.id}
					onHide={() => setAdminNotesVisible(false)}
					visible={adminNotesVisible}
					interlocutor={user}
					programId={data.programId}
					onNotesCountChange={setNotesCount}
				/>
			)}
			<BoxSection header={renderTitle()}>
				<div className="flex flex-column row-gap-4">
					{areas.length > 0 && (
						<div>
							<div style={{ color: colors.gray }}>{t('userPanel.personalDetails.competences')}</div>
							<div className="flex mt-2 mb-2 mr-2 align-items-center flex-wrap flex-column sm:flex-row">
								{areas
									.reduce(
										(curr: string[], prev) =>
											prev && Array.isArray(prev.options) ? [...curr, ...prev.options] : curr,
										[]
									)
									.map((area) => (
										<div
											key={area}
											className="w-full md:w-3 flex align-items-start mt-2 mb-2 pr-2  "
											// style={{ width: '24%' }}
										>
											<i
												className="pi pi-hashtag align-self-center text-xl mr-2"
												style={{ color: colors.purplishBlue }}
											/>
											{renderContent(area)}
										</div>
									))}
							</div>
						</div>
					)}
					{filteredAnswers
						.filter((a) => String(a.value).length > 0)
						.sort((a, b) => a.question.priority - b.question.priority)
						.map((answer) => (
							<div key={answer.questionId} className="flex flex-column row-gap-1">
								{renderAnswer(answer, systemAnswers, colors, renderContent)}
							</div>
						))}
					{finalAdditionalAnswers.map(({ label, value }, i) => (
						// eslint-disable-next-line react/no-array-index-key
						<div key={i} className="flex flex-column row-gap-1">
							<span style={{ color: colors.gray }}>{label}</span>
							<span>{value}</span>
						</div>
					))}
				</div>
				{isYour && (
					<Button
						label={t('userPanel.personalDetails.editData')}
						onClick={() => navigate(`/panel/${organizationName}/${programName}/${type}/application`)}
						className="flex ml-auto mt-5"
					/>
				)}
				{renderBottom && (
					<div>
						<hr />
						{renderBottom()}
					</div>
				)}
			</BoxSection>
		</Box>
	);
};

export default BusinessCard;
