import React, { HTMLAttributes, ReactElement, ReactNode, Ref, forwardRef, useCallback, useState } from 'react';
import { classNames } from 'primereact/utils';
import { BoxSectionProps } from './BoxSection';
import './Box.scss';

type BoxVariant = 'white' | 'white-bordered' | 'blue' | 'clean';
export type BoxProps = Pick<HTMLAttributes<HTMLDivElement>, 'id' | 'className' | 'style' | 'onClick'> & {
	title?: string;
	slim?: boolean;
	variant?: BoxVariant;
	vertical?: boolean;
	full?: boolean;
	wrap?: boolean;
	// visible?: boolean;
	overflowHidden?: boolean;
	dropShadow?: boolean;
	hoverable?: boolean;
	noPad?: boolean;
	children: ReactNode | ReactElement<BoxSectionProps> | ReactElement<BoxSectionProps>[];
};

export const Box = forwardRef(
	(
		{
			title,
			slim,
			variant = 'white',
			vertical = false,
			full = false,
			wrap = false,
			children,
			className,
			// visible = false,
			overflowHidden = false,
			dropShadow = false,
			hoverable = false,
			noPad = false,
			...restProps
		}: BoxProps,
		ref: Ref<HTMLDivElement>
	) => {
		const [hoverActive, setHoverActive] = useState(false);
		const handleMouseHover = useCallback(() => setHoverActive(true), []);
		const handleMouseLeave = useCallback(() => setHoverActive(false), []);
		return (
			<div
				ref={ref}
				className={classNames(
					'box',
					`box-variant-${variant}`,
					{
						'box-column': !vertical,
						'box-row': vertical,
						'box-slim': slim,
						'box-full': full,
						'box-wrap': wrap,
						// 'box-visible': visible,
						'box-hidden': overflowHidden,
						'box-shadow': dropShadow,
						'box-hover': restProps?.onClick || hoverable,
						'box-hover-active': hoverActive,
						'box-nopad': noPad,
					},
					className
				)}
				onMouseEnter={handleMouseHover}
				onMouseLeave={handleMouseLeave}
				{...restProps}
			>
				{children}
			</div>
		);
	}
);
