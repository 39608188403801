import React, { Ref, forwardRef, TextareaHTMLAttributes } from 'react';
import { classNames } from 'primereact/utils';
import { getInputBaseClassName } from '../InputBase';
import './InputTextArea.scss';

export type InputTextAreaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
	variant?: 'white';
};

export const InputTextArea = forwardRef(
	({ variant, className, ...restProps }: InputTextAreaProps, ref: Ref<HTMLTextAreaElement>) => {
		return (
			<div className={classNames(getInputBaseClassName(), 'input-text-area', className)}>
				<textarea ref={ref} {...restProps} />
			</div>
		);
	}
);
