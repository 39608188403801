import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { Box, BoxSection } from 'components/_new/Box';
import { Button } from 'components/_new/Button';
import { Tooltip } from 'primereact/tooltip';
import { Setting, ModuleTypes } from '../../../types/Contract';
import { parseHtml } from '../../../utils/htmlParser';
import { InputText } from '../../_new/InputText';
import { MarkdownEditor } from '../../_common/MarkdownEditor/MarkdownEditor';

interface ContractCardProps extends Setting {
	onSwap: (currentIndex: number, swapWithIndex: number) => void;
	index: number;
	canMoveUp: boolean;
	canMoveDown: boolean;
	onDelete: () => void;
	contractModified: boolean;
	onEdit: (editedSetting: Partial<Setting>) => void;
}

const ContractCard: React.FC<ContractCardProps> = ({
	header,
	text,
	moduleType,
	onSwap,
	index,
	canMoveUp,
	canMoveDown,
	onDelete,
	onEdit,
	id,
	programId,
	priority,
	contractModified,
}) => {
	const [isEdited, setIsEdited] = useState(false);
	const [editedSetting, setEditedSetting] = useState<Partial<Setting>>({});
	const { t } = useTranslation();

	const { control, handleSubmit, reset } = useForm<Partial<Setting>>({
		defaultValues: {
			header,
			text,
		},
	});

	const renderCopy = () => {
		switch (moduleType) {
			case ModuleTypes.text:
				return <div> {parseHtml(text || '', { nl2br: false })}</div>;
			case ModuleTypes.editText:
				return (
					<div>
						{parseHtml(text || '', { nl2br: false })}
						<div className="font-italic">{t('programPanel.editContract.editText')}</div>
					</div>
				);
			case ModuleTypes.meetings:
				return <div className="font-italic">{t('programPanel.editContract.meetings')}</div>;
			case ModuleTypes.meetingSettings:
				return <div className="font-italic">{t('programPanel.editContract.meetingSetting')}</div>;
			case ModuleTypes.goals:
				return <div className="font-italic">{t('programPanel.editContract.goals')}</div>;
			default:
				return <div>Nieznany typ modułu</div>;
		}
	};

	const renderEdit = () => {
		switch (moduleType) {
			case ModuleTypes.editText:
			case ModuleTypes.text:
				return (
					<div className="flex-column flex">
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="mb-3 mt-2">
								<Controller
									name="header"
									control={control}
									render={({ field }) => <InputText className="mb-2" {...field} />}
								/>
							</div>
							<div style={{ cursor: 'text' }}>
								<Controller
									name="text"
									control={control}
									render={({ field }) => <MarkdownEditor defaultValue={text} {...field} />}
								/>
							</div>
							<div className="flex justify-content-end gap-2 mt-2">
								<Button
									label={t('actions.cancel')}
									onClick={() => setIsEdited(false)}
									variant="primary-outlined"
								/>
								<Button submit label={t('actions.save')} />
							</div>
						</form>
					</div>
				);
			case ModuleTypes.meetings:
			case ModuleTypes.meetingSettings:
			case ModuleTypes.goals:
				return (
					<div className="gap-2 flex-column flex mt-4 mb-2">
						<form onSubmit={handleSubmit(onSubmit)}>
							<Controller
								name="header"
								control={control}
								render={({ field }) => <InputText {...field} />}
							/>
							<div className="flex justify-content-end gap-2 mt-2">
								<Button
									label={t('actions.cancel')}
									onClick={() => setIsEdited(false)}
									variant="primary-outlined"
								/>
								<Button submit label={t('actions.save')} />
							</div>
						</form>
					</div>
				);
			default:
				return <div>Unsupported module type for editing</div>;
		}
	};
	const onSubmit = (data: Partial<Setting>) => {
		onEdit({ ...data, id, moduleType, priority, programId });
		setIsEdited(false);
	};

	const toggleEditMode = () => {
		setIsEdited(!isEdited);
		reset({
			header,
			text,
		});
	};

	const canDelete = !(contractModified || (moduleType !== ModuleTypes.editText && moduleType !== ModuleTypes.text));
	const tooltipText =
		moduleType !== ModuleTypes.editText && moduleType !== ModuleTypes.text
			? t('programPanel.editContract.baseModuleTooltip')
			: t('programPanel.editContract.onePairConfiguredTooltip');

	return (
		<Box variant="white-bordered" className="my-2" dropShadow>
			<BoxSection>
				{!isEdited ? (
					<>
						<Tooltip target={`#remove-item-btn-${index}`} content={tooltipText} disabled={canDelete} />
						<div
							className="flex flex-row w-full justify-content-between align-items-center"
							style={{ borderBottom: '1px solid lightGray' }}
						>
							<h3>{header}</h3>
							<div className="flex flex-row gap-1">
								<Button
									label={t('actions.edit')}
									icon="pencil"
									iconSet="pi"
									variant="primary-text"
									variantSize="sm"
									onClick={toggleEditMode}
								/>
								<Button
									label="up"
									iconOnly
									icon="up-long"
									iconStyle="solid"
									variant="primary-text"
									variantSize="sm"
									disabled={!canMoveUp}
									onClick={() => canMoveUp && onSwap(index, index - 1)}
								/>
								<Button
									label="down"
									iconOnly
									icon="down-long"
									iconStyle="solid"
									variant="primary-text"
									variantSize="sm"
									disabled={!canMoveDown}
									onClick={() => canMoveDown && onSwap(index, index + 1)}
								/>
								<span id={`remove-item-btn-${index}`}>
									<Button
										label={t('actions.remove')}
										icon="trash"
										iconSet="pi"
										variant="danger-text"
										variantSize="sm"
										onClick={onDelete}
										disabled={!canDelete}
									/>
								</span>
							</div>
						</div>
						<span>{renderCopy()}</span>
					</>
				) : (
					renderEdit()
				)}
			</BoxSection>
		</Box>
	);
};

export default ContractCard;
