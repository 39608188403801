import React from 'react';
import { useTranslation } from 'react-i18next';
import { useProgramPanel } from 'contexts/programPanelContext';
import { Panel, PanelItem } from '../../partials/panel';

export const ProgramStatus = () => {
	const { t } = useTranslation();
	const { data: programData, panelPath } = useProgramPanel();

	const programUrl = `${process.env.REACT_APP_FRONT_URL}/${programData?.name}`;

	return (
		<Panel title={t('programPanel.dashboard.status.title')} staticEdit={{ pathname: `${panelPath}/config` }}>
			<>
				<PanelItem
					title={t('programPanel.dashboard.status.programNameDescription', {
						program: programData?.displayName,
						organization: programData?.organization?.displayName,
					})}
					value={programData?.organization?.displayName}
					transformValue={() => ''}
				/>
				<PanelItem
					title={t('programPanel.dashboard.status.programIsActive')}
					type="boolean"
					value={!programData?.finished}
					transformValue="active/unactive"
					inputCheckboxProps={{ invertValue: true }}
				/>
				<PanelItem
					title={t('programPanel.dashboard.status.programUrlAddress')}
					value={programUrl}
					transformValue={({ value }) => (
						<a href={value} target="_blank" rel="noreferrer" className=" underline">
							{value}
						</a>
					)}
				/>
				<PanelItem
					title={t('programPanel.dashboard.status.endDate')}
					value={programData?.endDate}
					type="date"
					clearable
					transformValue="date-only"
					withEmptyText
					bold
				/>
			</>
		</Panel>
	);
};
