import React, { createContext, useState, useEffect, useContext } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { userPanelApi, todosApi } from 'api';
import { useScope } from 'contexts/scopeContext';
import { Application, ApplicationRole } from 'types/Application';
import { Pair } from 'types/Pair';
import { TodosResponse } from 'types/Todo';
import { ProgramWithLanding } from 'types/Program';
import { ProgramMembership } from 'types/Membership';
import { PageLoading } from 'components/PageLoading';
import { useTranslation } from 'react-i18next';
import PageMissing from 'components/PageMissing';
import { AxiosError } from 'axios';
import { useAuth } from './authContext';
import { useGlobal } from './globalContext';

type PanelContextType = {
	panelType: ApplicationRole;
	acceptedApplicationsData: Application[];
	currentApplicationData: Application;
	canSwitchPanels: boolean;
	userPairs?: Pair[];
	userPairsRefetch: () => void;
	userPairsLoading: boolean;
	userPairsFetching: boolean;
	userPairsFetched: boolean;
	currentProgram: ProgramWithLanding;
	currentProgramMembership: ProgramMembership;
	todosData: TodosResponse;
	todosRefetch: () => void;
	panelLinkPrefix: string;
	panelScopePath: string;
};

const PanelContext = createContext<PanelContextType>({} as PanelContextType);

export const PanelContextProvider = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { type: typeFromParams } = useParams();

	const { ready } = useAuth();
	const { toastRef } = useGlobal();
	const {
		scopePath,
		currentProgram,
		currentProgramError,
		currentProgramMembership,
		acceptedApplicationsData,
		applicationsFetched,
	} = useScope();

	const { search: currentSearch } = useLocation();

	const [panelType, setPanelType] = useState<ApplicationRole | null>(null);
	const panelLinkPrefix = `panel/${scopePath}/${panelType}`;
	const canSwitchPanels = acceptedApplicationsData.length >= 2;

	const [currentApplicationData, setCurrentApplicationData] = useState<Application | undefined>();

	useEffect(() => {
		if (ready && currentProgramMembership !== undefined) {
			if (currentProgramMembership === null) {
				// no membership
				toastRef?.current?.show({ severity: 'info', detail: t('userPanel.noAccess') });
				navigate({
					pathname: `/${scopePath}`,
					search: currentSearch,
				});
			} else if (applicationsFetched) {
				const availablePanelTypes = acceptedApplicationsData.map(({ applicationRole }) => applicationRole);

				if (!typeFromParams || !availablePanelTypes.includes(typeFromParams as ApplicationRole)) {
					// TODO: toast "no panel access"
					// availablePanelTypes.length

					if (!availablePanelTypes.length) {
						toastRef?.current?.show({ severity: 'info', detail: t('userPanel.noAccess') });
					}

					navigate({
						pathname:
							availablePanelTypes.length > 0
								? `/panel/${scopePath}/${availablePanelTypes[0]}`
								: `/${scopePath}`,
						search: currentSearch,
					});
				}

				// valid panel type & access
				else {
					setCurrentApplicationData(
						acceptedApplicationsData.find(({ applicationRole }) => typeFromParams === applicationRole)
					);
					setPanelType(typeFromParams as ApplicationRole);
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		acceptedApplicationsData,
		applicationsFetched,
		typeFromParams,
		setPanelType,
		scopePath,
		toastRef,
		ready,
		currentProgramMembership,
		currentSearch,
	]);

	const {
		data: userPairs,
		refetch: userPairsRefetch,
		isLoading: userPairsLoading,
		isFetched: userPairsFetched,
		isFetching: userPairsFetching,
	} = useQuery(
		[
			'userPairsCurrent',
			{
				applicationRole: currentApplicationData?.applicationRole,
				programId: currentProgram?.id,
			},
		],
		() =>
			userPanelApi.getCurrentPairs(
				String(currentApplicationData?.applicationRole),
				Number(currentProgramMembership?.id)
			),
		{
			enabled: !!(currentApplicationData && currentProgramMembership),
			refetchInterval: 120000,
		}
	);

	const {
		data: todosData,
		refetch: todosRefetch,
		isLoading: todosLoading,
	} = useQuery(
		[
			'todos',
			{ membershipId: currentProgramMembership?.id, applicationRole: currentApplicationData?.applicationRole },
		],
		() =>
			todosApi.getTodos({
				membershipId: currentProgramMembership?.id,
				applicationRole: currentApplicationData?.applicationRole,
			}),
		{
			initialData: { common: [], pair: [] },
			enabled: Boolean(currentProgramMembership?.id) && Boolean(currentApplicationData?.applicationRole),
			refetchInterval: 120000,
		}
	);

	if (
		!ready ||
		(ready && currentProgramMembership === undefined) ||
		!currentApplicationData ||
		todosLoading ||
		userPairsLoading
	) {
		return <PageLoading text={t('userPanel.loadApplication')} />;
	}

	if (!currentProgram || !currentProgramMembership) {
		return (
			<PageMissing
				withRedirect={
					currentProgramError instanceof AxiosError &&
					currentProgramError.response?.status === 403 &&
					!currentProgramMembership
				}
			/>
		);
	}

	const panelScopePath = `/panel/${scopePath}/${panelType}`;

	return (
		<PanelContext.Provider
			value={{
				panelType: panelType as ApplicationRole,
				acceptedApplicationsData,
				currentApplicationData,
				canSwitchPanels,
				userPairs,
				userPairsRefetch,
				userPairsLoading,
				userPairsFetching,
				userPairsFetched,
				currentProgram,
				currentProgramMembership,
				todosData,
				todosRefetch,
				panelLinkPrefix,
				panelScopePath,
			}}
		>
			<Outlet />
		</PanelContext.Provider>
	);
};

export const usePanel = () => useContext(PanelContext);
