/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import { useMutation, useQuery } from '@tanstack/react-query';
import { mentibotAiApi } from 'api';
import { Box, BoxSection } from 'components/_new/Box';
import { InputTextArea } from 'components/_new/InputTextArea';
import { Button } from 'components/_new/Button';
import { Checkbox } from 'primereact/checkbox';
import { usePanel } from 'contexts/panelContext';
import { nl2br } from 'utils/nl2br';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'components/_new/Link';
import { Spinner } from 'components/_new/Spinner';
import { DialogAction } from 'components/_new/Dialog';
import { usePair } from 'contexts/pairContext';
import { MessageBox } from 'components/_new/MessageBox';
import mentibotQuestionsSvg from 'assets/icons/mentibot-questions.svg';
import mentibotTechniquesSvg from 'assets/icons/mentibot-techniques.svg';
import mentibotSmartSvg from 'assets/icons/mentibot-smart.svg';
import mentibotHomeworkSvg from 'assets/icons/mentibot-homework.svg';

type FormType = 'questions' | 'techniques' | 'smart' | 'homework';

type MentibotAIFormProps = {
	formType: FormType;
};

type Content = { formType: string; iconUrl: string; title: string; desc: string; beforeInput: string };

export const MentibotAIForm = ({ formType }: MentibotAIFormProps) => {
	const { t } = useTranslation();
	const { organizationName, programName, type } = useParams();
	const {
		pair: { id: pairId },
	} = usePair();

	const contents: Content[] = [
		{
			formType: 'questions',
			iconUrl: mentibotQuestionsSvg,
			title: t('userPanel.mentibotAi.form.questions.title'),
			desc: t('userPanel.mentibotAi.form.questions.desc'),
			beforeInput: t('userPanel.mentibotAi.form.questions.beforeInput'),
		},
		{
			formType: 'techniques',
			iconUrl: mentibotTechniquesSvg,
			title: t('userPanel.mentibotAi.form.techniques.title'),
			desc: t('userPanel.mentibotAi.form.techniques.desc'),
			beforeInput: t('userPanel.mentibotAi.form.techniques.beforeInput'),
		},
		{
			formType: 'smart',
			iconUrl: mentibotSmartSvg,
			title: t('userPanel.mentibotAi.form.smart.title'),
			desc: t('userPanel.mentibotAi.form.smart.desc'),
			beforeInput: t('userPanel.mentibotAi.form.smart.beforeInput'),
		},
		{
			formType: 'homework',
			iconUrl: mentibotHomeworkSvg,
			title: t('userPanel.mentibotAi.form.homework.title'),
			desc: t('userPanel.mentibotAi.form.homework.desc'),
			beforeInput: t('userPanel.mentibotAi.form.homework.beforeInput'),
		},
	];

	const { currentApplicationData, currentProgram } = usePanel();

	const {
		control,
		register,
		handleSubmit,
		watch,
		reset: formReset,
		formState,
	} = useForm({
		defaultValues: {
			prompt: '',
			terms: false,
		},
	});

	const { iconUrl, title, desc, beforeInput } = contents.find((content) => content.formType === formType) as Content;

	const { data, isSuccess, isFetched, refetch } = useQuery(
		['mentibot-ai', { formType }],
		() => mentibotAiApi.getLastReply(pairId, Number(currentApplicationData?.id), formType),
		{
			refetchInterval: 5000,
			enabled: true,
			onError: () => {},
			onSuccess: (result) => {
				if (result.status === 'error') {
					setWhileGenerating(false);
				} else {
					setWhileGenerating(result.status === 'waiting');
				}
				feedbackReset({ comment: result.info.comment || '' });
			},
		}
	);

	const [whileGenerating, setWhileGenerating] = useState(false);

	const hasResult = isSuccess && isFetched;
	const hasResultReply = hasResult && data?.content !== null;

	const { mutate, isLoading } = useMutation(
		(body: any) => mentibotAiApi.getNewReply(pairId, Number(currentApplicationData?.id), formType, body),
		{
			onSuccess: (result: any) => {
				if (result.status === 'error') {
					setWhileGenerating(false);
					setNewQuestionMode(true);
				} else {
					setWhileGenerating(result.status === 'waiting');
					refetch();
					setNewQuestionMode(false);
					formReset({ prompt: '', terms: false });
				}
			},
		}
	);

	const handleSubmitForm = handleSubmit((values) => {
		mutate(values);
	});

	const termsOn = watch('terms');
	const [newQuestionMode, setNewQuestionMode] = useState(false);

	const { mutateAsync: addRatingMutate, isLoading: addRatingLoading } = useMutation(
		({ rating, comment }: { rating?: number; comment?: string }) =>
			mentibotAiApi.addRating(pairId, Number(currentApplicationData?.id), formType, { rating, comment }),
		{
			onSuccess: () => {
				refetch();
			},
		}
	);
	const handleRateUpClick = () => {
		const idRatingUp = data && data.info.rating === 1;

		if (idRatingUp !== undefined) {
			addRatingMutate({ rating: idRatingUp ? 0 : 1 }).then(({ info: { rating } }) => {
				if (rating !== 0) {
					setFeadbackModalOpen(true);
				}
			});
		}
	};
	const handleRateDownClick = () => {
		const idRatingDown = data && data.info.rating === -1;
		if (idRatingDown !== undefined) {
			addRatingMutate({ rating: idRatingDown ? 0 : -1 }).then(({ info: { rating } }) => {
				if (rating !== 0) {
					setFeadbackModalOpen(true);
				}
			});
		}
	};

	const [feadbackModalOpen, setFeadbackModalOpen] = useState(false);
	// const { mutate: addFeedbackMutate, isLoading: addFeedbackLoading } = useMutation(
	// 	({ comment }: any) => mentibotAi.addFeedback(Number(currentApplicationData?.id), { comment }),
	// 	{
	// 		onSuccess: () => {
	// 			setFeadbackModalOpen(false);
	// 		},
	// 	}
	// );
	const {
		register: feedbackRegister,
		handleSubmit: feedbackHandleSubmit,
		reset: feedbackReset,
	} = useForm({
		defaultValues: { comment: '' },
	});
	const feedbackHandleSubmitForm = feedbackHandleSubmit(({ comment }) => {
		addRatingMutate({ comment }).then(() => {
			setFeadbackModalOpen(false);
		});
	});

	return (
		<>
			<DialogAction
				title={t('userPanel.mentibotAi.reply.ratingComment.title')}
				visible={feadbackModalOpen}
				onHide={() => {
					setFeadbackModalOpen(false);
				}}
				actions={[
					{
						key: 'save',
						form: 'feedback-form',
						label: t('actions.save'),
						loading: addRatingLoading,
						submit: true,
					},
				]}
			>
				<p>{t('userPanel.mentibotAi.reply.ratingComment.thanks')}</p>
				<form
					id="feedback-form"
					onSubmit={feedbackHandleSubmitForm}
					className="w-full flex flex-column gap-1 mt-2"
				>
					<InputTextArea
						{...feedbackRegister('comment')}
						required
						placeholder={t('userPanel.files.rating.commentPlaceholder')}
					/>
				</form>
			</DialogAction>
			<Box variant="blue" vertical dropShadow>
				<BoxSection className="flex-0 hidden md:block" style={{ width: '100px' }}>
					<img src={iconUrl} alt={`${formType} icon`} />
				</BoxSection>
				<BoxSection header={title} headerClassName="text-lg" contentClassName="flex flex-column gap-4">
					<div dangerouslySetInnerHTML={{ __html: desc }} />

					{hasResult && ['waiting', 'ready'].includes(data?.status || '') && !newQuestionMode ? (
						<div className="flex flex-column gap-4">
							{whileGenerating || !hasResultReply ? (
								<div className="flex flex-column justify-items-center align-items-center gap-2">
									<Spinner size="md" />
									<span>{t('userPanel.mentibotAi.reply.pleaseWait')}</span>
								</div>
							) : (
								<>
									<div>
										{t('userPanel.mentibotAi.reply.yourSubject')} <b>{data?.prompt}</b>
									</div>
									<div>{t('userPanel.mentibotAi.reply.replyInfo')}</div>
									<div className="text-italic line-height-22">
										{parse(nl2br(data?.content || ''))}
									</div>

									{data?.info.applicationId === currentApplicationData.id && (
										<div className="flex flex-column gap-1 align-items-center">
											<span>{t('userPanel.mentibotAi.reply.rating')}</span>
											<span className="inline-flex gap-2">
												<Button
													label={t('misc.yes')}
													onClick={handleRateUpClick}
													title="Rate up"
													disabled={addRatingLoading}
													variant={!(data?.info?.rating === 1) ? 'grey-text' : 'primary-text'}
													variantSize="lg"
													icon="thumbs-up"
													iconSet="fa"
													iconStyle="regular"
													iconClassName="text-3xl"
													iconPosition="right"
													className="p-1"
												/>
												<Button
													label={t('misc.no')}
													onClick={handleRateDownClick}
													title="Rate down"
													disabled={addRatingLoading}
													variant={
														!(data?.info?.rating === -1) ? 'grey-text' : 'primary-text'
													}
													variantSize="lg"
													icon="thumbs-down"
													iconSet="fa"
													iconStyle="regular"
													iconClassName="text-3xl"
													className="p-1"
												/>
											</span>
										</div>
									)}
									<div className="flex flex-row justify-content-end">
										<div className="flex flex-column align-items-end gap-2">
											<Link
												to="#"
												onClick={() => {
													setNewQuestionMode(true);
												}}
												disabled={data?.info?.left === 0}
												title={
													data?.info?.left === 0
														? 'Osiągnięto limit użycia Mentibota'
														: undefined
												}
											>
												{t('userPanel.mentibotAi.reply.newQuestion')}
											</Link>
											<Link
												to={`/panel/${organizationName}/${programName}/${type}/pair/${pairId}/mentibot-ai/list`}
											>
												{t('userPanel.mentibotAi.backToList')}
											</Link>
										</div>
									</div>
								</>
							)}
						</div>
					) : (
						<>
							<form onSubmit={handleSubmitForm} className="flex flex-column gap-4">
								<div>{beforeInput}</div>
								<div className="text-xxs">
									<Trans t={t} i18nKey="userPanel.mentibotAi.form.info" shouldUnescape />
								</div>

								{data?.status === 'error' && (
									<MessageBox variant="rose" message={t('userPanel.mentibotAi.error')} />
								)}

								<div>
									<InputTextArea
										{...register('prompt', {
											required: { value: true, message: t('misc.forms.fieldIsRequired') },
											minLength: {
												value: 10,
												message: t('misc.forms.pleaseEnterMinChars', { min: 10 }),
											},
										})}
										required
										disabled={!currentProgram.mentibotEnabled}
									/>
									{formState.errors.prompt && (
										<small className="p-error">{formState.errors.prompt.message}</small>
									)}
								</div>

								<div className="flex flex-row gap-2">
									<Controller
										control={control}
										name="terms"
										render={({ field }) => (
											<Checkbox
												{...field}
												checked={field.value}
												inputId="terms"
												disabled={!currentProgram.mentibotEnabled}
											/>
										)}
									/>
									<label className="flex-1" htmlFor="terms">
										{t('userPanel.mentibotAi.form.termsInfo')}
									</label>
								</div>

								<div className="flex flex-row justify-content-end">
									<Button
										submit
										label={t('userPanel.mentibotAi.form.sendQuestionAndGetReply')}
										variant="primary-outlined"
										rounded
										loading={isLoading}
										disabled={!currentProgram.mentibotEnabled || !termsOn || data?.info?.left === 0}
										title={
											data?.info?.left === 0
												? t('userPanel.mentibotAi.form.limitReachet')
												: undefined
										}
									/>
								</div>
							</form>
							{newQuestionMode && (
								<div className="flex flex-row justify-content-end">
									<div className="flex flex-column align-items-end gap-2">
										<Link
											to="#"
											onClick={() => {
												setNewQuestionMode(false);
											}}
										>
											{t('userPanel.mentibotAi.form.backToPrevReply')}
										</Link>
										<Link
											to={`/panel/${organizationName}/${programName}/${type}/pair/${pairId}/mentibot-ai/list`}
										>
											{t('userPanel.mentibotAi.backToList')}
										</Link>
									</div>
								</div>
							)}
						</>
					)}

					{isSuccess && data?.info && (
						<>
							{data.info.left === 0 && <div>{t('userPanel.mentibotAi.form.limitReachetLong')}</div>}
							{data.info.left > 0 && (
								<div>
									<Trans
										t={t}
										i18nKey="userPanel.mentibotAi.form.limitInfo"
										components={[<span className="text-purple font-bold" />]}
										values={{
											left: data?.info?.left,
										}}
									/>
								</div>
							)}
						</>
					)}
				</BoxSection>
			</Box>
		</>
	);
};
