import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePanel } from 'contexts/panelContext';
import { Pair } from 'types/Pair';
import { colors } from 'theme';
import { Button } from 'primereact/button';
import PairPersonalInfo from 'assets/icons/pair-personal-info.svg';
import BusinessCard from 'components/_common/panel/BusinessCard';
import { GroupedChoiceList } from 'types/Question';
import { userDisplayName } from 'utils/userDisplayName';
import { Box, BoxSection } from 'components/_new/Box';
import { Avatar } from 'components/_new/Avatar';
import { Dialog } from 'components/_new/Dialog';
import { prepareUserAreas } from 'utils/prepareUserAreas';
import { parseHtml } from 'utils/htmlParser';
import { classNames } from 'primereact/utils';
import PairActions from './PairActions';
import OptionItem from './OptionItem';

interface PairProps {
	key?: string | number;
	id?: string;
	data: Pair;
	userAreas?: string[];
	disableActions?: boolean;
	withActions?: boolean;
	withGreenBorder?: boolean;
	onAction?: () => void;
	rednerBottom?: () => React.ReactNode;
}
interface Option {
	area: string;
	match: boolean;
}

const PairItem = ({
	id,
	data,
	userAreas,
	disableActions,
	withActions = false,
	withGreenBorder = false,
	onAction,
	rednerBottom,
	...restProps
}: PairProps) => {
	const { t } = useTranslation();
	const { panelType } = usePanel();

	const personalDetails = data.application;
	const {
		application: {
			programMembership: { user },
			systemAnswers: { position, expectations, areas, department },
		},
	} = data;
	const [togglePersonalDetails, setTogglePersonalDetails] = useState(false);

	const getOptions = () => {
		if (areas) {
			const pairAreas = prepareUserAreas(areas);
			const separatedAreas: Option[] = [];
			pairAreas?.forEach((area) => {
				const isAreaMatch = userAreas?.find((userArea) => userArea === area);
				if (isAreaMatch) return separatedAreas.unshift({ area, match: true });
				return separatedAreas.push({ area, match: false });
			});
			return separatedAreas;
		}
		return [];
	};

	const displayName = userDisplayName(user);

	const renderActions = () => (
		<PairActions
			pairFullname={displayName}
			type={panelType}
			data={data}
			disableButtons={disableActions}
			onAction={onAction}
		/>
	);

	const renderContent = (content: any, separator = ', ') => {
		if (Array.isArray(content)) {
			return content.join(separator);
		}
		return parseHtml(content);
	};

	const renderTitle = () => (
		<div className="flex flex-column w-full justify-content-between sm:flex-row">
			<Button
				onClick={() => setTogglePersonalDetails(true)}
				className="flex flex-row align-items-center p-button p-button-link p-0 color-inherit text-left"
			>
				<div className="flex flex-row justify-content-start gap-3 w-full">
					<Avatar src={user.avatar} size="lg" name={userDisplayName(user, null)} />
					<div>
						<div className="font-semibold text-3xl mb-1">{displayName}</div>
						<div className="font-semibold">
							{`${position ? `${renderContent(position)}` : ''}${
								department ? `, ${renderContent(department)}` : ''
							}`}
						</div>
					</div>
				</div>
			</Button>
			<div
				className="font-normal flex align-items-center text-xs  md:ml-0 ml-7"
				style={{ color: colors.purplishBlue }}
			>
				<Button className="p-button-text" onClick={() => setTogglePersonalDetails(true)}>
					<img src={PairPersonalInfo} className="pr-2" alt="person" />
					{t('userPanel.pairing.personalDetails')}
				</Button>
			</div>
		</div>
	);

	return (
		<div id={id} {...restProps} className="mb-4">
			<Box
				variant="white-bordered"
				className={classNames(withGreenBorder && 'border-3 border-green-300 bg-green-50/50')}
			>
				<BoxSection header={renderTitle()}>
					<div>
						<div className="color-gray">
							{panelType === 'mentee'
								? t('userPanel.pairing.mentorCompentences')
								: t('userPanel.pairing.menteeDevelopmentAres')}
							:
						</div>
						<div className="flex mt-1 gap-2 flex-wrap flex-start flex-column sm:flex-row">
							{getOptions().map((area) => (
								<OptionItem key={area.area} option={area} />
							))}
						</div>
					</div>
					<div className="flex flex-column gap-2 py-4">
						<div>
							<div className="color-gray">
								{panelType === 'mentee'
									? t('userPanel.pairing.mentorCanOffer')
									: t('userPanel.pairing.menteeGoalsInProgram')}
								:
							</div>
							<div className="font-italic">{renderContent(expectations)}</div>
						</div>
						{personalDetails && personalDetails.limitStats && (
							<div>
								<div className="color-gray">
									{t('programPanel.application.pairPropositions.acceptedPairs')}
								</div>
								<div>
									<span
										className={classNames({
											'text-success font-bold': personalDetails.limitStats.pairLimitReached,
										})}
									>
										{personalDetails.limitStats?.currentPairs}/{personalDetails.pairLimit}
									</span>
								</div>
							</div>
						)}
					</div>
					{rednerBottom && <div>{rednerBottom()}</div>}
					{withActions ? renderActions() : undefined}
				</BoxSection>
			</Box>
			<Dialog
				title={t('userPanel.pairing.personalDetailsPair', {
					type: panelType === 'mentee' ? t('misc.mentors') : t('misc.mentees'),
				})}
				visible={togglePersonalDetails}
				onHide={() => setTogglePersonalDetails(false)}
				size="lg"
				className="p-dialog-buissnescard"
			>
				<BusinessCard
					data={personalDetails}
					additionalAnswers={[
						personalDetails && personalDetails?.limitStats
							? {
									label: t('programPanel.application.pairPropositions.acceptedPairs'),
									value: (
										<span
											className={classNames({
												'text-success font-bold': personalDetails.limitStats.pairLimitReached,
											})}
										>
											{personalDetails?.limitStats?.currentPairs}/{personalDetails.pairLimit}
										</span>
									),
							  }
							: false,
					]}
				/>
			</Dialog>
		</div>
	);
};

export default PairItem;
