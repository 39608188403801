const startIndex = 901;
// eslint-disable-next-line import/no-mutable-exports
let baseIndex = startIndex;

export { startIndex, baseIndex };

export function incrementIndex() {
	// eslint-disable-next-line no-plusplus
	baseIndex++;
	return baseIndex;
}

export function decrementIndex() {
	// eslint-disable-next-line no-plusplus
	baseIndex--;
	return baseIndex;
}
