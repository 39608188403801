import React, { forwardRef, Ref } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { userPanelApi } from 'api';
import { useGlobal } from 'contexts/globalContext';
import { usePanel } from 'contexts/panelContext';
import { DialogAction, DialogBaseExtendProps } from 'components/_new/Dialog';
import { Session } from 'types/Session';
import { CalendarMeetingPayload } from 'types/payloads/CalendarMeetingPayload';
import { Checkbox } from 'primereact/checkbox';

type SendIcsDialogProps = DialogBaseExtendProps & {
	session: Session;
};

export const SendMeetingCalendarDialog = forwardRef(
	({ session, onHide, ...restProps }: SendIcsDialogProps, ref: Ref<HTMLDivElement>) => {
		const { t } = useTranslation();
		const { currentProgramMembership } = usePanel();
		const { toastRef } = useGlobal();

		const { mutate, isLoading } = useMutation(
			(forMeOnly: CalendarMeetingPayload) =>
				userPanelApi.sendCalendarEmail(session.pairId, currentProgramMembership.id, session.id, forMeOnly),
			{
				onSuccess: () => {
					toastRef?.current?.show({
						severity: 'success',
						life: 3000,
						summary: t('misc.success'),
						detail: t('userPanel.sessions.sendICS.success'),
					});
					if (onHide) {
						onHide();
					}
				},
			}
		);

		const { control, handleSubmit } = useForm<{ forMeOnly: boolean }>({
			defaultValues: { forMeOnly: false },
		});

		const handleSubmitForm = handleSubmit(({ forMeOnly }) => {
			mutate({ forMeOnly });
		});

		return (
			<DialogAction
				ref={ref}
				title={t('userPanel.sessions.sendICS.send')}
				actions={[
					{
						key: 'send-to-calendar',
						submit: true,
						form: 'send-ics-form',
						label: t('userPanel.myCalendar.sendToCalendar'),
						loading: isLoading,
					},
				]}
				onHide={onHide}
				bodyClassName="flex flex-column gap-4"
				{...restProps}
			>
				<form id="send-ics-form" onSubmit={handleSubmitForm}>
					<p>{t('userPanel.sessions.sendICS.description')}</p>
					<div className="mb-5">
						<Controller
							name="forMeOnly"
							control={control}
							render={({ field: { onChange, value, ...restField } }) => (
								<label>
									<Checkbox
										{...restField}
										className="mt-4 mr-2"
										checked={!value}
										onChange={({ target: { checked } }) => onChange(!checked)}
									/>
									{t('userPanel.sessions.sendICS.forMeOnly')}
								</label>
							)}
						/>
					</div>
				</form>
			</DialogAction>
		);
	}
);
