import React, {
	useMemo,
	ReactNode,
	forwardRef,
	Ref,
	ButtonHTMLAttributes,
	useCallback,
	MouseEventHandler,
} from 'react';
import { classNames } from 'primereact/utils';
import { universalRenderer } from 'utils/universalRenderer';
import { Icon, IconProps, IconSet } from '../Icon';
import './Button.scss';

type ButtonVariantSize = 'xs' | 'sm' | 'md' | 'lg';

type ButtonVariant =
	| 'primary'
	| 'primary-outlined'
	| 'priamry-light'
	| 'primary-text'
	| 'white-text'
	| 'grey'
	| 'grey-light'
	| 'grey-text'
	| 'rose'
	| 'rose-light'
	| 'rose-text'
	| 'transparent'
	| 'danger'
	| 'danger-text'
	| 'on-purple';

export type ButtonProps = Pick<
	ButtonHTMLAttributes<HTMLButtonElement>,
	'id' | 'title' | 'className' | 'form' | 'onClick' | 'onMouseEnter' | 'onMouseLeave'
> & {
	label: string;
	loading?: boolean;
	variant?: ButtonVariant;
	variantSize?: ButtonVariantSize;
	noPad?: boolean;
	icon?: string | ReactNode | (() => ReactNode);
	iconSet?: IconSet;
	iconStyle?: IconProps['iconStyle'];
	iconOnly?: boolean;
	iconClassName?: string;
	iconPosition?: 'left' | 'right';
	submit?: boolean;
	unactive?: boolean;
	disabled?: boolean;
	rounded?: boolean;
	noTitle?: boolean;
	bold?: boolean;
	children?: ReactNode;
	badge?: number;
	block?: boolean;
	responsiveClassName?: string;
};

export const Button = forwardRef(
	(
		{
			label,
			loading = false,
			variant = 'primary',
			variantSize = 'md',
			noPad = false,
			className,
			icon,
			iconSet,
			iconStyle,
			iconOnly,
			iconClassName,
			iconPosition = 'left',
			submit = false,
			unactive = false,
			disabled = false,
			rounded = false,
			noTitle = false,
			bold = false,
			onClick,
			children,
			badge,
			block,
			responsiveClassName,
			...props
		}: ButtonProps,
		ref: Ref<HTMLButtonElement>
	) => {
		const finalClassName = useMemo(() => {
			const variantSizeClassName = classNames({
				'button-size-xs': variantSize === 'xs',
				'button-size-sm': variantSize === 'sm',
				'button-size-md': variantSize === 'md',
				'button-size-lg': variantSize === 'lg',
			});

			const variantClassName = classNames('button', `button-${variant}`, {
				'button-active': loading,
				'button-unactive': unactive,
				'button-disabled': disabled,
				'button-rounded': rounded,
				'button-icononly': iconOnly,
				'button-bold': bold,
			});

			return classNames(
				{
					flex: !responsiveClassName,
					[String(responsiveClassName)]: Boolean(responsiveClassName),
				},
				'flex-row',
				variantClassName,
				variantSizeClassName,
				{ 'button-nopad': noPad, 'w-full': block },
				className
			);
		}, [
			variantSize,
			variant,
			loading,
			unactive,
			disabled,
			rounded,
			iconOnly,
			bold,
			responsiveClassName,
			noPad,
			block,
			className,
		]);

		const renderIcon = () => {
			const renderIconInner = () => {
				if (loading) {
					return <Icon name="circle-notch" iconSet="fa" iconStyle="solid" className="fa-spin" />;
				}
				if (icon) {
					if (typeof icon === 'string') {
						return (
							<Icon
								name={icon}
								iconSet={iconSet}
								iconStyle={iconStyle}
								className={classNames(iconClassName)}
							/>
						);
					}
					return <span className={iconClassName}>{universalRenderer(icon)}</span>;
				}
				return null;
			};
			if (loading || icon || iconOnly) {
				if (iconOnly) {
					return (
						<>
							<span className="button-icon-placeholder" />
							<span className="button-icon">{renderIconInner()}</span>
						</>
					);
				}
				return <span className="button-icon">{renderIconInner()}</span>;
			}
			return null;
		};

		const title = iconOnly ? label : undefined;

		const renderContent = () => (
			<>
				{iconPosition === 'left' && renderIcon()}
				{!iconOnly ? <span>{label}</span> : null}
				{iconPosition === 'right' && renderIcon()}
				{badge && badge > 0 ? <span className="button-badge bg-rose">{badge}</span> : null}
			</>
		);

		const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
			(event) => {
				if (loading || disabled) {
					event.preventDefault();
				} else {
					onClick?.(event);
				}
			},
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[disabled, loading]
		);

		return (
			// eslint-disable-next-line react/button-has-type
			<button
				ref={ref}
				{...(submit ? { type: 'submit' } : {})}
				{...(!submit ? { type: 'button' } : {})}
				className={finalClassName}
				aria-label={label}
				aria-busy={loading}
				disabled={disabled}
				aria-disabled={disabled}
				title={noTitle ? undefined : title}
				onClick={handleClick}
				{...props}
			>
				{children || renderContent()}
			</button>
		);
	}
);
