import React, { FC, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import i18n from 'locale';
import { Steps } from 'primereact/steps';
import { Dropdown } from 'components/_common/forms/Dropdown';
import { Spinner } from 'components/_new/Spinner';
import { MenuItem } from 'primereact/menuitem';
import { userDisplayName } from 'utils/userDisplayName';
import { classNames } from 'primereact/utils';
import { usePanel } from 'contexts/panelContext';
import { border, colors } from 'theme';
import { useProgressSteps } from 'hooks/useProgressSteps';
import { capitalizeWord } from 'utils/helpers';
import SubPageTitle from '../panel/SubPageTitle';
import './ProgressSteps.scss';

interface ProgressStepsProps {
	steps?: any[];
	activeStep?: number;
	width?: string;
}

const ProgressSteps: FC<ProgressStepsProps> = ({
	width,
	steps: staticSteps,
	activeStep: staticActiveStep,
}: ProgressStepsProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { organizationName, programName, type } = useParams();

	const { currentApplicationData } = usePanel();
	const { forms, refetch, data, activeIndex, pairId, setPairId, isLoading, isFetching, currentPairsOptions } =
		useProgressSteps({
			staticSteps,
			staticActiveStep,
		});

	useEffect(() => {
		if (!forms) {
			refetch();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [forms, pairId]);

	const stepMapper = useCallback(
		(step: any) => {
			const dataPairId = step?.data?.pairId;
			const sessionId = step?.data?.sessionId;
			switch (step.type) {
				case 'has_generated_report':
					return {
						label: t('userPanel.progressSteps.steps.has_generated_report'),
						url: `/panel/${organizationName}/${programName}/${type}/pair/${dataPairId}/process-summary`,
					};
				case 'has_last_session':
					return {
						label: t('userPanel.progressSteps.steps.has_last_session'),
						url: `/panel/${organizationName}/${programName}/${type}/pair/${dataPairId}/final-report`,
					};
				case 'has_penultimate_session':
					return {
						label: t('userPanel.progressSteps.steps.has_penultimate_session'),
						url: `/panel/${organizationName}/${programName}/${type}/pair/${dataPairId}/session/${sessionId}`,
					};
				case 'after_mid_session':
					return {
						label: t('userPanel.progressSteps.steps.after_mid_session'),
						url: `/panel/${organizationName}/${programName}/${type}/pair/${dataPairId}/session`,
					};
				case 'has_mid_session':
					return {
						label: t('userPanel.progressSteps.steps.has_mid_session'),
						url: `/panel/${organizationName}/${programName}/${type}/pair/${dataPairId}/session/${sessionId}`,
					};
				case 'after_first_session':
					return {
						label: t('userPanel.progressSteps.steps.after_first_session'),
						url: `/panel/${organizationName}/${programName}/${type}/pair/${dataPairId}/session`,
					};
				case 'has_first_session':
					return {
						label: t('userPanel.progressSteps.steps.has_first_session'),
						url: `/panel/${organizationName}/${programName}/${type}/pair/${dataPairId}/session/${sessionId}`,
					};
				case 'has_contract':
					return {
						label: t('userPanel.progressSteps.steps.first_session_and_contract'),
						url: `/panel/${organizationName}/${programName}/${type}/pair/${dataPairId}/session`,
					};
				case 'has_entry_meeting':
					return {
						label: t('userPanel.progressSteps.steps.has_entry_meeting'),
						url: `/panel/${organizationName}/${programName}/${type}/pairing`,
					};
				case 'familiarize_with_program':
					return {
						label: t('userPanel.progressSteps.steps.familiarize_with_program'),
						url: `/${organizationName}/${programName}`,
					};
				case 'has_application_form':
					return {
						label: t('userPanel.progressSteps.steps.has_application_form'),
						url: `/${organizationName}/${programName}/apply-${type}`,
					};
				case 'choosing_mentor':
					return {
						label: t('userPanel.progressSteps.steps.choosing_mentor'),
						url: `/panel/${organizationName}/${programName}/${type}/pairing`,
					};
				case 'zero_session':
					return {
						label: t('userPanel.progressSteps.steps.zero_session'),
						url: `/panel/${organizationName}/${programName}/${type}/pairing`,
					};
				case 'pair_confirmation':
					return {
						label: t('userPanel.progressSteps.steps.pair_confirmation'),
						url: `/panel/${organizationName}/${programName}/${type}/pairing`,
					};
				case 'first_session':
					return {
						label: t('userPanel.progressSteps.steps.first_session'),
						url: `/panel/${organizationName}/${programName}/${type}/pairing`,
					};
				case 'fulfillment_of_contract':
					return {
						label: t('userPanel.progressSteps.steps.fulfillment_of_contract'),
						url: `/panel/${organizationName}/${programName}/${type}/pairing`,
					};
				case 'fulfillment_of_development_plan':
					return {
						label: (
							<Trans
								t={t}
								i18nKey="userPanel.progressSteps.steps.fulfillment_of_development_plan"
								values={{
									type:
										i18n.resolvedLanguage === 'pl' && type === 'mentor'
											? 'Mentora'
											: capitalizeWord(type ?? ''),
								}}
							/>
						),

						url: `/panel/${organizationName}/${programName}/${type}/pairing`,
					};
				case 'next_mentoring_sessions':
					return {
						label: t('userPanel.progressSteps.steps.next_mentoring_sessions'),
						url: `/panel/${organizationName}/${programName}/${type}/pairing`,
					};
				case 'register_in_program':
					return {
						label: t('userPanel.progressSteps.steps.register_in_program'),
						url: `/${organizationName}/${programName}`,
					};
				default:
					return null;
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[organizationName, programName, type]
	);

	const stepsData = (staticSteps || data?.steps || [])
		.map(stepMapper)
		.filter((item) => item?.label)
		.map((item, index) => ({
			...item,
			className: classNames({
				'p-step-prev': index < activeIndex,
				'p-step-next': index > activeIndex,
			}),
			disabled: forms ? index : index > activeIndex,
		})) as MenuItem[];

	const handleChangePairDropdown = useCallback((event: any) => {
		setPairId(event.value);
	}, []);

	const handleStepsSelect = useCallback(
		(event: any) => {
			event.originalEvent.preventDefault();
			const item = stepsData[event.index];
			navigate({
				pathname: item.url,
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[stepsData]
	);

	if (!forms && (isLoading || isFetching)) {
		return <Spinner />;
	}

	return (
		<div className="hidden lg:flex flex-column mb-1" style={{ width }}>
			<div
				className="flex justify-content-between align-items-center pb-2 mb-3"
				style={{ borderBottom: border(colors.lightGray) }}
			>
				{currentPairsOptions && currentPairsOptions?.length > 0 && !forms ? (
					<div className="flex">
						{currentPairsOptions && currentPairsOptions?.length === 1 && (
							<SubPageTitle
								title={`${t('userPanel.progressSteps.progressWith')} ${userDisplayName(
									currentApplicationData.applicationRole === 'mentee'
										? currentPairsOptions[0].mentorMembership?.user
										: currentPairsOptions[0].menteeMembership?.user
								)}`}
							/>
						)}
						{currentPairsOptions && currentPairsOptions?.length > 1 && (
							<>
								<SubPageTitle
									title={t('userPanel.progressSteps.progressWith')}
									additionalStyles={{ fontSize: '1.5rem' }}
								/>
								<Dropdown
									className="w-15rem h-3rem mt-2 ml-2"
									options={currentPairsOptions}
									optionValue="id"
									value={
										currentPairsOptions.find((item) => item.id === pairId)?.id ||
										currentPairsOptions?.[0]?.id
									}
									onChange={handleChangePairDropdown}
									rounded
									placeholder={currentPairsOptions[0].id.toString()}
								/>
							</>
						)}
					</div>
				) : (
					<div />
				)}
			</div>
			<div className="hidden lg:inline-flex overflow-x-auto">
				<Steps
					style={{ width }}
					className="p-steps-progress w-full"
					model={stepsData}
					activeIndex={activeIndex}
					onSelect={handleStepsSelect}
					readOnly={false}
				/>
			</div>
		</div>
	);
};

export default ProgressSteps;
