import React from 'react';
import { useTranslation } from 'react-i18next';

import { Pair } from 'types/Pair';
import PairRejected from 'assets/icons/pair-cancel.svg';
import PairStatus from 'assets/icons/pair-status.svg';
import PairAccept from 'assets/icons/pair-accept.svg';

import { usePanel } from 'contexts/panelContext';
import { ConfirmPairItem } from './ConfirmPairItem';
import { IconWrapper } from './IconWrapper';

interface MentorActionsProps {
	data: Pair;
	setInfoDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
	disabled?: boolean;
	onAction?: () => void;
}

const MentorActions = ({ data, setInfoDialogOpen, disabled, onAction }: MentorActionsProps) => {
	const { t } = useTranslation();
	const {
		currentApplicationData: { limitStats },
	} = usePanel();
	const youPairLimitReached = Boolean(limitStats?.pairLimitReached);

	const {
		rejectedByMentee,
		rejectedByMentor,
		acceptedByMentee,
		acceptedByMentor,
		id,
		mentorMembershipId,
		status,
		application,
	} = data;
	const pairLimitReached = Boolean(application.limitStats?.pairLimitReached);
	const noRelationship =
		rejectedByMentor === null &&
		acceptedByMentor === null &&
		acceptedByMentee === null &&
		rejectedByMentee === null;

	const renderInfo = () => {
		const noRelationship =
			rejectedByMentor === null &&
			acceptedByMentor === null &&
			acceptedByMentee === null &&
			rejectedByMentee === null;

		if (acceptedByMentee && acceptedByMentor) {
			return (
				<IconWrapper
					imgSrc={PairAccept}
					alt={t('userPanel.pairing.altPairAccepted')}
					text={t('userPanel.pairing.pairAccepted')}
				/>
			);
		}

		if (rejectedByMentee) {
			return (
				<IconWrapper
					imgSrc={PairRejected}
					alt={t('userPanel.pairing.altPairRejected')}
					text={t('userPanel.pairing.pairRejected', { type: 'Mentee' })}
				/>
			);
		}

		if (rejectedByMentor) {
			return (
				<div className="flex w-full justify-content-between">
					<IconWrapper
						imgSrc={PairRejected}
						alt={t('userPanel.pairing.altPairRejected')}
						text={t('userPanel.pairing.youRejected')}
					/>
				</div>
			);
		}

		if (youPairLimitReached) {
			return (
				<IconWrapper
					imgSrc={PairRejected}
					alt={t('userPanel.pairing.youPairLimitReached')}
					text={t('userPanel.pairing.youPairLimitReached')}
				/>
			);
		}

		if (pairLimitReached) {
			return (
				<IconWrapper
					imgSrc={PairRejected}
					alt={t('userPanel.pairing.pairLimitReached')}
					text={t('userPanel.pairing.pairLimitReached')}
				/>
			);
		}

		if (acceptedByMentee && !acceptedByMentor) {
			return (
				<IconWrapper
					imgSrc={PairStatus}
					text={t('userPanel.pairing.oppositeSideAccepted', { type: 'Mentee' })}
					alt={t('userPanel.pairing.oppositeSideAccepted')}
				/>
			);
		}

		if (acceptedByMentor && !acceptedByMentee) {
			return (
				<IconWrapper
					imgSrc={PairStatus}
					alt={t('userPanel.pairing.altPairStatus')}
					text={t('userPanel.pairing.waitForAnswer', { type: 'Mentee' })}
				/>
			);
		}

		if (noRelationship && status === 'open') {
			return (
				<IconWrapper
					imgSrc={PairStatus}
					text={t('userPanel.pairing.meetingInvitationSent')}
					alt={t('userPanel.pairing.meetingInvitationSent')}
				/>
			);
		}

		return null;
	};

	return (
		<div className="flex flex-wrap gap-2 justify-content-between w-full align-content-center">
			<div className="flex-1 flex flex-column justify-content-center min200px">{renderInfo()}</div>
			<div className="flex-1 flex justify-content-end align-content-center">
				{((noRelationship && status === 'open') || (acceptedByMentee && !acceptedByMentor)) &&
					!youPairLimitReached &&
					!pairLimitReached && (
						<ConfirmPairItem
							id={id}
							membershipId={mentorMembershipId}
							setInfoDialogOpen={setInfoDialogOpen}
							hideButton={disabled}
							onAction={onAction}
						/>
					)}
			</div>
		</div>
	);
};

export default MentorActions;
