import React, { PropsWithChildren, createContext, useContext } from 'react';
import { useScope } from 'contexts/scopeContext';
import { chatApi } from 'api/chat';
import { useLongPollingQuery } from './useLP';

const ChatContext = createContext({
	subscribe: (eventName: string, listener: any) => {},
	unsubscribe: (eventName: string, listener: any) => {},
});

export const CHAT_THREAD_KEY = 'chat-thread';
export const CHAT_THREAD_SELF_KEY = 'chat-thread-self';

// eslint-disable-next-line @typescript-eslint/ban-types
export const ChatContextProvider = ({ children, ...props }: PropsWithChildren<{}>) => {
	const { currentProgramMembership } = useScope();

	const subscribe = (eventName: string, listener: any) => {
		document.addEventListener(eventName, listener);
	};

	const unsubscribe = (eventName: string, listener: any) => {
		document.removeEventListener(eventName, listener);
	};

	useLongPollingQuery(
		['sub-chat-thread', Number(currentProgramMembership?.id)],
		({ signal }) =>
			chatApi.subChatThread({ params: { programMembershipId: Number(currentProgramMembership?.id) }, signal }),
		{
			enabled: Boolean(currentProgramMembership),
			onMessage: (data) => {
				const event = new CustomEvent(CHAT_THREAD_KEY, { detail: data });
				document.dispatchEvent(event);
			},
		}
	);

	useLongPollingQuery(
		['sub-chat-thread-self', Number(currentProgramMembership?.id)],
		({ signal }) =>
			chatApi.subChatThreadSelf({
				params: { programMembershipId: Number(currentProgramMembership?.id) },
				signal,
			}),
		{
			enabled: Boolean(currentProgramMembership),
			onMessage: (data) => {
				const event = new CustomEvent(CHAT_THREAD_SELF_KEY, { detail: data });
				document.dispatchEvent(event);
			},
		}
	);

	return (
		<ChatContext.Provider value={{ subscribe, unsubscribe }} {...props}>
			{children}
		</ChatContext.Provider>
	);
};

export const useChatContext = () => useContext(ChatContext);
