import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ActionGoalId } from 'types/Goal';
import { usePanel } from 'contexts/panelContext';
import SubPageTitle from 'components/_common/panel/SubPageTitle';
import SigningField from 'components/_common/panel/SigningField';
import MessageBox from 'components/_common/panel/MessageBox';
import { Box, BoxSection } from 'components/_new/Box';
import { Button } from 'primereact/button';
import { usePair } from 'contexts/pairContext';
import { PageLoading } from 'components/PageLoading';
import PageMissing from 'components/PageMissing';
import useGoals from 'hooks/useGoals';
import { userDisplayName } from 'utils/userDisplayName';
import { useAuth } from 'contexts/authContext';
import { CurrentUser } from 'types/CurrentUser';
import GoalItem from './GoalItem';

const GoalsWrapper = () => {
	const { t } = useTranslation();

	const { currentUser } = useAuth();
	const { panelType } = usePanel();
	const { pair } = usePair();
	const { getGoals, saveNewGoal, updateGoal, deleteGoal, signGoals, fetchedGoals, goalLoading, goalError } = useGoals(
		pair.id
	);
	const [currentGoals, setCurrentGoals] = useState(fetchedGoals?.goals);
	const [editedGoal, setEditedGoal] = useState<number | null>(null);

	const pairFullName = userDisplayName(pair?.application.programMembership.user);
	const userFullName = userDisplayName(currentUser as CurrentUser);
	const menteeFullName = pair?.application.applicationRole === 'mentee' ? pairFullName : userFullName;

	const addNewGoal = () => {
		getGoals();
		setEditedGoal(ActionGoalId.new);
		setCurrentGoals((prevGoals) => [
			...(prevGoals || []),
			{
				id: ActionGoalId.new,
				goal: '',
				change: '',
				achivmentPath: '',
				priorities: '',
				notes: '',
			},
		]);
	};
	useEffect(() => {
		setCurrentGoals(fetchedGoals?.goals);
	}, [fetchedGoals]);

	useEffect(() => {
		if (editedGoal === null) setCurrentGoals(fetchedGoals?.goals);
	}, [editedGoal, setCurrentGoals, fetchedGoals]);

	if (goalError) return <PageMissing />;

	if (goalLoading || !fetchedGoals) <PageLoading />;

	return (
		<>
			<SubPageTitle title={`${t('userPanel.navigation.mentoringPlan')}: ${menteeFullName}`} />
			<Box variant="white-bordered">
				<BoxSection>
					<div className="font-bold pb-2">{t('userPanel.goal.goalsIntroHeader')}</div>
					<Trans
						t={t}
						i18nKey={`userPanel.goal.${
							panelType === 'mentor' ? 'goalsIntroTextMentor' : 'goalsIntroTextMentee'
						}`}
						components={[<br />, <ul />, <li />]}
					/>

					<div className="mt-5">
						<MessageBox tip content={[{ text: t('userPanel.goal.goalHint') }]} />
					</div>

					<div className="ml-4 mt-5 mb-3 font-bold text-xl">{t('userPanel.goal.yourGoals')}</div>
					<ul style={{ paddingLeft: 0 }}>
						{currentGoals?.map((goalData, index) => (
							<GoalItem
								editedGoal={editedGoal}
								getGoals={getGoals}
								saveNewGoal={saveNewGoal}
								updateGoal={updateGoal}
								deleteGoal={deleteGoal}
								key={goalData.id}
								goalData={goalData}
								index={index + 1}
								setEditedGoal={setEditedGoal}
								// bold={index === 0}
							/>
						))}
					</ul>
					{editedGoal === null && (
						<div className="flex align-items-center mb-2 ml-4 font-bold text-xl">
							<Button onClick={addNewGoal} className="p-1 mr-2">
								<i className="pi pi-plus text-xs" />
							</Button>
							<span>{t('userPanel.contract.addGoal')}</span>
						</div>
					)}
					<div className="my-4">
						<div className="mb-5 mt-8">{t('userPanel.goal.goalsSignedBy')}</div>
						<SigningField
							signContract={signGoals}
							pairFullName={pairFullName}
							userFullName={userFullName}
							menteeSign={fetchedGoals?.goalSignDateMentee}
							mentorSign={fetchedGoals?.goalSignDateMentor}
							panelType={panelType}
						/>
					</div>
				</BoxSection>
			</Box>
		</>
	);
};

export default GoalsWrapper;
