import { GroupedChoiceList } from 'types/Question';

export const matchOptionsByGroup = (choiceList: GroupedChoiceList[], fieldValue: GroupedChoiceList[]) => {
	const flatOptionsList = choiceList.map((group) => group.options).flat();

	return choiceList.map((group) => ({
		title: group.title,
		options: Array.isArray(fieldValue)
			? fieldValue.reduce((previousIterationMatches: string[], currentGroup: GroupedChoiceList) => {
					const newMatches = (currentGroup.options || []).reduce((matches: string[], currentOption) => {
						const isOptionMatching = !!flatOptionsList.find(
							(possibleOption) => possibleOption === currentOption
						);
						const currentOptionOriginalGroup = choiceList.find((group) =>
							group.options.includes(currentOption)
						);

						if (isOptionMatching && currentOptionOriginalGroup?.title === group.title)
							return [...matches, currentOption];

						return matches;
					}, []);

					return [...previousIterationMatches, ...newMatches];
			  }, [])
			: [],
	}));
};
