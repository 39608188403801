import React, { Ref, forwardRef, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { Dropdown as DropdownOriginal, DropdownProps as DropdownPropsOriginal } from 'primereact/dropdown';
import mergeRefs from 'merge-refs';

export type DropdownProps = DropdownPropsOriginal & {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	rounded?: boolean;
	hasError?: any;
	inline?: boolean;
};

export const Dropdown = forwardRef(
	({ hasError, rounded, className, inline, ...props }: DropdownProps, ref: Ref<HTMLInputElement>) => {
		const finalClassName = classNames(className, {
			'w-full': !inline,
			'border-round-3xl px-3': rounded,
			'p-invalid': Boolean(hasError),
		});
		const innerRef = useRef<DropdownOriginal>(null);
		return (
			<>
				<input
					ref={ref}
					type="button"
					className="hidden-accessible"
					onFocus={() => {
						const el = innerRef?.current;
						el?.getFocusInput().focus();
					}}
				/>
				<DropdownOriginal ref={innerRef} className={finalClassName} {...props} />
			</>
		);
	}
);
