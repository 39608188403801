import React, { useCallback } from 'react';
import { parseHtml } from 'utils/htmlParser';
import { ModuleType } from 'types/Contract';
import { ContractModuleBox } from './ContractModuleBox';

export type ContractModuleEditHandler = () => boolean;
export type ContractModuleCancelHandler = () => void;
export type ContractModuleSaveHandler = () => Promise<boolean>;

export type ContractModuleCommonProps = {
	// eslint-disable-next-line react/no-unused-prop-types
	onInit?: (moduleRef: any) => void;
	module: ModuleType;
	mergeLayout?: false | 'top' | 'bottom' | 'both';
	contentInsideHeader?: boolean;
	loading?: boolean;
	editMode?: boolean;
};

type ContractModuleProps = ContractModuleCommonProps & {
	className?: string;
	editable?: boolean;
	onEdit?: ContractModuleEditHandler;
	onCancel?: ContractModuleCancelHandler;
	onSave?: ContractModuleSaveHandler;
	children?: ({ editMode }: { editMode: boolean }) => React.ReactNode;
	headerRenderRight?: () => React.ReactNode;
};

export const ContractModule = ({
	className,
	module,
	editable,
	mergeLayout = false,
	contentInsideHeader = false,
	editMode = false,
	onEdit,
	onCancel,
	onSave,
	loading,
	children,
	headerRenderRight,
}: ContractModuleProps) => {
	const { setting } = module;
	const num = Number(setting?.priority);
	const title = String(setting.header);
	const text = String(setting?.text);

	const handleEditClick = useCallback(() => {
		if (editable && loading !== true) {
			if (onEdit) {
				onEdit();
			}
		}
	}, [editable, loading, onEdit]);

	const handleCancelEditClick = useCallback(() => {
		if (editable && loading !== true) {
			if (onCancel) {
				onCancel();
			}
		}
	}, [editable, loading, onCancel]);

	const handleSaveClick = useCallback(async () => {
		if (editable && loading !== true) {
			if (onSave) {
				onSave();
			}
		}
	}, [editable, loading, onSave]);

	const renderContent = () => (
		<div className="flex flex-column gap-1">
			<div>{parseHtml(text, { nl2br: false })}</div>
			{children && <div className="flex flex-column gap-1 w-full">{children({ editMode })}</div>}
			{/* <div className="flex flex-row">
				{children && <div className="flex flex-column gap-1 w-full">{children({ editMode })}</div>}
				{editable && editMode && (
					<div className="flex flex-column gap-1 ml-1">
						<Button
							className="p-button-text p-0"
							icon="pi pi-save"
							onClick={handleSaveClick}
							disabled={loading === true || !onSave}
							tooltip={!onSave ? 'Save handler is nod defined' : undefined}
							tooltipOptions={{ showOnDisabled: true }}
						/>
					</div>
				)}
			</div> */}
		</div>
	);

	return (
		<ContractModuleBox
			className={className}
			mergeLayout={mergeLayout}
			contentInsideHeader={contentInsideHeader}
			num={num}
			title={title}
			headerRenderRight={headerRenderRight}
			editMode={editMode}
			handleSaveClick={handleSaveClick}
			handleEditClick={handleEditClick}
			handleCancelEditClick={handleCancelEditClick}
			editable={Boolean(editable)}
			loading={Boolean(loading)}
		>
			{renderContent()}
		</ContractModuleBox>
	);
};
