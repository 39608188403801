import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { userPanelApi } from 'api';
import { Button } from 'primereact/button';
import CustomConfirmDialog from 'components/_common/ConfirmDialog';
import { usePanel } from 'contexts/panelContext';
import { InputTextarea } from 'components/_common/forms/InputTextarea';
import { Dialog } from 'components/_new/Dialog';
import { useGlobal } from '../../../contexts/globalContext';

interface ConfirmPairItemProps {
	membershipId: number;
	id: number;
	setInfoDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
	disabled?: boolean;
	hideButton?: boolean;
	onAction?: () => void;
}

export const ConfirmPairItem = ({
	id,
	membershipId,
	setInfoDialogOpen,
	disabled = false,
	hideButton = false,
	onAction,
}: ConfirmPairItemProps) => {
	const { t } = useTranslation();
	const { userPairsRefetch } = usePanel();
	const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
	const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
	const [rejectReason, setRejectReason] = useState('');
	const rejectReasonMinLenght = 20;
	const { toastRef } = useGlobal();
	const { mutate: confirmPair } = useMutation(() => userPanelApi.acceptPair(id, membershipId), {
		onSuccess: () => {
			if (onAction) {
				onAction();
			}
			userPairsRefetch();
			setInfoDialogOpen(true);
		},
	});
	const { mutate: rejectPair } = useMutation(() => userPanelApi.rejectPair(id, membershipId, rejectReason), {
		onSuccess: () => {
			if (onAction) {
				onAction();
			}
			userPairsRefetch();
			toastRef?.current?.show({ severity: 'info', detail: t('userPanel.pairing.rejectToastInfo') });
		},
	});

	return (
		<>
			<CustomConfirmDialog
				confirmationOpen={confirmDialogOpen}
				setConfirmationOpen={setConfirmDialogOpen}
				handleAccept={() => {
					confirmPair();
				}}
				message={t('userPanel.pairing.confirmAcceptMessage')}
			/>

			{!hideButton && (
				<div>
					<span className="text-xs">{t('userPanel.pairing.isMeetingDone')}</span>
					<div className="flex align-items-end justify-content-end mt-2">
						<Dialog
							title={t('userPanel.pairing.rejectDialog.header')}
							size="lg"
							onHide={() => setRejectDialogOpen(false)}
							visible={rejectDialogOpen}
						>
							<div className="flex flex-column">
								<p>{t('userPanel.pairing.rejectDialog.description')}</p>
								<InputTextarea
									value={rejectReason}
									onChange={(e) => setRejectReason(e.target.value)}
									autoResize
									className="h-6rem"
									placeholder={t('userPanel.pairing.rejectDialog.placeholder')}
									id="rejectReason"
									maxLength={250}
									hasError={rejectReason.length > 1 && rejectReason.length < rejectReasonMinLenght}
								/>
								{rejectReason.length < rejectReasonMinLenght && (
									<label htmlFor="rejectReason" className="mt-2">
										{t('userPanel.pairing.rejectDialog.label')}
									</label>
								)}
								<div className="flex justify-content-end mt-2">
									<Button
										label={t('userPanel.pairing.rejectDialog.rejectButton')}
										icon="pi pi-times p-button-sm"
										disabled={rejectReason.length < rejectReasonMinLenght}
										onClick={() => {
											rejectPair();
											setRejectDialogOpen(false);
										}}
										autoFocus
										className="p-button-danger p-button-sm"
									/>
									<Button
										label={t('userPanel.pairing.rejectDialog.goBackButton')}
										onClick={() => setRejectDialogOpen(false)}
										className="p-button-text p-button-sm ml-2"
									/>
								</div>
							</div>
						</Dialog>
						<Button
							onClick={() => setConfirmDialogOpen(true)}
							className="py-1 mr-2 px-3 text-sm"
							disabled={disabled}
						>
							{t('userPanel.pairing.confirmPair')}
						</Button>
						<Button
							onClick={() => setRejectDialogOpen(true)}
							className="p-button-text p-button-plain py-1 px-3 text-xs"
							disabled={disabled}
						>
							{t('userPanel.pairing.rejectPair')}
						</Button>
					</div>
				</div>
			)}
		</>
	);
};
