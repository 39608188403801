import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useProgramPanel } from 'contexts/programPanelContext';
import { Link } from 'react-router-dom';
import { roundFixed } from 'utils/number';
import { Panel, PanelItem } from '../../partials/panel';

export const ProgramProcessSummaries = () => {
	const { t } = useTranslation();
	const { data: programData } = useProgramPanel();

	const programComputedData = useMemo(() => {
		return {
			activeUsersInLast30DaysCountPerc: roundFixed(
				((programData?.applicationStats?.activeApplicationsInLast30DaysCount || 0) /
					(programData?.applicationStats?.applicationCount || 0)) *
					100
			),
			programPairWithSessionInLast30DaysCountPerc: roundFixed(
				((programData?.pairStats?.programPairWithSessionInLast30DaysCount || 0) /
					(programData?.pairStats?.programPairAcceptedCount || 0)) *
					100
			),
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [programData]);

	return (
		<Panel title={t('programPanel.dashboard.processSummaries.title')}>
			<>
				<PanelItem
					title={t('programPanel.dashboard.processSummaries.usersActiveInLast30Days')}
					value={`${programData?.applicationStats?.activeApplicationsInLast30DaysCount} (${programComputedData.activeUsersInLast30DaysCountPerc}%)`}
					bold
					large
				/>
				<PanelItem
					title={t('programPanel.dashboard.processSummaries.pairsWithSessionInLast30Days')}
					value={`${programData?.pairStats?.programPairWithSessionInLast30DaysCount} (${programComputedData.programPairWithSessionInLast30DaysCountPerc}%)`}
					bold
					large
				/>
			</>
			<>
				<PanelItem title={t('programPanel.dashboard.processSummaries.averageSessionSatisfaction')}>
					<Link to={`/program/${programData?.name}/statistics`} className="text-purplish-blue text-center">
						<i className="fa-solid fa-arrow-up-right-from-square" />
					</Link>
				</PanelItem>
				<PanelItem
					title={t('misc.mentee')}
					value={programData?.stats?.menteeAvgSatisfaction}
					withEmptyText
					bold
					large
				/>
				<PanelItem
					title={t('misc.mentors')}
					value={programData?.stats?.mentorAvgSatisfaction}
					withEmptyText
					bold
					large
				/>
			</>
			<>
				<PanelItem
					title={t('programPanel.dashboard.processSummaries.pairsWithoutSignedContract')}
					value={programData?.pairStats?.programPairWithoutSignedContractCount}
					transformValue={({ value }) => (
						<>
							{Number(value) > 0 ? <span className="bold text-danger">{Number(value)}</span> : 0}
							<Link
								to={{
									pathname: `/program/${programData?.name}/pair/list`,
									search: '?advanced=without-signed-contract',
								}}
								className="text-purplish-blue ml-2"
							>
								<i className="fa-solid fa-arrow-up-right-from-square" />
							</Link>
						</>
					)}
				/>
				<PanelItem
					title={t('programPanel.dashboard.processSummaries.pairsWithoutSessionInLast30Days')}
					value={programData?.pairStats?.programPairWithoutSessionInLast30DaysCount}
					transformValue={({ value }) => (
						<>
							{Number(value) > 0 ? <span className="bold text-danger">{Number(value)}</span> : 0}
							<Link
								to={{
									pathname: `/program/${programData?.name}/pair/list`,
									search: '?advanced=without-last-session',
								}}
								className="text-purplish-blue ml-2"
							>
								<i className="fa-solid fa-arrow-up-right-from-square" />
							</Link>
						</>
					)}
				/>
				<PanelItem
					title={t('programPanel.dashboard.processSummaries.usersWithoutLoginInLast30days')}
					value={programData?.applicationStats?.unactiveApplicationsInLast30DaysCount}
					transformValue={({ value }) => (
						<>
							{Number(value) > 0 ? <span className="bold text-danger">{Number(value)}</span> : 0}
							<Link
								to={{
									pathname: `/program/${programData?.name}/application/list`,
									search: '?advanced=without-last-signin&approved=accepted',
								}}
								className="text-purplish-blue ml-2"
							>
								<i className="fa-solid fa-arrow-up-right-from-square" />
							</Link>
						</>
					)}
				/>
			</>
		</Panel>
	);
};
