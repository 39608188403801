import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { Button } from 'components/_new/Button';
import { chatApi } from 'api/chat';
import { useGlobal } from 'contexts/globalContext';
import { useScope } from 'contexts/scopeContext';
import { useLocation } from 'react-router-dom';
import { useChat } from './useChat';
import { useChatThreadSubscriber } from './useChatSubscrber';

type ChatButtonProps = {
	programPanel?: boolean;
	landingMode?: boolean;
};

export const ChatButton = ({ programPanel = false, landingMode = false }: ChatButtonProps) => {
	const { t } = useTranslation();

	const {
		access,
		openDialog,
		dialogProps: { visible },
		queryUniqueParam,
	} = useChat();
	const { currentProgramMembership, applicationsData } = useScope();
	const { newChats, setNewChats } = useGlobal();

	const { refetch: chatApiStatusRefetch, isLoading: statusLoading } = useQuery(
		['chat-status', queryUniqueParam],
		() => chatApi.getStatus(Number(currentProgramMembership?.id)),
		{
			enabled: Boolean(currentProgramMembership) && access.hasAccess,
			initialData: [],
			refetchOnWindowFocus: true,
			cacheTime: 60000,
			onSuccess: (data) => {
				setNewChats(data);
			},
		}
	);

	const statusRefetchDelayed = () => {
		// if dialog is open, delay refetch for prevent fetch "open & now read" threads
		if (visible) {
			setTimeout(() => {
				if (!statusLoading) {
					chatApiStatusRefetch();
				}
			}, 1000);
		}

		// otherwise refetch now
		else if (!statusLoading) {
			chatApiStatusRefetch();
		}
	};

	const chatThreadHandlerEvent = useCallback(() => {
		statusRefetchDelayed();
	}, []);
	useChatThreadSubscriber({
		onChatThread: chatThreadHandlerEvent,
		onChatThreadSelf: chatThreadHandlerEvent,
	});

	const location = useLocation();
	useEffect(() => {
		const currentTitle = document.title.replace(/^\([0-9]+\)\s/g, '');
		if (newChats.length > 0) {
			document.title = `(${newChats.length}) ${currentTitle}`;
		} else {
			document.title = currentTitle;
		}
	}, [location.pathname, newChats.length]);

	if (!programPanel && !applicationsData.length) {
		return null;
	}
	return (
		<Button
			onClick={() => openDialog()}
			rounded
			variantSize="lg"
			variant={landingMode ? 'on-purple' : 'primary-outlined'}
			icon="comments"
			iconStyle={landingMode ? 'solid' : 'regular'}
			iconOnly
			label={t('chat.title')}
			badge={newChats.length}
			loading={access.loading}
			disabled={!access.hasAccess}
		/>
	);
};
